import {Grid, TextField, styled} from '@mui/material';
import React, {useCallback} from 'react';
import {TableLayoutResult} from '@modules/table_layout/hooks/useTableLayout';
import {MenuItemType, PopperMenuButton} from '@molecules/Buttons/PopperMenuButton';
import {PopperSelectBoxButton, PopperSelectBoxProps} from '@molecules/Buttons/PopperSelectBoxButton';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {Search} from '@mui/icons-material';
import {TableLayoutDialog, TableLayoutDialogProps} from '@organisms/Table/TableLayoutDialog';

const StyledTextField = styled(TextField)(({theme}) => ({
  width: '270px',
  backgroundColor: theme.palette.common.white,
  fontSize: '14px',
  '& .MuiInputLabel-root': {
    fontSize: '14px',
  },
}));

const StyledFlex = styled('div')({
  flexGrow: 1,
});

type SearchAndFilterBarProps = {
  tableLayout?: TableLayoutResult;
  searchLabel?: string;
  searchNameValue?: string;
  defaultSearchNameValue?: string;
  firstReactNode?: React.ReactNode;
  secondReactNode?: React.ReactNode;
  onChangeSearchName: (searchName: string) => void;
  onChangeTableLayout: (layout: TableLayoutResult) => void;
  popperSelectBoxButtonProps: PopperSelectBoxProps[];
};

const actionMenuItems = [
  {
    label: '表示項目を変更',
    value: 'changeLayout',
  },
];

export const SearchAndFilter: React.FC<SearchAndFilterBarProps> = ({
  tableLayout,
  searchLabel,
  searchNameValue,
  defaultSearchNameValue: defaultValue,
  firstReactNode,
  secondReactNode,
  onChangeSearchName,
  onChangeTableLayout,
  popperSelectBoxButtonProps,
}) => {
  const handleChangeLayout = useCallback(async () => {
    try {
      const currentLayout = await dialogHandler.open<TableLayoutDialogProps>(TableLayoutDialog, {
        tableColumns: tableLayout?.tableLayout ?? [],
        defaultOptions: tableLayout?.currentLayout ?? [],
        forceValue: tableLayout?.forceValue ?? {},
      });
      const newTableLayout = {
        tableLayout: tableLayout?.tableLayout ?? [],
        currentLayout: currentLayout,
      };
      onChangeTableLayout(newTableLayout);
    } catch (e) {
      console.error(e);
    }
  }, [tableLayout, onChangeTableLayout]);

  const handleActionMenuClick = useCallback(
    (item: MenuItemType) => {
      switch (item.value) {
        case 'changeLayout':
          handleChangeLayout();
          break;
      }
    },
    [handleChangeLayout]
  );

  const handleChangeSearchName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeSearchName(e.target.value);
    },
    [onChangeSearchName]
  );

  return (
    <Grid container sx={{paddingTop: '8px'}}>
      <StyledTextField
        label={searchLabel ?? '点検表の名前を検索'}
        variant="outlined"
        size="small"
        InputProps={{
          endAdornment: <Search />,
        }}
        defaultValue={defaultValue}
        value={searchNameValue}
        onChange={handleChangeSearchName}
      />
      {firstReactNode}
      {popperSelectBoxButtonProps.map((props, index) => (
        <PopperSelectBoxButton key={index} {...props} />
      ))}
      {secondReactNode}
      <StyledFlex />
      <PopperMenuButton menuItemList={actionMenuItems} onMenuClick={handleActionMenuClick}>
        アクション
      </PopperMenuButton>
    </Grid>
  );
};
