import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';

export const DialogContent = styled(Grid)(({theme}) => ({
  flex: '1 1 auto',
  height: '100%',
  padding: `0 ${theme.spacing(3)} ${theme.spacing(2)}`,
  maxHeight: 'calc(100vh - 192px)',
  overflow: 'auto',
}));
