import React, {useState, useRef, useCallback, useEffect, useMemo} from 'react';
import {Box, Grid, SxProps, Theme, styled} from '@mui/material';
import {Header} from '@Apps/BaseSharedMenu/Header';
import {Footer} from '@Apps/BaseSharedMenu/Footer';
import {Scanner} from '../../../Scanner';
import {ScannedProductTable} from './ScannedProductTable';
import {useNavigate} from 'react-router-dom';
import {useAtom} from 'jotai';
import {scannedProductAtom} from '../states';
import {FetchHospitalProductsParams, getHospitalProducts} from '@modules/hospital_products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {RentalErrorDialog} from '@Apps/BaseSharedMenu/RentalErrorDialog';
import {ProductStatus, UnacceptedStatus} from '../../types';
import {InnerLoading} from '@components/molecules/Loading';
import {contentFixedStyle} from '@components/templates/RentalTemplate';

/**
 * カメラでスキャン後の機器選択画面
 */
export const ScanRentDeviceBarcode = () => {
  const scannerRef = useRef(null);
  const {myInfo} = useMyInfo();
  const navigate = useNavigate();
  const [scanning, setScanning] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [status, setStatus] = useState<ProductStatus>(null);
  const [rentHospitalProducts, setRentHospitalProducts] = useAtom(scannedProductAtom);
  const isDisabled = useMemo(() => !(rentHospitalProducts.length > 0), [rentHospitalProducts.length]);
  const constraints = useMemo(() => ({width: window.innerWidth * 0.6, height: window.innerHeight * 0.5}), []);

  const updateRentHospitalProductsByManagementId = useCallback(
    async (managementId: string) => {
      // managementIdをもとに機器情報を検索する
      const params: FetchHospitalProductsParams = {
        managementId: managementId,
        permanentlyAssigneds: false,
        status: 'ready',
      };

      const {data, totalCount} = await getHospitalProducts(myInfo.hospitalHashId, params);

      if (totalCount === 0) {
        setStatus(null);
        setOpenDialog(true);
        // FIX ME: 管理番号がユニークになったら、loop回す必要なくなる。
      } else if (data.some((d) => d.status !== 'ready' || (d.numberPeriodInspectionResultsInPeriod ?? 0) > 0)) {
        data.forEach((d) => setStatus(d.status));
        setOpenDialog(true);
      } else {
        setRentHospitalProducts((prevState) => [
          ...prevState,
          ...data.filter((item) => !prevState.some((d) => d.hashId === item.hashId)),
        ]);
      }
    },
    [myInfo.hospitalHashId, setRentHospitalProducts]
  );

  const onDetected = useCallback(
    (managementId: string) => {
      updateRentHospitalProductsByManagementId(managementId);
    },
    [updateRentHospitalProductsByManagementId]
  );

  const handleClickCancel = useCallback(
    (rowIndex: number) => {
      setRentHospitalProducts(rentHospitalProducts.filter((_item, idx) => idx !== rowIndex));
    },
    [rentHospitalProducts, setRentHospitalProducts]
  );

  const handleClickNextButton = useCallback(() => {
    navigate('/shared/rental/product/camera/checkout');
  }, [navigate]);

  useEffect(() => {
    setScanning(!scanning);
  }, []);

  return (
    <>
      <Header title={'貸出'} />
      <Grid item sx={contentFixedStyle}>
        <Box ref={scannerRef} sx={videoContainerStyle}>
          <StyledCanvas className={'drawingBuffer'} />
          {scanning ? (
            <Scanner scannerRef={scannerRef} onDetected={onDetected} constraints={constraints} />
          ) : (
            <InnerLoading />
          )}
        </Box>
        <Box sx={resultStyle}>
          <ScannedProductTable rentingHospitalProducts={rentHospitalProducts} onClickCancel={handleClickCancel} />
        </Box>
      </Grid>
      <Footer
        text={'貸出機器のバーコードを\nカメラで読み取って下さい'}
        nextButtonLabel={'確認画面へ'}
        onClickNextButton={handleClickNextButton}
        isDisabled={!isDisabled}
      />
      <RentalErrorDialog
        open={openDialog}
        onClickButton={() => setOpenDialog(false)}
        status={status as UnacceptedStatus}
        type="rent"
      />
    </>
  );
};

const videoContainerStyle: SxProps<Theme> = {
  position: 'relative',
  width: '70%',
  ml: 4,
};

const resultStyle: SxProps<Theme> = (theme) => ({
  width: '30%',
  maxHeight: '100%',
  overflow: 'auto',
  ml: 4,
  mr: 4,
  [theme.breakpoints.up('tabletH')]: {
    paddingLeft: 7,
    paddingRight: 7,
  },
  [theme.breakpoints.up('desktop')]: {
    paddingLeft: 10,
    paddingRight: 10,
  },
});

const StyledCanvas = styled('canvas')({
  top: '0px',
  left: '0px',
  height: '100%',
  width: '100%',
  position: 'absolute',
});
