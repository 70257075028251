import {isNullish} from '@front-libs/helpers';
import {ProductDetailFormType} from '.';

export const getNextStatus = (initialValues: ProductDetailFormType, submitValues: ProductDetailFormType) => {
  const willDisposed =
    initialValues.status !== 'disabled' &&
    submitValues.status !== 'disabled' &&
    (!isNullish(submitValues.dateOfDisposal) ||
      (submitValues.reasonOfDisposal !== '' && !isNullish(submitValues.reasonOfDisposal)));

  const wontDisposed =
    initialValues.status === 'disabled' &&
    submitValues.status === 'disabled' &&
    ((!isNullish(initialValues.dateOfDisposal) && isNullish(submitValues.dateOfDisposal)) ||
      (initialValues.reasonOfDisposal !== '' && submitValues.reasonOfDisposal === ''));

  const isNextDisabled = initialValues.status !== 'disabled' && submitValues.status === 'disabled';
  return {willDisposed, wontDisposed, isNextDisabled};
};
