import {
  Box,
  Button,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ChildUnitSelectionDialog} from './components/ChildUnitSelectionDialog';
import {HeaderComponent} from './components/HeaderComponent';
import {ChildUnitFormValue, useChildUnitForm} from './useChildUnitForm';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {AlertDialog} from '@components/molecules/Dialogs/AlertDialog';
import {SimpleDialogProps} from 'src/components/molecules/Dialogs/BaseDialog';

const rootStyle: SxProps = {
  backgroundColor: '#fff',
  width: '100vw',
  height: '100vh',
};

const contentAreaStyle: SxProps = {margin: '24px 32px'};

const emptyContentAreaTextStyle: SxProps = {fontSize: '14px'};

async function confirmDelete() {
  try {
    await dialogHandler.open<SimpleDialogProps>(AlertDialog, {
      title: '関連する子機の削除',
      content: 'この操作を実行しますか？実行すると元に戻せません。',
      positiveButtonLabel: '実行する',
    });

    return true;
  } catch (_e) {
    return false;
  }
}

type DeleteTargetType = {
  parentHashId: string;
  childHashId: string;
};

const handleClickDelete = async ({parentHashId, childHashId}: DeleteTargetType) => {
  const isSubmit = await confirmDelete();
  if (!isSubmit) return;

  //TODO: ここに 削除の本体処理を書く
  console.log({parentHashId, childHashId});
};

const DeleteButton = ({parentHashId, childHashId}: DeleteTargetType) => {
  return (
    <Button
      variant="text"
      startIcon={<DeleteOutlineOutlinedIcon />}
      onClick={() => handleClickDelete({parentHashId, childHashId})}>
      削除
    </Button>
  );
};

const TableStyle = {
  minWidth: '680px',
  '& td, & th': {
    border: 0,
  },
};
const ChildUnitTable = () => {
  function createData(managementId: string, displayName: string, name: string, childHashId: string) {
    return {managementId, displayName, name, button: <DeleteButton parentHashId={''} childHashId={childHashId} />};
  }

  const rows = [
    createData('IP-001', 'NKV-330 人工呼吸器', 'NKV-330', 'childHashId'),
    createData(
      'IP-002',
      '磁気共鳴断層撮影装置 Optima MR360/Brivo MR355(SIGNA Explorer シリーズ、SIGNA Creator シリーズ)',
      'NKV-3300123456789',
      'childHashId'
    ),
    createData('IP-003', 'NKV-330 人工呼吸器00123456789', 'NKV-33000123456789', 'childHashId'),
    createData('IP-004', 'NKV-330 人工呼吸器00123456789', 'NKV-33000123456789', 'childHashId'),
    createData('IP-005', 'NKV-330 人工呼吸器00123456789', 'NKV-33000123456789', 'childHashId'),
  ];

  return (
    <TableContainer component={Box}>
      <Table sx={TableStyle} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>管理番号</TableCell>
            <TableCell align="left">機種名</TableCell>
            <TableCell align="left">型式</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={`${row.managementId}${index}`} sx={{'&: td, &: th': {border: 0}}}>
              <TableCell component="th" scope="row">
                {row.managementId}
              </TableCell>
              <TableCell align="left" sx={{maxWidth: '150px'}}>
                {row.displayName}
              </TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.button}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const ProductChildDeviceEditor = () => {
  const navigate = useNavigate();
  const {hashId} = useParams();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // TODO：API実装後修正
  // 子機のデータが存在しない場合の表示フラグ制御
  const [isChildUnitEmpty] = useState(false);

  const {handleSubmit, register, errors, isValid} = useChildUnitForm();
  // TODO: API実装後修正
  const onSubmit = (data: ChildUnitFormValue) => {
    console.log('data', data);
  };

  const handleClickPrevButton = () => {
    navigate(`/products/${hashId}`);
  };

  const handleClickNextButton = () => {
    setIsDialogOpen(true);
  };

  return (
    <Box sx={rootStyle}>
      <HeaderComponent onClickPrevButton={handleClickPrevButton} onClickNextButton={handleClickNextButton} />
      <Box sx={contentAreaStyle}>
        {isChildUnitEmpty ? (
          <Typography sx={emptyContentAreaTextStyle}>関連する子機が登録されていません。</Typography>
        ) : (
          <ChildUnitTable />
        )}
      </Box>
      <ChildUnitSelectionDialog
        open={isDialogOpen}
        onSubmit={handleSubmit(onSubmit)}
        onClose={() => setIsDialogOpen(false)}
        register={register}
        errors={errors}
        isValid={isValid}
      />
    </Box>
  );
};
