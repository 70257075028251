import React, {useMemo} from 'react';
import _ from 'lodash';
import {
  Box,
  Button,
  Grid,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import {RentalHistoryIndex} from '@modules/rentals/types';
import {HospitalProductIndex} from '@modules/hospital_products/types';

export type TableProps = {
  returnHospitalProducts: RentalHistoryIndex[];
  onClickCancel: (rowIndex: number) => void;
};

export const ScannedProductTable: React.FC<TableProps> = ({returnHospitalProducts, onClickCancel}) => {
  const tableRows = useMemo<Partial<HospitalProductIndex & {rentalRoom: string}>[]>(() => {
    return [
      ...returnHospitalProducts.map(({rentalHospitalProduct, rentalRoom}) => ({
        ...rentalHospitalProduct,
        rentalRoom: rentalRoom?.name ?? '',
      })),
      ...(returnHospitalProducts.length < 4 ? Array(4 - returnHospitalProducts.length).fill({}) : []),
    ];
  }, [returnHospitalProducts]);

  return (
    <Grid container direction="column" style={{fontFamily: 'Roboto'}}>
      <Grid item>
        <Box sx={tableCaptionStyle}>返却する機器リスト</Box>
      </Grid>
      <Grid item>
        <TableContainer component={Paper} sx={tableContainerStyle} elevation={0}>
          <Table stickyHeader>
            <caption>{`合計${returnHospitalProducts.length}点`}</caption>
            <TableHead>
              <TableRow sx={tableRowStyle}>
                <TableCell sx={managementIdHeadCellStyle}>管理番号</TableCell>
                <TableCell align="left" sx={cancelBtnHeadCellStyle} />
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows.map((row, index) => (
                <TableRow key={index} sx={tableRowStyle}>
                  <TableCell component="th" scope="row" sx={managementIdBodyCellStyle}>
                    {_.truncate(row.managementId, {length: 12})}
                  </TableCell>
                  <TableCell sx={cancelBtnCellStyle}>
                    {row.managementId && (
                      <Button variant="outlined" sx={cancelButtonStyle} onClick={() => onClickCancel(index)}>
                        キャンセル
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const tableContainerStyle: SxProps<Theme> = {
  borderRadius: '10px',
  maxHeight: '360px',
  '& .MuiTable-root caption': {
    textAlign: 'right',
    color: '#374659',
    fontSize: '24px',
    position: 'sticky',
    bottom: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: '#fff',
  },
};

const tableCaptionStyle: SxProps<Theme> = (theme) => ({
  textAlign: 'center',
  marginBottom: 2,
  fontSize: 18,
  [theme.breakpoints.up('tabletH')]: {
    fontSize: 24,
  },
});

const tableRowStyle: SxProps<Theme> = (theme) => ({
  height: '48px',
  [theme.breakpoints.up('laptop')]: {
    height: '56px',
  },
});

const managementIdHeadCellStyle: SxProps<Theme> = (theme) => ({
  minWidth: '100px',
  fontSize: 20,
  backgroundColor: theme.palette.common.white,
  padding: '0px 8px 0px 20px',
  [theme.breakpoints.up('tabletH')]: {
    fontSize: 24,
  },
  [theme.breakpoints.up('laptop')]: {
    padding: '8px 16px 8px 32px',
  },
});

const managementIdBodyCellStyle: SxProps<Theme> = (theme) => ({
  width: '120px',
  fontWeight: 700,
  color: '#2a96e8',
  fontSize: 28,
  padding: '0px 8px 0px 20px',
  [theme.breakpoints.up('laptop')]: {
    fontSize: 32,
    padding: '0px 16px 0px 32px',
  },
});

const cancelBtnHeadCellStyle: SxProps<Theme> = {
  backgroundColor: 'white',
};

const cancelBtnCellStyle: SxProps<Theme> = {
  padding: '0px 16px 0px 8px',
  textAlign: 'right',
};

const cancelButtonStyle: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.rent.main}`,
  borderRadius: '10px',
  color: theme.palette.rent.main,
  boxSizing: 'border-box',
  padding: 1,
  width: '98px',
  height: '36px',
});
