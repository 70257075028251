export const accountSection: {
  sectionName: string;
  url?: string;
  children: {
    label: string;
    url: string;
  }[];
}[] = [
  {
    sectionName: 'プロファイル',
    url: '/account/profile',
    children: [],
  },
  {
    sectionName: 'セキュリティ',
    url: '/account/security',
    children: [],
  },
  {
    sectionName: '通知',
    url: '/account/notification',
    children: [],
  },
  {
    sectionName: 'ブラウザ状況',
    url: '/account/status',
    children: [],
  },
  {
    sectionName: '各種規約',
    url: '/account/document',
    children: [],
  },
];

export const settingSection: {
  sectionName: string;
  url?: string;
  children: {
    label: string;
    url: string;
  }[];
}[] = [
  {
    sectionName: '病院情報',
    url: '/settings/hospital',
    children: [],
  },
  {
    sectionName: 'ユーザー管理',
    url: '/settings/user',
    children: [],
  },
  {
    sectionName: '機能別設定',
    url: '',
    children: [
      {
        label: '点検',
        url: '/settings/inspection',
      },
      {
        label: '貸出・返却',
        url: '/settings/rental',
      },
      {
        label: '修理',
        url: '/settings/repair',
      },
      {
        label: '機器登録',
        url: '/settings/product_registration',
      },
      {
        label: '機器管理',
        url: '/settings/product_management',
      },
      {
        label: '病棟ユーザーメニュー',
        url: '/settings/fault_reception',
      },
    ],
  },
  {
    sectionName: '建物・場所',
    url: '/settings/place',
    children: [],
  },
  {
    sectionName: '管理部署',
    url: '/settings/management',
    children: [],
  },
  {
    sectionName: '担当代理店',
    url: '/settings/dealer',
    children: [],
  },
];
