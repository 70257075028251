import React, {useEffect, useState, useCallback} from 'react';
import {Chip, Divider, useMediaQuery, styled} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import {HospitalProductIndex, NoteSettings} from '@modules/hospital_products/types';
import {FormFieldsSection, FormField} from '@Apps/ProductDetail/TwoColumn/FormFieldsSection';
import {
  deviceTypes,
  devices,
  deviceDetails,
  rentalSection,
  leaseSection,
  makerInspectionFormSection,
  demonstrationFormSection,
} from './FormFieldDefinitions';
import {
  ChannelLabel,
  ChannelLabelTypography,
  ChannelNoTypography,
  FormFieldsSectionContainer,
  FormFieldsSections,
  StatusLabel,
} from '@Apps/ProductDetail/styled';
import {useHospitalProductNoteSettings} from '@modules/hospital_products/hooks/useHospitalProductNoteSettings';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InnerLoading} from '@components/molecules/Loading';
import {StandardTextField} from '@components/molecules/Formik/fields/StandardTextField';
import {StandardDateField} from '@components/molecules/Formik/fields/StandardDateField';
import {StandardSelect} from '@components/molecules/Formik/fields/StandardSelectorField';
import {useNavigate} from 'react-router-dom';
import {useUserPermissions} from '@modules/auth/hooks';
import {HospitalUserPermission} from '@modules/auth/consts';

const getNotesTypeOptionsFromNoteSettings = (
  noteSettings: NoteSettings,
  numStr: string
): {label: string; value: string}[] => {
  const opt = noteSettings[`notes${numStr}TypeOption`];
  if (opt === null) return [];
  if (typeof opt === 'string') return [];
  if (typeof opt === 'boolean') return [];

  return opt.values.map((v) => ({label: v.name, value: v.value.toString()}));
};

/**
 *
 * @param noteSettings
 * @returns
 */
const createNotesFields = (noteSettings: NoteSettings) => {
  const maxNotesNum = 20;

  const temp: FormField[] = [];
  for (let i = 1; i <= maxNotesNum; i++) {
    const numString = i === 1 ? '' : String(i);
    if (noteSettings[`notes${numString}Visible`] === false) continue;

    const type = noteSettings[`notes${numString}Type`];
    const label = noteSettings[`notes${numString}Name`];
    switch (type) {
      case 'text':
        temp.push({
          type: 'textarea',
          label: label,
          name: `notes${numString}` as keyof HospitalProductIndex,
          InputComponent: StandardTextField,
          multiline: true,
        });
        break;
      case 'date':
        temp.push({
          type: 'text',
          label: label,
          name: `notes${numString}` as keyof HospitalProductIndex,
          InputComponent: StandardDateField,
          clearable: true,
        });
        break;
      case 'select':
        temp.push({
          type: 'select',
          label: label,
          name: `notes${numString}` as keyof HospitalProductIndex,
          InputComponent: StandardSelect,
          options: getNotesTypeOptionsFromNoteSettings(noteSettings, numString),
        });
        break;
    }
  }
  return temp;
};

const FormFieldsDivider = styled(Divider)({
  margin: '0 16px 16px',
});
const StyledErrorIcon = styled(ErrorIcon)({
  color: '#C2000A',
  width: '18px',
  height: '18px',
});

const StyledChip = styled(Chip)({
  backgroundColor: '#FFEAED',
  color: '#C7243A',
  borderRadius: '4px',
  fontSize: '14px',
  height: '28px',
  marginRight: '24px',
});

const StyledDiv = styled('div')({
  height: '28px',
});

type Props = {
  hospitalProduct?: HospitalProductIndex;
};

/**
 * セクション情報を管理。
 * 機種情報、機器情報、詳細情報の3つのセクションを表示する。
 * 製品のサムネイル画像がヘッダー、セクションの上部を横断する関係で、例外的にステータス情報を含めている
 */
export const ProductInfoSections = React.memo(({hospitalProduct}: Props) => {
  const {isPermitted} = useUserPermissions();
  const isTransmitter = !!hospitalProduct?.isTransmitter;
  const isPermittedChannelManagement = isPermitted([HospitalUserPermission.ChannelManagement]);
  const isWorking = hospitalProduct?.status === 'working';
  const isDisplayStatusLabel = isWorking || isTransmitter;
  const roomName = hospitalProduct?.rentHospitalRoom?.name ?? '不明';
  const channelNumberToDisplay = hospitalProduct?.channelNumber ?? '未割り当て';
  const navigate = useNavigate();
  const {myInfo} = useMyInfo();
  const {data, isLoading} = useHospitalProductNoteSettings(myInfo.hospitalHashId);
  const [deviceDetailsFields, setDeviceDetailsFields] = useState<FormField[]>(deviceDetails.fields);

  useEffect(() => {
    if (!data) return;
    const notesField = createNotesFields(data.noteSettings);
    setDeviceDetailsFields([...deviceDetails.fields, ...notesField]);
  }, [data]);

  const handleChannelClick = useCallback(
    (channelNumber) => {
      const query = channelNumber ? `?channelNumber=${channelNumber}` : '';
      navigate(`/channels${query}`, {replace: true});
    },
    [navigate]
  );

  if (isLoading) {
    return <InnerLoading />;
  }

  return (
    <FormFieldsSectionContainer>
      <StatusLabel visible={isDisplayStatusLabel.toString()}>
        <StyledDiv>{isWorking && <StyledChip icon={<StyledErrorIcon />} label={`貸出先：${roomName}`} />}</StyledDiv>
        {isTransmitter && isPermittedChannelManagement && (
          <ChannelLabel>
            <ChannelLabelTypography>チャンネル：</ChannelLabelTypography>
            <ChannelNoTypography onClick={() => handleChannelClick(hospitalProduct?.channelNumber)}>
              {channelNumberToDisplay}
            </ChannelNoTypography>
          </ChannelLabel>
        )}
      </StatusLabel>
      <FormFieldsSections>
        <FormFieldsSection sectionName={deviceTypes.sectionName} fields={deviceTypes.fields} />
        <FormFieldsDivider />
        <FormFieldsSection sectionName={devices.sectionName} fields={devices.fields} />
        <FormFieldsDivider />
        <FormFieldsSection sectionName={deviceDetails.sectionName} fields={deviceDetailsFields} />
        {hospitalProduct?.waysOfPurchase === 'rental' ? (
          <>
            <FormFieldsDivider />
            <FormFieldsSection sectionName={rentalSection.sectionName} fields={rentalSection.fields} />
          </>
        ) : hospitalProduct?.waysOfPurchase === 'lease' ? (
          <>
            <FormFieldsDivider />
            <FormFieldsSection sectionName={leaseSection.sectionName} fields={leaseSection.fields} />
          </>
        ) : (
          false
        )}
        {hospitalProduct?.waysOfPurchase === 'demo' && (
          <>
            <FormFieldsDivider />
            <FormFieldsSection
              sectionName={demonstrationFormSection.sectionName}
              fields={demonstrationFormSection.fields}
            />
          </>
        )}
        <FormFieldsDivider />
        <FormFieldsSection
          sectionName={makerInspectionFormSection.sectionName}
          fields={makerInspectionFormSection.fields}
        />
      </FormFieldsSections>
    </FormFieldsSectionContainer>
  );
});
