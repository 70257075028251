import {useFetchHospitalRooms} from '@modules/hospital_places/api';
import {type HospitalRoom} from '@modules/hospital_places/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {Box, CSSObject, Grid, SxProps, Theme} from '@mui/material';
import {contentFixedStyle} from '@templates/RentalTemplate';
import {useAtomValue, useSetAtom} from 'jotai';
import _ from 'lodash';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Footer} from '../Footer';
import {Header} from '../Header';
import {RentalSelectionCard} from '../RentalSelectionCard';
import {SelectionSwiper} from '../SelectionSwiper';
import {
  allowRentalRoomUndefinedDataAtom,
  defaultBarcodeScannerTypeAtom,
  rentFloorAtom,
  rentRoomAtom,
  rentStepperAtom,
  rentWardAtom,
} from '../states';

const MAX_WARDS_PER_PAGE = 18;

/**
 * 大エリア選択画面
 */
export const FloorSelection = () => {
  const navigate = useNavigate();
  const setRentalFloor = useSetAtom(rentFloorAtom);
  const setRentalRoom = useSetAtom(rentRoomAtom);
  const setRentalStepper = useSetAtom(rentStepperAtom);
  const defaultBarcodeScannerType = useAtomValue(defaultBarcodeScannerTypeAtom);
  const allowRentalRoomUndefinedData = useAtomValue(allowRentalRoomUndefinedDataAtom);
  const ward = useAtomValue(rentWardAtom);
  const {myInfo} = useMyInfo();

  const {data: roomData} = useFetchHospitalRooms(myInfo.hospitalHashId, {
    page: 0,
    perPage: 100,
    hospitalWardHashId: ward?.hashId,
    showRentalPlace: true,
    order: 'floor_number',
  });

  const filteredRooms = useMemo(() => {
    const roomMap: Map<string, HospitalRoom> = new Map();
    roomData.forEach((room) => {
      const key = `${room.floorNumber}:${room.isGroundFloor}`;
      if (!roomMap.has(key)) {
        roomMap.set(key, room);
      }
    });
    return [...roomMap.values()];
  }, [roomData]);

  const chunkedRooms = useMemo(() => {
    return {data: _.chunk(filteredRooms, MAX_WARDS_PER_PAGE), length: filteredRooms.length};
  }, [filteredRooms]);

  const getFloorDescription = useCallback((room: HospitalRoom) => {
    return room.isGroundFloor ? `${room.floorNumber}階` : `地下${room.floorNumber}階`;
  }, []);

  const handleClickNextButton = useCallback(() => {
    setRentalRoom(null);
    defaultBarcodeScannerType === 'barcode_reader'
      ? navigate(`/shared/rental/product/barcode_reader`)
      : navigate(`/shared/rental/product/camera`);
  }, [defaultBarcodeScannerType, navigate, setRentalRoom]);

  const handleSelectChange = useCallback(
    (value) => {
      if ('floorNumber' in value) {
        setRentalFloor({floorNumber: value.floorNumber, isGroundFloor: value.isGroundFloor});
        navigate(`/shared/rental/roomSelection`);
      }
    },
    [navigate, setRentalFloor]
  );

  useEffect(() => {
    setRentalStepper(1);
  }, [setRentalStepper]);

  const GridComponent = () => {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={[gridRootStyle, filteredRooms.length > 4 && gridRootSecondLineStyle]}
        spacing={3}>
        {chunkedRooms.data[0] &&
          chunkedRooms.data[0].map((room, index) => (
            <Grid item key={index} sx={filteredRooms.length > 8 ? grid6ItemsStyle : grid4ItemsStyle}>
              <RentalSelectionCard
                label={getFloorDescription(room)}
                itemsCount={filteredRooms.length}
                value={room}
                onChange={handleSelectChange}
              />
            </Grid>
          ))}
      </Grid>
    );
  };
  return (
    <>
      <Header title={'貸出'} />
      <Box sx={wardFloorContainerStyle}>{`${ward?.name}`}</Box>
      <Box sx={contentFixedStyle}>
        {chunkedRooms.length <= 12 ? (
          <GridComponent />
        ) : (
          <SelectionSwiper<HospitalRoom>
            data={chunkedRooms.data}
            getLabel={(item) => getFloorDescription(item)}
            onSelect={(item) => handleSelectChange(item)}
          />
        )}
      </Box>
      <Footer
        text={'貸出先を選択してください'}
        nextButtonLabel={allowRentalRoomUndefinedData ? '貸出先を選択せず次へ' : undefined}
        onClickNextButton={handleClickNextButton}
      />
    </>
  );
};

const wardFloorContainerStyle: SxProps<Theme> = (theme) => ({
  position: 'relative',
  top: 0,
  left: '32px',
  fontSize: '24px',
  fontWeight: 'bold',
  zIndex: 0,
  color: theme.palette.rent.main,
});

const gridRootStyle: CSSObject = {
  margin: 'auto',
  flexWrap: 'nowrap',
  padding: '0px 80px 0px 56px',
};

const gridRootSecondLineStyle: CSSObject = {
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
};

const grid4ItemsStyle: SxProps = {
  flexBasis: '25%',
};

const grid6ItemsStyle: SxProps = {
  flexBasis: '16.66%',
};
