import React from 'react';
import {isTablet} from 'react-device-detect';
import {pdfjs} from 'react-pdf';
import {makeStyles} from '@material-ui/core/styles';
import {PDFDisplayForTablet} from './PDFDisplayForTablet';
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
// NOTE:バージョン9未満の際はlegacy側のworkerを使用する
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
type Props = {
  src: string;
};

type PDFDisplayProps = {
  scale?: number;
} & Props;

export const PDFDisplay: React.FC<PDFDisplayProps> = ({src, scale = 1}) => {
  return isTablet ? <PDFDisplayForTablet src={src} scale={scale} /> : <PDFDisplayPC src={src} />;
};

const PDFDisplayPC: React.FC<Props> = ({src}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <object width={'100%'} className={classes.pdf} type={'application/pdf'} data={`${src}`}>
        object can't be rendered
      </object>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
  },
  pdf: {
    width: '100%',
    height: '100%',
  },
}));
