import {UserFormatter} from '@modules/hospital_users/helpers';
import {UserIndex} from '@modules/hospital_users/types';
import {
  dealtCategoryOptions,
  rootCauseCategoryOptions,
  symptomCategoryOptions,
  symptomDetailCategoryOptions,
} from '@modules/repairs/constants';

export const propertyField = (hospitalUsers: UserIndex[]) => ({
  requestUserHashId: {
    type: 'selector',
    label: '依頼者',
    options: UserFormatter.getOptions(hospitalUsers),
  },
  requestUserName: {
    type: 'text',
    label: '依頼者名',
  },
  requestForRepairAt: {
    type: 'datetime',
    label: '修理受付日時',
  },
  personInChargeHashId: {
    type: 'selector',
    label: '担当者',
    options: UserFormatter.getOptions(hospitalUsers),
  },
  appliedAtToSuppliesDepartment: {
    type: 'datetime',
    label: '事務への申請年月日',
  },
  applicationToSuppliesDepartment: {
    type: 'text',
    label: '事務への申請',
    multiline: true,
  },
  dealtCategory: {
    type: 'selector',
    label: '処理区分',
    options: dealtCategoryOptions,
  },
  symptomCategory: {
    type: 'selector',
    label: '事象区分',
    options: symptomCategoryOptions,
  },
  symptomDetailCategory: {
    type: 'selector',
    label: '事象詳細区分',
    options: symptomDetailCategoryOptions,
  },
  rootCauseCategory: {
    type: 'selector',
    label: '原因区分',
    options: rootCauseCategoryOptions,
  },
  causeOfIssueDetail: {
    type: 'text',
    label: '原因',
    multiline: true,
  },
  requests: {
    type: 'text',
    label: '依頼事項',
    multiline: true,
  },
  dealWith: {
    type: 'text',
    label: '対応',
    multiline: true,
  },
  necessityOfSubstitute: {
    type: 'selector',
    label: '代替の有無',
    options: [
      {label: '有り', value: true},
      {label: '無し', value: false},
    ],
  },
  substitutionDetail: {
    type: 'text',
    label: '代替の内容',
    multiline: true,
  },
  makerContactAt: {
    type: 'datetime',
    label: 'メーカーへの連絡日時',
  },
  fixedAt: {
    type: 'datetime',
    label: '完了年月日',
  },
  makerPicName: {
    type: 'text',
    label: 'メーカー担当者',
  },
  makerContactTel: {
    type: 'text',
    label: 'メーカー連絡先（電話）',
  },
  makerContactEmail: {
    type: 'text',
    label: 'メーカー連絡先（メール）',
  },
  estimatedCostOfFix: {
    type: 'number',
    label: '修理見積額',
  },
  costOfFix: {
    type: 'number',
    label: '修理請求額',
  },
  note: {
    type: 'text',
    label: '備考1',
    multiline: true,
  },
  dateOfDisposal: {
    type: 'date',
    label: '廃棄日',
  },
  reasonOfDisposal: {
    type: 'text',
    label: '廃棄理由',
    multiline: true,
  },
});
