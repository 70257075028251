import React, {useMemo} from 'react';
import {createStyles, makeStyles, Theme, Typography, Grid} from '@material-ui/core';
import clsx from 'clsx';

const useStyle = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    status: {
      flex: '0 0 65px',
      borderRadius: '80px',
      padding: '2px 0px',
      fontSize: '12px',
      fontWeight: 'bold',
      textAlign: 'center',
      borderWidth: '2px',
      borderStyle: 'solid',
    },
    red: {
      background: '#feeaed',
      color: '#C7243A',
      borderColor: '#C7243A',
    },
    yellow: {
      background: '#FEF4E5',
      color: '#FF9800',
      borderColor: '#FF9800',
    },
    green: {
      background: '#EAF5EA',
      color: '#3C9E58',
      borderColor: '#3C9E58',
    },
  })
);

type PercentBadgeProps = {
  percent: number;
};

export const PercentBadge: React.FC<PercentBadgeProps> = (props) => {
  const {percent} = props;
  const classes = useStyle();

  const displayPercent = useMemo(() => Math.round(percent), [percent]);

  const textClass = useMemo(
    () =>
      displayPercent >= 80
        ? clsx(classes.status, classes.red)
        : displayPercent < 80 && displayPercent >= 50
          ? clsx(classes.status, classes.yellow)
          : clsx(classes.status, classes.green),
    [classes.green, classes.red, classes.status, classes.yellow, displayPercent]
  );

  return (
    <Grid className={classes.root}>
      <Typography className={textClass} variant="inherit">
        {displayPercent}%
      </Typography>
    </Grid>
  );
};
