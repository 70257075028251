export enum TableFormRowId {
  ManagementId = 'managementId',
  SerialNumber = 'serialNumber',
  LotNumber = 'lotNumber',
  PermanentlyAssigned = 'permanentlyAssigned',
  HospitalProductMgmtSectionHashId = 'hospitalProductMgmtSectionHashId',
  Status = 'status',
  HospitalRoomHashId = 'hospitalRoomHashId',
  DateOfPurchase = 'dateOfPurchase',
  IsBaseUnit = 'isBaseUnit',
  WaysOfPurchase = 'waysOfPurchase',
  LegalDurableYear = 'legalDurableYear',
  IsMaintenanceContract = 'isMaintenanceContract',
  HospitalDealerHashId = 'hospitalDealerHashId',
  AssetRegisterNumber = 'assetRegisterNumber',
  DateOfDisposal = 'dateOfDisposal',
  ReasonOfDisposal = 'reasonOfDisposal',
  Notes = 'notes',
  OptionalBarcode = 'optionalBarcode',
  RawBarcode = 'rawBarcode',
}

/** 設定により必須になりうるTableFormRowId */
export type PotentiallyRequiredTableFormRowId = Exclude<
  TableFormRowId,
  TableFormRowId.ManagementId | TableFormRowId.Notes
>;
