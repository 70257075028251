import {createStyles, Divider, Grid, makeStyles, styled, Theme, Typography} from '@material-ui/core';
import {Form} from 'formik';
export const sideNavHeight = '100%';
export const sideNavWidth = 356;
export const sideBarWidth = 256;

export const use2ColumnsLayoutTemplate = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      overflowY: 'hidden',
    },
    sideNav: {
      width: sideNavWidth,
      height: `calc(100vh - 56px)`,
      overflowY: 'auto',
      // marginTop: appHeaderWidth,
    },
    content: {
      width: `calc(100% - ${sideNavWidth}px)`,
      height: `calc(100vh - 56px)`,
      overflowY: 'auto',
    },
  })
);

export const use3ColumnsLayoutTemplate = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      overflowY: 'hidden',
    },
    leftSideNav: {
      width: sideNavWidth,
      height: `calc(100vh - 56px)`,
      overflowY: 'auto',
      // marginTop: appHeaderWidth,
    },
    rightSideNav: {
      width: 0,
      height: `calc(100vh - 56px)`,
      overflowY: 'auto',
      // marginTop: appHeaderWidth,
      [theme.breakpoints.up('laptop')]: {
        width: sideNavWidth,
      },
    },
    content: {
      width: `calc(100% - ${sideNavWidth}px)`,
      height: `calc(100vh - 56px)`,
      overflowY: 'auto',
      [theme.breakpoints.up('laptop')]: {
        width: `calc(100% - ${sideNavWidth}px - ${sideNavWidth}px)`,
      },
    },
  })
);

export const useSettingsContentTemplate = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      fontWeight: 'bold',
    },
    grid: {
      height: '100%',
      overflowY: 'visible',
    },
    sideBar: {
      width: sideBarWidth,
      height: `calc(100vh - 56px)`,
      overflowY: 'auto',
    },
    content: {
      width: `calc(100% - ${sideBarWidth}px)`,
      height: `calc(100vh - 56px)`,
      overflowY: 'visible',
      padding: '48px 40px',
      '& > :last-child': {
        // 編集時にポップアップのフッターからコンテントが隠れるのを防ぐためのpadding
        paddingBottom: '75px',
      },
    },
    flex: {
      flexGrow: 1,
    },
    form: {
      backgroundColor: theme.palette.common.white,
      width: '100%',
      overflowY: 'visible',
    },
    pageSubTitle1: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
    },
    pageSubTitle2: {
      fontWeight: 'bold',
      color: theme.palette.secondary.dark,
    },
    divider: {
      margin: '16px 0',
      width: '100%',
    },
    link: {
      color: theme.palette.secondary.dark,
    },
  })
);

/**
 * 以下はuseSettingsContentTemplateをStyledに変換したもの
 */

export const StyledSettingsTempForm = styled(Form)(({theme}: {theme: Theme}) => ({
  backgroundColor: theme.palette.common.white,
  overflowY: 'visible',
  width: `calc(100% - ${sideBarWidth}px)`,
  height: `calc(100% - 0px)`,
}));

export const StyledSettingsTempPageTitle = styled(Typography)({
  fontWeight: 'bold',
});

export const StyledSettingsTempSideBar = styled(Grid)({
  width: `${sideBarWidth}px`,
  height: `calc(100vh - 56px)`,
  overflowY: 'auto',
});

export const StyledSettingsTempContent = styled(Grid)({
  padding: '48px 40px',
  '& > :last-child': {
    paddingBottom: '75px',
  },
});

export const StyledSettingsTempFlex = styled('div')({
  flexGrow: 1,
});

export const StyledSettingsTempPageSubTitle1 = styled(Typography)(({theme}: {theme: Theme}) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
}));

export const StyledSettingsTempPageSubTitle2 = styled('div')(({theme}: {theme: Theme}) => ({
  fontWeight: 'bold',
  color: theme.palette.secondary.dark,
}));

export const StyledSettingsTempDivider = styled(Divider)({
  margin: '16px 0',
  width: '100%',
});

export const StyledSettingsTempLink = styled('a')(({theme}: {theme: Theme}) => ({
  color: theme.palette.secondary.dark,
}));
