import React, {useMemo} from 'react';
import {Theme, List, ListItem, Typography, Drawer as MuiDrawer, styled} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {Section} from './section';
import {accountSection, settingSection} from './consts';

// スタイルを適用したDrawerコンポーネント
const StyledDrawer = styled(MuiDrawer)(({theme}: {theme: Theme}) => ({
  width: '256px',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    zIndex: 0,
    backgroundColor: theme.palette.grey[50],
    width: '256px',
    marginTop: '56px',
  },
}));

// スタイルを適用したdivコンポーネント
const DrawerContainer = styled('div')({
  overflow: 'auto',
  maxHeight: 'calc(100vh - 88px)', // NOTE:88pxはヘッダーの56pxとPaddingの32pxを足した値
  padding: '32px 0px 0px 16px',
});

// スタイルを適用したTypographyコンポーネント
const PageTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '24px',
  marginBottom: '36px',
  whiteSpace: 'nowrap',
});

/**
 * サイドバーを表示するコンポーネント
 * 「アカウント設定」と「設定」にて利用
 */
export const Sidebar: React.FC = () => {
  const location = useLocation();
  const sectionTitle = useMemo(() => {
    return location.pathname.startsWith('/account/') ? 'アカウント設定' : '設定';
  }, [location]);
  const section = useMemo(() => {
    return location.pathname.startsWith('/account/') ? accountSection : settingSection;
  }, [location]);

  return (
    <StyledDrawer variant="permanent">
      <DrawerContainer>
        <List>
          <ListItem>
            <PageTitle variant="h6">{sectionTitle}</PageTitle>
          </ListItem>
          {section.map((x) => (
            <Section key={x.sectionName} sectionName={x.sectionName} url={x.url}>
              {x.children}
            </Section>
          ))}
        </List>
      </DrawerContainer>
    </StyledDrawer>
  );
};
