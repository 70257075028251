import React, {Suspense} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  IconButton,
  Divider,
  Typography,
  styled,
  CSSObject,
} from '@mui/material';
import {DialogProps} from '@molecules/Dialogs/DialogHandler';
import CloseIcon from '@mui/icons-material/Close';
import {WholeProductSearchPullDown} from '@organisms/WholeProductSearchPullDownBtn/WholeProductSearchPullDown';
import ProgressImage from '@assets/UnifyProductImage.svg';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {InnerLoading} from '@components/molecules/Loading';
import {FormLabelBadge} from '@components/molecules/FormLabelBadge';
export type UnifyWholeProductModalProps = DialogProps;

export type UnifyWholeProductModalResult = {
  fromWholeProduct: string;
  toWholeProduct: string;
};

// Zodスキーマを修正
const schema = z.object({
  fromWholeProduct: z.string().min(1, '移行元の機種を選択してください'),
  toWholeProduct: z.string().min(1, '移行先の機種を選択してください'),
});

type FormInputs = z.infer<typeof schema>;

const StyledCloseIcon = styled(CloseIcon)({
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#172B4D',
});
const StyledDoubleArrowIcon = styled(DoubleArrowIcon)({
  fontSize: '24px',
  color: '#172B4D',
  padding: '0px 24px',
  marginTop: '48px',
});

const dialogStyle: CSSObject = {'& .MuiPaper-root': {padding: '16px'}};
const dialogTitleStyle: CSSObject = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const dialogTitleTextStyle: CSSObject = {
  fontWeight: '600',
  fontSize: '20px',
  fontColor: '#172B4D',
};

const dialogActionsStyle: CSSObject = {
  padding: '16px 8px',
};

const boxContainerStyle: CSSObject = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const boxStyle: CSSObject = {
  width: '100%',
};

const buttonStyle: CSSObject = {
  padding: '8px 16px',
  fontSize: '15px',
  '&.Mui-disabled': {
    backgroundColor: ' #D1D5DB',
    color: 'white',
  },
};

const dividerStyle: CSSObject = {
  margin: '16px',
  color: '#D1D5DB',
};

const StyledProgressImage = styled('img')({
  maxWidth: '100%',
  padding: '16px',
});

const ListBoxStyled = styled(Box)({
  width: '100%',
});

const ListUlStyle = styled('ul')({
  counterReset: 'list-counter',
  fontSize: '14px',
  padding: '0px 16px 16px',
  margin: 0,
});

const ListLiStyle = styled('li')({
  position: 'relative',
  paddingLeft: '32px',
  listStyle: 'none',
  counterIncrement: 'list-counter',
  '&::before': {
    content: '"※" counter(list-counter)"："',
    position: 'absolute',
    left: 0,
    color: '#172B4D',
  },
  // 4番目の要素
  '&:nth-of-type(4)': {
    fontWeight: 'bold',
    color: '#C7243A',
  },
});

const InformationListComponent = () => {
  return (
    <ListBoxStyled>
      <ListUlStyle>
        <ListLiStyle>
          機種情報は以下を指します。 <br />
          ・大分類、小分類、機種名、型式、メーカー名、承認番号、JMDNコード、JANコード、GTINコード、特定保守製品、クラス分類、定価、研修対象
        </ListLiStyle>
        <ListLiStyle>添付文書・機種共通ファイルは、移行元/先の全データを統合後の機種に紐づけます。</ListLiStyle>
        <ListLiStyle>
          機種を統合することにより、移行元となる機種に紐づいていた機器の、以下点検情報は削除されません。 <br />
          ・スキップした点検実施予定、実施途中の点検、及び完了した点検
        </ListLiStyle>
        <ListLiStyle>
          機種を統合することにより、移行元となる機種に紐づいていた機器の、定期点検計画は削除されます。統合後、再設定をお願いします。
        </ListLiStyle>
      </ListUlStyle>
    </ListBoxStyled>
  );
};

const DialogTitleComponent = ({onClick}: {onClick: VoidFunction}) => {
  return (
    <DialogTitle sx={dialogTitleStyle}>
      <Typography sx={dialogTitleTextStyle}>統合する機種を選択</Typography>
      <IconButton onClick={onClick}>
        <StyledCloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

const DialogActionsComponent = ({onClick, disabled}: {onClick: VoidFunction; disabled: boolean}) => {
  return (
    <DialogActions sx={dialogActionsStyle}>
      <Button type="submit" variant="contained" color="primary" sx={buttonStyle} disabled={disabled}>
        決定
      </Button>
      <Button onClick={onClick} sx={buttonStyle}>
        キャンセル
      </Button>
    </DialogActions>
  );
};

const PullDownTitle = ({title}: {title: string}) => {
  return (
    <Box display="flex" alignItems="center" gap={1} padding="8px">
      <Typography>{title}</Typography>
      <FormLabelBadge required={true} />
    </Box>
  );
};

/**
 * 機種統合ダイアログ
 * @param props
 * @returns
 */
export const UnifyWholeProductModal: React.FC<UnifyWholeProductModalProps> = ({
  open,
  actions,
}: UnifyWholeProductModalProps) => {
  const {
    handleSubmit,
    formState: {errors, isValid},
    setValue,
    getValues,
  } = useForm<FormInputs>({
    resolver: zodResolver(schema),
    mode: 'onChange', // 変更をバリデーションに反映
  });

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    const result: UnifyWholeProductModalResult = {
      fromWholeProduct: data.fromWholeProduct,
      toWholeProduct: data.toWholeProduct,
    };
    actions.resolve(result);
  };

  const fromSelectPullDown = (value: string) => {
    setValue('fromWholeProduct', value, {shouldValidate: true});
  };
  const toSelectPullDown = (value: string) => {
    setValue('toWholeProduct', value, {shouldValidate: true});
  };

  const handleClose = () => {
    actions.reject();
  };

  // フォームの値を取得
  const fromWholeProductHashID = getValues('fromWholeProduct') ?? undefined;
  const toWholeProductHashID = getValues('toWholeProduct') ?? undefined;

  return (
    <Dialog open={!!open} fullWidth maxWidth="lg" sx={dialogStyle}>
      <Suspense fallback={<InnerLoading />}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitleComponent onClick={handleClose} />
          <DialogContent>
            <Box sx={boxContainerStyle}>
              <Box sx={boxStyle}>
                <PullDownTitle title="移行元となる機種" />
                <WholeProductSearchPullDown
                  onSelect={fromSelectPullDown}
                  pullDownType="from"
                  excludedWholeProductHashIds={toWholeProductHashID ? [toWholeProductHashID] : []} // 移行元の機種一覧には移行先の機種を除外し一覧表示する
                />
                {errors.fromWholeProduct && <Typography color="error">{errors.fromWholeProduct.message}</Typography>}
              </Box>
              <StyledDoubleArrowIcon />
              <Box sx={boxStyle}>
                <PullDownTitle title="移行先となる機種" />
                <WholeProductSearchPullDown
                  onSelect={toSelectPullDown}
                  pullDownType="to"
                  excludedWholeProductHashIds={fromWholeProductHashID ? [fromWholeProductHashID] : []} // 移行先の機種一覧には移行元の機種を除外し一覧表示する
                />
                {errors.toWholeProduct && <Typography color="error">{errors.toWholeProduct.message}</Typography>}
              </Box>
            </Box>
          </DialogContent>
          <DialogActionsComponent onClick={handleClose} disabled={!isValid} />
        </form>
        <Divider sx={dividerStyle} />
        <StyledProgressImage src={ProgressImage} alt="機種の統合" />
        <InformationListComponent />
      </Suspense>
    </Dialog>
  );
};
