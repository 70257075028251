import React, {useMemo, useState} from 'react';
import {Autocomplete, TextField, FormControl, Box} from '@mui/material';
import {PullDownType, useWholeProductSearchPullDown} from './hooks';
import {WholeProductIndex} from '@modules/products/types';
import {PaginationButtons} from './PaginationButtons';

const buttonArea = 'buttonArea';

// ページネーションを出すためのダミーデータ
const dummyData: WholeProductIndex = {
  hashId: '',
  displayName: buttonArea,
  name: '',
  hospitalHashId: '',
  thumbnailUrl: '',
  expired: false,
  className: '',
  maker: {
    hashId: '',
    name: '',
    address: '',
    tel: '',
  },
  categories: [],
  isTransmitter: false,
  isShared: false,
};

type PaginationOption = {
  props: React.HTMLAttributes<HTMLLIElement>;
  totalCount: number;
  currentPage: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
};

/**
 * ページネーションのコンポーネント
 * @param param0
 * @returns
 */
const PaginationOption = ({props, totalCount, currentPage, pageSize, onPageChange}: PaginationOption) => {
  const handleButtonClick = (e: React.MouseEvent) => {
    //  セレクトボックスが閉じないように
    e.stopPropagation();
  };

  return (
    <Box
      component="li"
      {...props}
      display="flex"
      alignItems="center"
      sx={{width: '100%'}}
      onMouseDown={(e) => e.preventDefault()}
      onClick={handleButtonClick}>
      <PaginationButtons
        totalCount={totalCount}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={onPageChange}
      />
    </Box>
  );
};

type WholeProductSearchPullDownProps = {
  onSelect: (value: string) => void;
  pullDownType?: PullDownType;
  excludedWholeProductHashIds?: string[];
};

/**
 * 機種統合のプルダウン
 * @param param0
 * @returns
 */
export const WholeProductSearchPullDown: React.FC<WholeProductSearchPullDownProps> = ({
  onSelect,
  pullDownType,
  excludedWholeProductHashIds,
}) => {
  const {selectedValue, data, handleChange, totalCount, handleInputChange, handlePageChange} =
    useWholeProductSearchPullDown({
      onSelect,
      pullDownType,
      excludedWholeProductHashIds,
    });
  const [page, setPage] = useState<number>(1);

  return (
    <FormControl variant="outlined" fullWidth>
      <Autocomplete
        id={`whole-product-search-pulldown-${pullDownType}`}
        options={[...data, dummyData]} // 最後にダミーデータを追加してページネーションを表示
        value={selectedValue}
        clearOnBlur={false}
        getOptionLabel={(option) => `${option.displayName} ${option.name}`}
        onChange={(_e, newValue) => {
          if (newValue?.displayName === buttonArea) return; //ダミーデータは何もしない
          handleChange(newValue);
        }}
        disableCloseOnSelect={false}
        isOptionEqualToValue={(option, value) => option.hashId === value?.hashId} // ここで比較方法をカスタマイズ
        renderOption={(props, option) => {
          // ダミーデータであればページネーションコンポーネントを表示
          if (option.displayName === 'buttonArea') {
            return (
              <PaginationOption
                key={'pagination_option'}
                props={props}
                totalCount={totalCount}
                currentPage={page}
                pageSize={100} // 100件固定
                onPageChange={(newPage) => {
                  setPage(newPage);
                  handlePageChange(newPage);
                }}
              />
            );
          } else {
            return (
              <Box component="li" {...props} key={option.hashId}>
                <span>{`${option.displayName} ${option.name}`}</span>
              </Box>
            );
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="機種名・型式で検索"
            variant="outlined"
            onChange={(e) => {
              handleInputChange(e.target.value);
            }}
          />
        )}
      />
    </FormControl>
  );
};
