import {TextField} from '@material-ui/core';
import {Selector} from '@molecules/Selector';
import React from 'react';
// import {productStatusOptions} from '@modules/hospital_products/constants';
import {FilterOption} from '@components/molecules/Drawers/FilterDrawer/types';
import {formatLotNumber} from '@modules/hospital_products/utls';
import {DateField} from '@molecules/DateField';
import {
  OptionString,
  OptionStringList,
} from '../../ProductsRegistrationDialog/Step2/EditableColumnModal/EditableColumnModal';
import {waysOfPurchaseOptions} from '../../constants';
import {NoteFieldFilterOptionType} from './constants';
import {ColumnType, Column} from './types';

const commonStyle: React.CSSProperties = {
  width: '100%',
};

/**
 * 汎用項目用のColumnを返す
 * @note 2024/03/14現在使われているtypeはdateとtextareaのみ、その他は拡張用
 * @param label 表示名
 * @param name  API送信時のKey
 * @param type  'text' | 'textarea' | 'number' | 'date' | 'select'
 * @param options typeがselectの時用
 * @returns
 */
export const getNotesColumn = (label: string, name: string, type: ColumnType, options?: OptionString[]): Column => {
  const columnConfig: Record<ColumnType, Column> = {
    date: {
      type: 'date',
      label: label,
      name: `${name.replace('Date', '')}`, // NOTE:nameに'Date'の文字列があれば削除する
      InputComponent: DateField,
      props: {
        style: commonStyle,
      },
    },
    textarea: {
      type: 'textarea',
      label: label,
      name: name,
      InputComponent: TextField,
      props: {
        variant: 'outlined',
        multiline: true,
        style: commonStyle,
      },
    },
    text: {
      type: 'text',
      label: label,
      name: name,
      InputComponent: TextField,
      props: {
        variant: 'outlined',
        style: commonStyle,
      },
    },
    number: {
      type: 'number',
      label: label,
      name: name,
      InputComponent: TextField,
      props: {
        variant: 'outlined',
        type: 'number',
        style: commonStyle,
      },
    },
    select: {
      type: 'select',
      label: label,
      name: name,
      InputComponent: Selector,
      props: {
        options: options,
      },
    },
  };

  return columnConfig[type];
};

/**
 * 病院の製品情報管理における各列の設定を生成します。
 *
 * @param hospitalRoomOptions 病院の部屋選択肢を表すオプションリスト。
 * @param hospitalDealerOptionsList 代理店選択肢を表すオプションリスト。
 * @param hospitalProductMgmtSectionOptions 製品管理部署選択肢を表すオプションリスト。
 * @param notesDataList 汎用項目のデータリスト。各項目は表示可否とラベル、値、オプションタイプを持つ。
 * @returns `Column[]` 形式の列設定配列。各列は選択肢、日付、数値、テキストフィールドを含むことができ、病院の部屋、代理店、購入区分などの具体的な情報を管理するための設定が含まれます。
 */
export const columns = (
  hospitalRoomOptions: OptionStringList,
  hospitalDealerOptionsList: OptionStringList,
  hospitalProductMgmtSectionOptions: OptionStringList,
  notesDataList: FilterOption[]
): Column[] => {
  const columnDataList: Column[] = [
    {
      type: 'select',
      label: '貸出区分',
      name: 'permanentlyAssigned',
      InputComponent: Selector,
      props: {
        options: [
          {label: '貸出不可', value: true},
          {label: '貸出可', value: false},
        ],
      },
    },
    // {
    //   type: 'select',
    //   label: '稼働状況',
    //   name: 'status',
    //   InputComponent: Selector,
    //   props: {
    //     options: productStatusOptions,
    //   },
    // },
    {
      type: 'select',
      label: '管理部署',
      name: 'hospitalProductMgmtSectionHashId',
      InputComponent: Selector,
      props: {
        options: hospitalProductMgmtSectionOptions,
      },
    },
    {
      type: 'date',
      label: '購入日',
      name: 'dateOfPurchase',
      InputComponent: DateField,
      props: {
        style: {width: '100%'},
      },
    },
    {
      type: 'select',
      label: '購入区分',
      name: 'waysOfPurchase',
      InputComponent: Selector,
      props: {
        options: waysOfPurchaseOptions,
      },
    },
    {
      type: 'select',
      label: '機器管理場所',
      name: 'hospitalRoomHashID',
      InputComponent: Selector,
      props: {
        options: hospitalRoomOptions,
      },
    },
    {
      type: 'number',
      label: '院内耐用年数（年）',
      name: 'legalDurableYear',
      InputComponent: TextField,
      props: {
        variant: 'outlined',
        style: {width: '100%'},
        type: 'number',
      },
    },
    {
      type: 'select',
      label: '保守契約',
      name: 'isMaintenanceContract',
      InputComponent: Selector,
      props: {
        options: [
          {label: '保守契約', value: true},
          {label: '保守契約外', value: false},
        ],
      },
    },
    {
      type: 'select',
      label: '親機・子機',
      name: 'isBaseUnit',
      InputComponent: Selector,
      props: {
        options: [
          {label: '親機', value: true},
          {label: '子機', value: false},
        ],
      },
    },
    {
      type: 'select',
      label: '担当代理店',
      name: 'hospitalDealerHashId',
      InputComponent: Selector,
      props: {
        options: hospitalDealerOptionsList,
      },
    },
    {
      type: 'text',
      label: '資産番号',
      name: 'assetRegisterNumber',
      InputComponent: TextField,
      props: {
        variant: 'outlined',
        style: {width: '100%'},
      },
    },
    {
      type: 'text',
      label: 'ロット番号',
      name: 'lotNumber',
      InputComponent: TextField,
      formatter: formatLotNumber,
      props: {
        variant: 'outlined',
        style: {width: '100%'},
      },
    },
    {
      type: 'date',
      label: '廃棄日',
      name: 'dateOfDisposal',
      InputComponent: DateField,
      props: {
        style: {width: '100%'},
      },
    },
    {
      type: 'textarea',
      label: '廃棄理由',
      name: 'reasonOfDisposal',
      InputComponent: TextField,
      props: {
        variant: 'outlined',
        style: {width: '100%'},
      },
    },
  ];

  // 汎用項目のリスト
  const notesColumnList = notesDataList
    .filter((v) => v.visible)
    .map((v) => {
      const type: ColumnType = NoteFieldFilterOptionType[v.optionType];
      const options = v.optionType === 'selector' ? v.options : undefined;
      return getNotesColumn(v.label, v.value, type, options);
    });
  return [...columnDataList, ...notesColumnList];
};
