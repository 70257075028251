import {useReducer, useEffect} from 'react';
import {useHospitalProductStore} from '@modules/hospital_products/hooks/useHospitalProduct';
import {HospitalProductState} from '@modules/hospital_products/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {codeReaderReducer, codeReaderInitialState} from './reducers';
import {CodeReaderType, CodeReaderState, CodeReaderActionType} from './types';
import {CodeReaderTypes, LocalStorageKeys} from './constants';

type HospitalProductMenuStoreType = {
  hospitalProductState: HospitalProductState;
  resetHospitalProductState: () => void;
  listHospitalProductsByManagementId: (managementId: string) => Promise<void>;
};

export const useHospitalProductMenuStore = (): HospitalProductMenuStoreType => {
  const {myInfo} = useMyInfo();
  const {
    state: hospitalProductState,
    dispatchResetHospitalProducts,
    dispatchListHospitalProducts,
  } = useHospitalProductStore();

  const resetHospitalProductState = () => {
    dispatchResetHospitalProducts();
  };

  const listHospitalProductsByManagementId = async (managementId: string) => {
    if (!managementId) {
      return;
    }

    const statuses = 'ready,working,uninspected,repairing'; // 廃棄機器は除外
    await dispatchListHospitalProducts(myInfo.hospitalHashId, {managementId, statuses});
  };

  return {
    hospitalProductState,
    resetHospitalProductState,
    listHospitalProductsByManagementId,
  };
};

type CodeReaderStoreType = {
  codeReaderState: CodeReaderState;
  updateCodeReaderType: (codeReaderType: CodeReaderType) => void;
};

export const useCodeReaderStore = (): CodeReaderStoreType => {
  const [state, dispatch] = useReducer(codeReaderReducer, codeReaderInitialState);

  const isCodeReaderType = (codeReaderType: string): codeReaderType is CodeReaderType => {
    return (CodeReaderTypes as readonly string[]).includes(codeReaderType);
  };

  const loadCodeReaderType = () => {
    const codeReaderType = localStorage.getItem(LocalStorageKeys.CodeReaderType);
    if (!codeReaderType) {
      return;
    }
    if (!isCodeReaderType(codeReaderType)) {
      return;
    }

    dispatch({
      type: CodeReaderActionType.CODE_READER_PUT_ITEM,
      payload: {codeReaderType},
    });
  };

  useEffect(() => {
    loadCodeReaderType();
  }, []);

  const updateCodeReaderType = (codeReaderType: CodeReaderType) => {
    dispatch({
      type: CodeReaderActionType.CODE_READER_PUT_ITEM,
      payload: {codeReaderType},
    });
    localStorage.setItem(LocalStorageKeys.CodeReaderType, codeReaderType);
  };

  return {
    codeReaderState: state,
    updateCodeReaderType,
  };
};
