import React, {memo} from 'react';
import {styled} from '@material-ui/styles';
import {FormLabelBadge} from '@molecules/FormLabelBadge';
import {Theme} from '@material-ui/core';

const StyledLabel = styled('label')(({gap}: {gap?: number}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: gap ? `${gap}px` : '16px',
  color: '#172B4D',
}));

const StyledTitle = styled('span')(({theme}: {theme: Theme}) => ({
  fontSize: '20px',
  [theme.breakpoints.up('tabletV')]: {
    fontSize: '18px',
  },
}));
const StyledExample = styled('span')({
  fontSize: '16px',
});

type Props = {
  title: string;
  required?: boolean;
  example?: string;
  badgeGap?: number;
};

/**
 * 不具合受付入力画面の入力項目のラベル
 * @param param0
 * @returns
 */
export const HeadingLabel = memo(({title, example, required = true, badgeGap}: Props) => {
  return (
    <StyledLabel gap={badgeGap}>
      <StyledTitle>{title}</StyledTitle>
      <FormLabelBadge required={required} padding="1px 6px" />
      {example && <StyledExample>{example}</StyledExample>}
    </StyledLabel>
  );
});
