/**
 * ZenDeskのWidgetの表示を切り替える
 * 画面右下のChatBot表示のボタンの表示
 * @see https://developer.zendesk.com/api-reference/widget-messaging/web/core/
 * @param show
 */
export const ZendeskWidgetVisible = (show: boolean) => {
  try {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (window as any).zE('messenger', show ? 'show' : 'hide');
  } catch (error) {
    console.error(error);
  }
};
