import React from 'react';
import ReactDOM from 'react-dom';
import {SnackbarProvider, useSnackbar, OptionsObject, VariantType, SnackbarKey} from 'notistack';
import {IconButton, StyledEngineProvider} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';

// render snackbar DOM into div element in index.html
const mountPoint = document.getElementById('snackbar');

const defaultEnqueueOptions = {
  autoHideDuration: 5000,
};

/**
 * スナックバーを表示する
 * @param msg - スナックバーに表示するメッセージ
 * @param variant - スナックバーのタイプ。大体successとかをよく使うはずなのでデフォルトはsuccess
 * @param horizontal - スナックバーの水平表示位置。大体左下のはずなのでleftがデフォルト
 * @param vertical - スナックバーの垂直表示位置。大体左下のはずなのでbottomがデフォルト
 * @param options - スナックバーのオプション。 OptionsObject を参照(variantは除く)
 */
export const openSnackBar = (
  msg: string,
  horizontal: 'left' | 'center' | 'right' = 'left',
  vertical: 'top' | 'bottom' = 'bottom',
  variant: VariantType = 'success',
  options: OptionsObject = {}
) => {
  const ShowSnackbar: React.FC<{message: string; opts?: OptionsObject}> = ({message, ...opts}) => {
    const {enqueueSnackbar} = useSnackbar();
    enqueueSnackbar(message, {variant, ...defaultEnqueueOptions, ...opts});
    return null;
  };

  ReactDOM.render(
    <StyledEngineProvider injectFirst>
      <SnackbarProvider
        action={(key) => <SnackbarCloseButton id={key} />}
        iconVariant={{error: <ErrorOutlineIcon />}}
        maxSnack={3}
        anchorOrigin={{horizontal, vertical}}>
        <ShowSnackbar message={msg} {...options} />
      </SnackbarProvider>
    </StyledEngineProvider>,
    mountPoint
  );
};

const SnackbarCloseButton = ({id}: {id: SnackbarKey}) => {
  const {closeSnackbar} = useSnackbar();

  return (
    <IconButton
      onClick={() => {
        closeSnackbar(id);
      }}>
      <CloseIcon style={{color: 'white'}} />
    </IconButton>
  );
};
