import {
  updateHospitalProduct,
  createHospitalProduct,
  bulkCreateHospitalProducts,
  FetchHospitalProductsParams,
  getHospitalProducts,
  FetchHospitalProductsResult,
} from '../api';
import {useInfiniteQuery, useQuery} from 'react-query';
import {getHospitalProductKey, getHospitalProductsKey} from '@constants/api';
import {useEffect, useMemo, useReducer} from 'react';
import {getHospitalProduct} from '@modules/hospital_products/api/hospitalProductApi';
import {axios} from '@front-libs/core';
import {reducer, initialState} from '../reducers/hospitalProduct';
import {HospitalProductState, HospitalProductActionType} from '../types';

export const useHospitalProduct = () => {
  return {
    createHospitalProduct,
    updateHospitalProduct,
    bulkCreateHospitalProducts,
  };
};

export const useFetchHospitalProductQuery = (hospitalHashId: string, hospitalProductHashId: string) => {
  return useQuery([getHospitalProductKey, hospitalHashId, hospitalProductHashId], () =>
    getHospitalProduct(hospitalHashId, hospitalProductHashId)
  );
};

export const useFetchHospitalProductsQuery = (hospitalHashId: string, params: FetchHospitalProductsParams) => {
  const query = useQuery([getHospitalProductsKey, hospitalHashId, params], () =>
    getHospitalProducts(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};

export const useFetchInfiniteHospitalProductsQuery = (hospitalHashId: string, params: FetchHospitalProductsParams) => {
  const {data, isLoading, isFetching, hasNextPage, fetchNextPage} = useInfiniteQuery(
    [getHospitalProductsKey, hospitalHashId, params], // page をキーに含める
    ({pageParam = 0}) => {
      return getHospitalProducts(hospitalHashId, {...params, page: pageParam}); // ページ番号をパラメータに含める
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.data.length === 0) {
          return undefined; // データがない場合、次のページはなし
        }
        return allPages.length + 1; // 次のページ番号を返す
      },
    }
  );

  // 最後までスクロールしたらページを1ずつ増やす
  useEffect(() => {
    if (hasNextPage && !isFetching) {
      fetchNextPage(); // 引数なしで呼び出す
    }
  }, [data?.pages, hasNextPage, isFetching, isLoading, fetchNextPage]);

  return {data, isLoading, isFetching, hasNextPage, fetchNextPage};
};

export const useFetchInfiniteHospitalProductsQuery2 = () => {
  const LIMIT = 10;
  const fetchData = async (page: number) => {
    const response = await axios.get<Post[]>(`https://jsonplaceholder.typicode.com/posts?_page=${page}`);
    const data = response.data;
    return data;
  };

  const {data, isLoading, isFetching, hasNextPage, fetchNextPage} = useInfiniteQuery(
    'todos',
    ({pageParam = 1}) => fetchData(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = lastPage.length === LIMIT ? allPages.length + 1 : undefined;
        return nextPage;
      },
    }
  );
  return {data, isLoading, isFetching, hasNextPage, fetchNextPage};
};

interface Post {
  userId: number;
  id: number;
  title: string;
  body: string;
}

type HospitalProductStoreType = {
  state: HospitalProductState;
  dispatchResetHospitalProducts: () => void;
  dispatchListHospitalProducts: (
    hospitalHashId: string,
    params: FetchHospitalProductsParams
  ) => Promise<FetchHospitalProductsResult>;
};

export const useHospitalProductStore = (): HospitalProductStoreType => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchResetHospitalProducts = () => {
    dispatch({
      type: HospitalProductActionType.HOSPITAL_PRODUCT_SET_LIST,
      payload: {hospitalProducts: []},
    });
  };

  const dispatchListHospitalProducts = async (hospitalHashId: string, params: FetchHospitalProductsParams) => {
    const res = await getHospitalProducts(hospitalHashId, params);
    dispatch({
      type: HospitalProductActionType.HOSPITAL_PRODUCT_SET_LIST,
      payload: {hospitalProducts: res.data},
    });
    return res;
  };

  return {
    state,
    dispatchResetHospitalProducts,
    dispatchListHospitalProducts,
  };
};
