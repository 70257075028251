import React from 'react';
import {styled} from '@material-ui/core';
import {Button} from '@material-ui/core';

type Props = {
  label: string;
  onChange: () => void;
};

export const ConfirmCard = ({label, onChange}: Props) => {
  return (
    <CardRoot onClick={onChange} data-testid={'menu-card'}>
      {label}
    </CardRoot>
  );
};
const CardRoot = styled(Button)(({theme}) => ({
  borderRadius: 'clamp(4px,2vw,24px)',
  boxShadow: '0px 8px 0px 0px rgba(209, 216, 245, 1)',
  '&:active': {
    color: theme.palette.rent.light,
  },
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 'clamp(10px, 2vmin, 24px)', // 24 / 1440 * 100 = 1.66666
  color: theme.palette.rent.main,
  backgroundColor: 'white',
  lineHeight: '1.3em',
}));
