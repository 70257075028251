import React, {useCallback, useRef, useState} from 'react';
import {FileUploadButton} from '@components/molecules/Buttons/sp/FileUploadButton';
import {MobileFileListItem} from '@components/molecules/MobileFileListItem';
import {FileIndex} from '@modules/files/types';
import {styled} from '@material-ui/styles';
import {openSnackBar} from '@components/molecules/SnackBar';
import {uploadFile} from '@modules/files/api';
import {MobileUnderBox} from '@components/molecules/MobileUnderMenu';
import {MobileMenuList, ListItemType} from '@components/molecules/MobileUnderMenu/MobileMenuList';
import {dialogHandler} from '@components/molecules/Dialogs/DialogHandler';
import {FilePreviewDialog, FilePreviewDialogProps} from '@components/molecules/Dialogs/sp/FilePreviewDialog';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// TODO:以下2つのICONは他チケットで使用するためコメントアウト
// import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
// import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const StyledInput = styled('input')({
  display: 'none',
});
const StyledList = styled('div')({
  backgroundColor: 'white',
});
const StyledListBackground = styled('div')({
  padding: '10px 16px 0px',
});

type FileMenuButtonType = 'open' | 'rename' | 'delete';

const menuButtonListItem: ListItemType<FileMenuButtonType>[] = [
  {type: 'open', primary: '開く', icon: <OpenInNewIcon />},
  // TODO:以下2つは他チケットで対応
  // {type: 'rename', primary: 'ファイル名を変更', icon: <CreateOutlinedIcon />},
  // {type: 'delete', primary: 'ファイルを削除', icon: <DeleteOutlineOutlinedIcon />},
];

type FileListContainerProps = {
  files?: FileIndex[];
  handleUploadFile: (file: FileIndex) => Promise<void>;
};

/**
 * スマホ用点検のファイルリスト
 * @param param0
 * @returns
 */
export const FileListContainer = ({files, handleUploadFile}: FileListContainerProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileIndex>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const fileUploaded = event.target.files[0];

    try {
      const newFile = await uploadFile({
        file: fileUploaded,
        fileName: fileUploaded.name,
        category: 'inspection_result',
      });
      await handleUploadFile(newFile.data);
      openSnackBar('ファイルをアップロードしました');
    } catch (_e) {
      openSnackBar('ファイルのアップロードに失敗しました', 'left', 'bottom', 'error');
    }
  };
  /** input要素をクリックして動作させる */
  const onClickUpLoad = () => {
    fileInputRef.current?.click();
  };

  const closeListMenu = useCallback(() => {
    setSelectedFile(undefined);
    setOpenMenu(false);
  }, []);

  const onClickListMenu = useCallback(
    (fileHashId: string) => {
      setSelectedFile(files?.find((v) => v.hashId === fileHashId));
      setOpenMenu(true);
    },
    [files]
  );

  /** プレビューダイアログ表示 */
  const handleClickPreview = useCallback(
    async (data: FileIndex) => {
      console.log('data', data);
      await dialogHandler.open<FilePreviewDialogProps>(FilePreviewDialog, {
        fileName: data.fileName,
        fileType: data.fileType,
        title: data.fileName,
        fileHashId: data.hashId,
      });
      closeListMenu();
    },
    [closeListMenu]
  );

  const onClickFileMenuList = useCallback(
    (buttonType: FileMenuButtonType) => {
      if (!selectedFile) return;
      switch (buttonType) {
        case 'open':
          handleClickPreview(selectedFile);
          break;
        case 'rename':
          // TODO:別タスク 名前の変更押下時の挙動
          break;
        case 'delete':
          // TODO:別タスク 削除押下時の挙動
          break;
        default:
          break;
      }
    },
    [handleClickPreview, selectedFile]
  );

  return (
    <>
      {openMenu && selectedFile && (
        <MobileUnderBox close={closeListMenu}>
          <MobileMenuList<FileMenuButtonType>
            buttonListItem={menuButtonListItem}
            onClick={onClickFileMenuList}
            fileName={selectedFile.fileName}
            ariaLabel="開く ファイル名変更 ファイル削除"
          />
        </MobileUnderBox>
      )}
      <StyledListBackground>
        {files?.map((item, index) => (
          <StyledList key={`files_${index}`}>
            <MobileFileListItem
              fileHashId={item.hashId}
              fileName={item.fileName}
              createdAt={item.createdAt}
              createdBy={item.createdBy}
              onClickMenu={onClickListMenu}
            />
          </StyledList>
        ))}
        <FileUploadButton onClick={onClickUpLoad} />
        <StyledInput type="file" onChange={handleChange} ref={fileInputRef} />
      </StyledListBackground>
    </>
  );
};
