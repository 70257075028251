import React from 'react';
import {ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from '@material-ui/core';
import {styled} from '@material-ui/core/styles';

const settingMenuItems = [
  {
    label: 'ログアウト',
    value: 'logout',
  },
] as const;

type Props = {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onClickSettingMenu: (value: (typeof settingMenuItems)[number]['value']) => void;
};

const StyledPaper = styled(Paper)({
  zIndex: 1000,
});

export const PopperMenu: React.FC<Props> = ({open, anchorEl, onClose, onClickSettingMenu}) => {
  const handleMenuItemClick = (value: (typeof settingMenuItems)[number]['value']) => {
    onClickSettingMenu(value);
    onClose();
  };

  return (
    <Popper open={open} transition placement="bottom" anchorEl={anchorEl}>
      {({TransitionProps}) => (
        <Grow {...TransitionProps} style={{transformOrigin: 'placement'}}>
          <StyledPaper>
            {/* NOTE:長押しで表示するため、mouseUpに反応しないようにmouseEvent設定 */}
            <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
              <MenuList>
                {settingMenuItems.map((item) => (
                  <MenuItem key={item.value} onClick={() => handleMenuItemClick(item.value)} data-testid={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </StyledPaper>
        </Grow>
      )}
    </Popper>
  );
};
