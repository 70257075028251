import {Close} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import {FieldErrors, UseFormRegister} from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {ChildUnitFormValue} from '../useChildUnitForm';

const TextStyle: SxProps = {marginTop: '32px'};

const StyledTitle: SxProps = {
  color: '#172B4D',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '24px',
};

const ErrorTextStyle: SxProps = {color: '#C7243A', display: 'flex', marginTop: '16px', gap: '8px'};

export type ChildUnitSelectionDialogProps = {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  register: UseFormRegister<ChildUnitFormValue>;
  errors: FieldErrors<ChildUnitFormValue>;
  isValid: boolean;
};

const DialogActionStyle: SxProps = {
  margin: '32px',
};

const TextFieldStyle: SxProps = {width: '100%'};

/**
 * 登録する子機の管理番号を入力ダイアログ
 */
export const ChildUnitSelectionDialog = ({
  open,
  onSubmit,
  onClose,
  register,
  errors,
  isValid,
}: ChildUnitSelectionDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle sx={StyledTitle}>
        <Grid container justifyContent="space-between" alignItems="center">
          登録する子機の管理番号を入力
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box>
          <TextField placeholder="管理番号を入力" sx={TextFieldStyle} {...register('managementId')} />
          {errors.managementId && (
            <Box sx={ErrorTextStyle}>
              <InfoOutlinedIcon />
              {errors.managementId?.message}
            </Box>
          )}
          <Typography component={'p'} sx={TextStyle}>
            対象の管理番号を調べたい場合は、機器一覧をご確認ください。
          </Typography>
          <Button
            LinkComponent={'a'}
            href="/products"
            rel="noopener noreferrer"
            target="_blank"
            color="primary"
            variant="text"
            startIcon={<LaunchIcon />}>
            機器一覧を別タブで開く
          </Button>
        </Box>
      </DialogContent>
      <DialogActions sx={DialogActionStyle}>
        <Button variant="contained" color="primary" onClick={onSubmit} disabled={!isValid}>
          登録
        </Button>
        <Button onClick={onClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};
