import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import {z} from 'zod';

const childUnitFormValueSchema = z.object({
  managementId: z
    .string()
    .min(1, '管理番号を入力してください。')
    .regex(/^[A-Za-z0-9]*$/, '半角英数字のみを入力してください。'),
});

export type ChildUnitFormValue = z.infer<typeof childUnitFormValueSchema>;

export const useChildUnitForm = () => {
  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
  } = useForm<ChildUnitFormValue>({
    mode: 'onChange',
    resolver: zodResolver(childUnitFormValueSchema),
  });

  return {register, handleSubmit, errors, isValid};
};
