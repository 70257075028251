import React, {memo} from 'react';
import {Box, Button} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import {theme} from '@components/atoms/theme';
import {ChevronLeft, ChevronRight} from '@material-ui/icons';
import {StrUtil} from '@front-libs/helpers';

type Props = {
  text: string;
  backButtonHandler: VoidFunction;
  nextButton?: {
    label: string;
    disabled: boolean;
    buttonHandler: VoidFunction;
  };
  isWard?: boolean;
};
/**
 * フッターを表示するコンポーネント
 * 戻るボタン、テキスト、およびオプションの次へボタンを含む
 *
 * @todo SharedFooterに移行予定
 *
 * @param {string} props.text - フッターに表示するテキスト
 * @param {VoidFunction} props.backButtonHandler - 「戻る」ボタンのクリックイベントハンドラ
 * @param {Object} [props.nextButton] - 「次へ」ボタンの設定。`label`はボタンに表示するテキスト、`disabled`はボタンが無効化されているかどうかを示し、`buttonHandler`はクリックイベントハンドラです。
 * @param {string} props.nextButton.label - 「次へ」ボタンに表示するテキスト
 * @param {boolean} props.nextButton.disabled - 「次へ」ボタンが無効かどうか
 * @param {VoidFunction} props.nextButton.buttonHandler - 「次へ」ボタンのクリックイベントハンドラ
 *
 * @example
 * ```tsx
 * import React from 'react';
 * import { Footer } from './Footer';
 *
 * function MyApp() {
 *   const handleBack = () => {
 *     console.log('Back button clicked');
 *   };
 *
 *   const handleNext = () => {
 *     console.log('Next button clicked');
 *   };
 *
 *   return (
 *     <Footer
 *       text="ページの説明"
 *       backButtonHandler={handleBack}
 *       nextButton={{
 *         label: "次へ",
 *         disabled: false,
 *         buttonHandler: handleNext
 *       }}
 *     />
 *   );
 * }
 * ```
 * この例では、`Footer`コンポーネントを使用して、「戻る」と「次へ」のボタンが含まれるフッターをレンダリングします。
 * `text`プロパティにはフッターに表示したいテキストを、`backButtonHandler`には「戻る」ボタンのイベントハンドラを、
 * `nextButton`には「次へ」ボタンの設定を渡しています。
 */
export const Footer = memo(
  ({text, backButtonHandler, nextButton, isWard}: Props) => {
    return (
      <StyledContainer>
        {!isWard && (
          <StyledBackButton
            data-testid={'rental-footer-back-button'}
            variant={'outlined'}
            size={'small'}
            onClick={backButtonHandler}>
            <StyledChevronLeft />
            <StyledButtonText>{'戻る'}</StyledButtonText>
          </StyledBackButton>
        )}
        <StyledText>{StrUtil.nl2br(text)}</StyledText>
        {nextButton && (
          <StyledNextButton variant={'outlined'} disabled={nextButton.disabled} onClick={nextButton.buttonHandler}>
            <StyledNextBtnText>{nextButton.label}</StyledNextBtnText>
            <StyledChevronRight />
          </StyledNextButton>
        )}
      </StyledContainer>
    );
  },
  (prevProps, nextProps) => {
    // NextButtonのdisabledが変更された時のみレンダリング対象
    return prevProps.nextButton?.disabled === nextProps.nextButton?.disabled;
  }
);

const StyledContainer = styled(Box)({
  backgroundColor: theme.palette.rent.main,
  height: '128px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
});

const StyledButtonText = styled(Box)({
  paddingTop: '4px',
  paddingRight: '24px',
  fontSize: '24px',
  minWidth: '52px',
});

const StyledNextBtnText = styled(Box)({
  paddingTop: '4px',
  paddingLeft: '24px',
});

const StyledChevronLeft = styled(ChevronLeft)({
  paddingTop: '4px',
  paddingRight: '8px',
  fontSize: '24px',
});

const StyledChevronRight = styled(ChevronRight)({
  paddingTop: '4px',
  paddingRight: '8px',
  fontSize: '24px',
});

const StyledText = styled(Box)({
  color: theme.palette.common.white,
  fontSize: '22px',
  fontWeight: 'bold',
  textAlign: 'center',
  width: '40%',
  [theme.breakpoints.up('tabletV')]: {
    fontSize: '32px',
    width: '60%',
    lineHeight: '1.2em',
  },
  [theme.breakpoints.up('tabletH')]: {
    fontSize: '36px',
    width: '100%',
  },
  [theme.breakpoints.up('laptop')]: {
    fontSize: '36px',
    width: '100%',
  },
});

const StyledBackButton = styled(Button)({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.rent.main,
  position: 'absolute',
  left: '0px',
  fontSize: '18px',
  fontWeight: 'bold',
  height: '60px',
  width: '108px',
  padding: '5px 0px 5px 8px',
  borderRadius: '0px 45px 45px 0px',
  border: 0,
  boxShadow: '0px 4px 0px 0px rgba(209, 216, 245, 1)',
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },

  [theme.breakpoints.up('tabletV')]: {
    width: '140px',
  },
  [theme.breakpoints.up('tabletH')]: {
    width: '160px',
    fontSize: '20px',
  },
  [theme.breakpoints.up('laptop')]: {
    width: '176px',
    fontSize: '24px',
    padding: '5px 15px',
  },
  '& .MuiButton-label': {
    justifyContent: 'left',
  },
});

const StyledNextButton = styled(Button)({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.rent.main,
  position: 'absolute',
  right: '0px',
  fontSize: '18px',
  fontWeight: 'bold',
  height: '60px',
  padding: '5px 0px 5px 8px',
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
  [theme.breakpoints.up('tabletH')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.up('laptop')]: {
    fontSize: '24px',
    padding: '5px 15px',
  },
  borderRadius: '45px 0px 0px 45px',
  border: 0,
  boxShadow: '0px 4px 0px 0px rgba(209, 216, 245, 1)',
});
