import {CategoryFormatter} from '@modules/categories/helpers';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {
  Box,
  Button,
  Grid,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import _ from 'lodash';
import React, {useMemo} from 'react';

export type TableProps = {
  rentingHospitalProducts: HospitalProductIndex[];
  rentRoom: string;
  onClickCancel: (rowIndex: number) => void;
};

export const RentProductTable = ({rentingHospitalProducts, rentRoom, onClickCancel}: TableProps) => {
  const tableRows = useMemo<Partial<HospitalProductIndex & {rentRoom: string}>[]>(() => {
    return [
      ...rentingHospitalProducts.map((item) => ({
        ...item,
        rentRoom,
      })),
      ...(rentingHospitalProducts.length < 4 ? Array(4 - rentingHospitalProducts.length).fill({}) : []),
    ];
  }, [rentRoom, rentingHospitalProducts]);

  return (
    <Grid container direction="column" sx={{fontFamily: 'Roboto'}}>
      <Grid item>
        <Box sx={tableCaptionStyle}>貸出する医療機器リスト</Box>
      </Grid>
      <Grid item>
        <TableContainer component={Paper} sx={tableContainerStyle} elevation={0}>
          <Table aria-label="simple table" stickyHeader>
            <caption>{`合計${rentingHospitalProducts.length}点`}</caption>
            <TableHead>
              <TableRow sx={tableRowStyle}>
                <TableCell sx={managementIdHeadCellStyle}>管理番号</TableCell>
                <TableCell align="left" sx={rentRoomHeadCellStyle}>
                  貸出場所
                </TableCell>
                <TableCell align="left" sx={categoryHeadCellStyle}>
                  小分類
                </TableCell>
                <TableCell align="left" sx={displayNameHeadCellStyle}>
                  機種名
                </TableCell>
                <TableCell align="left" sx={cancelBtnHeadCellStyle} />
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows.map((row, index) => (
                <TableRow key={index} sx={tableRowStyle}>
                  <TableCell component="th" scope="row" sx={managementIdBodyCellStyle}>
                    {_.truncate(row.managementId, {length: 12})}
                  </TableCell>
                  <TableCell sx={rentRoomBodyCellStyle}>{row.rentRoom}</TableCell>
                  <TableCell sx={categoryBodyCellStyle}>
                    {CategoryFormatter.getNarrowCategory(row.categories ?? [])?.name ?? ''}
                  </TableCell>
                  <TableCell sx={displayNameBodyCellStyle}>{_.truncate(row.displayName, {length: 22})}</TableCell>
                  <TableCell sx={cancelBtnCellStyle}>
                    {row.managementId && (
                      <Button variant="outlined" sx={cancelButtonStyle} onClick={() => onClickCancel(index)}>
                        キャンセル
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

// スタイル定義
const tableContainerStyle: SxProps<Theme> = {
  borderRadius: '10px',
  maxHeight: '360px',
  '& .MuiTable-root caption': {
    textAlign: 'right',
    color: '#374659',
    fontSize: '24px',
    position: 'sticky',
    bottom: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: '#fff',
  },
};

const tableRowStyle: SxProps<Theme> = (theme) => ({
  height: '48px',
  [theme.breakpoints.up('laptop')]: {
    height: '56px',
  },
});

const managementIdHeadCellStyle: SxProps<Theme> = (theme) => ({
  width: '35%',
  fontSize: '24px',
  backgroundColor: theme.palette.common.white,
  padding: '0px 8px 0px 8px',
  [theme.breakpoints.up('laptop')]: {
    padding: '8px 16px 8px 32px',
  },
});

const managementIdBodyCellStyle: SxProps<Theme> = (theme) => ({
  width: '120px',
  fontWeight: 700,
  color: '#2a96e8',
  fontSize: '28px',
  padding: '0px 8px 0px 8px',
  [theme.breakpoints.up('laptop')]: {
    fontSize: '32px',
    padding: '0px 16px 0px 32px',
  },
});

const rentRoomHeadCellStyle: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  padding: '0px 8px 0px 0px',
  [theme.breakpoints.up('laptop')]: {
    padding: '0 16px 0 0',
  },
});

const rentRoomBodyCellStyle: SxProps<Theme> = (theme) => ({
  padding: '0px 8px 0px 0px',
  maxWidth: '96px',
  wordBreak: 'break-all',
  [theme.breakpoints.up('laptop')]: {
    padding: '0 16px 0 0',
  },
});

const categoryHeadCellStyle: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  width: '86px',
  padding: '0px 8px 0px 0px',
  [theme.breakpoints.up('laptop')]: {
    padding: '0 16px 0 0',
  },
});

const categoryBodyCellStyle: SxProps<Theme> = (theme) => ({
  padding: '0px 8px 0px 0px',
  maxWidth: '120px',
  wordBreak: 'break-all',
  [theme.breakpoints.up('laptop')]: {
    padding: '0 16px 0 0',
  },
});

const displayNameHeadCellStyle: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  padding: '0px 8px 0px 0px',
  [theme.breakpoints.up('laptop')]: {
    padding: '0 16px 0 0',
  },
});

const displayNameBodyCellStyle: SxProps<Theme> = (theme) => ({
  padding: '0px 8px 0px 0px',
  wordBreak: 'break-all',
  overflow: 'auto',
  textOverflow: 'ellipsis',
  width: '120px',
  [theme.breakpoints.up('laptop')]: {
    width: '160px',
    padding: '0 16px 0 0',
  },
});

const cancelBtnHeadCellStyle: SxProps<Theme> = {
  backgroundColor: '#fff',
};

const cancelBtnCellStyle: SxProps<Theme> = {
  padding: '0px 16px 0px 8px',
  textAlign: 'right',
};

const cancelButtonStyle: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.rent.main}`,
  borderRadius: '10px',
  color: theme.palette.rent.main,
  boxSizing: 'border-box',
  padding: '10px 8px',
  width: '98px',
  height: '36px',
});

const tableCaptionStyle = {
  textAlign: 'center',
  marginBottom: '16px',
  fontSize: '24px',
};
