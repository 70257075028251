import React, {useEffect, useState, useCallback, Suspense} from 'react';
import request from 'axios';
import {yup} from '@front-libs/core';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {Form, Formik, useFormikContext} from 'formik';
import {
  UpdateHospitalProductParams,
  updateHospitalProduct,
  useFetchHospitalProductQuery,
} from '@modules/hospital_products/api';
import {InnerLoading} from '@molecules/Loading';
import {FormikFormSubmitDrawer} from '@molecules/Formik/FormSubmitDrawer';
import {openSnackBar} from '@molecules/SnackBar';
import {useParams, useSearchParams} from 'react-router-dom';
import {isNullish} from '@front-libs/helpers';
import {isNull} from 'lodash';
import {createMakerInspectionSetting, updateMakerInspectionSetting} from '@modules/maker_inspection_settings/api';
import dayjs from 'dayjs';
import {getNextStatus} from './helper';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {SimpleDialog} from '@molecules/Dialogs/BaseDialog';
import {getInspectionResults} from '@modules/inspection_results/api';
import {useMutation} from 'react-query';
import {ProductInfoSections} from './TwoColumn/ProductInfoSections';
import {ProductAppendix} from './TwoColumn/ProductAppendix';
import {HospitalProductIndex, HospitalProductDetail} from '@modules/hospital_products/types';
import {ProductThumbnail} from '@components/atoms/Thumbnail';
import {NavigationContainer} from './TwoColumn/NavigationContainer';
import {ProductDetailHeader} from '@components/organisms/ProductDetailHeader';
import {ProductContentContainer, ProductContentColumnLeft, ProductContentColumnRight} from '@Apps/ProductDetail/styled';
import {Grid} from '@material-ui/core';
import {useFetchMakerInspectionSettingsQuery} from '@modules/maker_inspection_settings/hooks';
import {use3ColumnsLayoutTemplate} from '@components/templates/ContentLayout/InnerSidebarContentLayout';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {EIGHT_DIGIT_NUMBER_ERROR_MESSAGE} from '../ProductImport/hooks';
import {useNavigateProductRegistrationWithCopy} from './hooks';

export type ProductDetailFormType = Partial<Omit<HospitalProductIndex, 'maker' | 'isBaseUnit'>> & {
  // formコンポーネントではnullが空文字に変換されるため
  isBaseUnit: boolean | '';
  maker: {
    hashId?: string;
    name?: string;
    isNew: boolean;
  };
  makerInspectionSetting: {
    hashId: string | null;
    dueDateOfMakerInspection: string | null;
    inspectionPeriod: number | null;
    nextInspectionDate: string | null;
  };
};

type ProductDetailFormProps = {
  onCopyProduct: VoidFunction;
};

const ProductDetailForm = ({onCopyProduct}: ProductDetailFormProps) => {
  const hospitalContext = useFormikContext<HospitalProductDetail>();
  const {isReadOnly} = useMyRole();

  if (!hospitalContext) {
    return <InnerLoading />;
  }

  return (
    <Form style={{width: 'inherit', height: 'inherit', overflow: 'hidden'}}>
      <ProductThumbnail hospitalProduct={hospitalContext.initialValues} />
      <NavigationContainer />
      <ProductDetailHeader hospitalProduct={hospitalContext.initialValues} onCopyProduct={onCopyProduct} />
      <ProductContentContainer>
        <ProductContentColumnLeft>
          <ProductInfoSections hospitalProduct={hospitalContext.initialValues} />
        </ProductContentColumnLeft>
        <ProductContentColumnRight>
          <ProductAppendix hospitalProduct={hospitalContext.initialValues} />
        </ProductContentColumnRight>
      </ProductContentContainer>
      {!isReadOnly && <FormikFormSubmitDrawer />}
    </Form>
  );
};

type ProductDetailContainerProps = {
  // 子コンポーネントにコピー処理のhandlerだけ渡す
  children: (handleCopyProduct: VoidFunction) => React.ReactNode;
};

const ProductDetailContainer = ({children}: ProductDetailContainerProps) => {
  const {hashId} = useParams();
  const {myInfo} = useMyInfo();
  const [, setSearchParam] = useSearchParams();
  const {
    data,
    isLoading: isLoadingHospitalProduct,
    refetch: refetchHospitalProduct,
  } = useFetchHospitalProductQuery(myInfo.hospitalHashId, hashId ?? '');

  const {navigateWithCopy} = useNavigateProductRegistrationWithCopy();

  const handleCopyProduct = useCallback(() => {
    if (isNullish(data)) return;
    navigateWithCopy(data);
  }, [data, navigateWithCopy]);

  const {
    data: makerInspectionSetting,
    isLoading: isLoadingMakerInspectionSetting,
    refetch: refetchMakerInspectionSetting,
  } = useFetchMakerInspectionSettingsQuery(myInfo.hospitalHashId, hashId ?? '');

  const isBaseUnit = isNullish(data) || isNullish(data.isBaseUnit) ? '' : data.isBaseUnit;

  const [initialValues, setInitialValues] = useState<ProductDetailFormType>({
    ...data,
    isBaseUnit: isBaseUnit,
    maker: {
      hashId: data?.maker?.hashId,
      name: data?.maker?.name,
      isNew: false,
    },
    makerInspectionSetting: {
      hashId: makerInspectionSetting?.data.at(0)?.hashId ?? null,
      inspectionPeriod:
        makerInspectionSetting?.data.at(0)?.makerInspectionWholeProductSetting?.inspectionPeriod ?? null,
      dueDateOfMakerInspection: makerInspectionSetting?.data.at(0)?.dueDateOfMakerInspection?.toString() ?? null,
      nextInspectionDate: makerInspectionSetting?.data.at(0)?.nextInspectionDate?.toString() ?? null,
    },
  });

  const validationSchema = yup.object({
    managementId: yup.string().required(),
    depreciationAmount: yup.number().min(0),
    jmdnCode: yup
      .string()
      .nullable()
      .notRequired()
      .matches(/^(?:[1-9][0-9]{7})$/, EIGHT_DIGIT_NUMBER_ERROR_MESSAGE),
    janCode: yup
      .string()
      .nullable()
      .notRequired()
      .matches(/^[a-zA-Z0-9]+$/, 'JANコードには、半角英数字を入力してください。'),
    approvalNumber: yup
      .string()
      .nullable()
      .notRequired()
      .matches(/^[A-Z0-9]+$/, '承認番号には、半角英大文字・数字 を入力してください。'),
  });

  const {mutate: updateMutate} = useMutation<unknown, unknown, UpdateHospitalProductParams>(
    (variables) => updateHospitalProduct(myInfo.hospitalHashId, hashId ?? '', variables),
    {
      onSuccess: async () => {
        openSnackBar('機器情報を更新しました');
        await Promise.all([refetchHospitalProduct(), refetchMakerInspectionSetting()]);
      },
      onError: (error) => {
        if (request.isAxiosError(error) && error.response) {
          const errorMessage = error.response.data.message;
          if (errorMessage.includes('conflicting management ID')) {
            openSnackBar('管理番号の保存に失敗しました', 'left', 'bottom', 'error');
            throw error;
          }
        }
        openSnackBar('機器情報の更新に失敗しました', 'left', 'bottom', 'error');
      },
    }
  );

  useEffect(() => {
    setInitialValues({
      ...data,
      isBaseUnit: isBaseUnit,
      maker: {
        hashId: data?.maker?.hashId,
        name: data?.maker?.name,
        isNew: false,
      },
      makerInspectionSetting: {
        hashId: makerInspectionSetting?.data.at(0)?.hashId ?? null,
        inspectionPeriod:
          makerInspectionSetting?.data.at(0)?.makerInspectionWholeProductSetting?.inspectionPeriod ?? null,
        dueDateOfMakerInspection: makerInspectionSetting?.data.at(0)?.dueDateOfMakerInspection?.toString() ?? null,
        nextInspectionDate: makerInspectionSetting?.data.at(0)?.nextInspectionDate?.toString() ?? null,
      },
    });
  }, [data, makerInspectionSetting?.data]);

  useEffect(() => {
    if (!data) return;
    // TODO: 後ほど親機子機で出し分ける
    // TODO: ToolTipに表示する情報はAPIから取得するのと親機が設定されていない場合はツールチップを表示しない
    setSearchParam({isBaseUnit: `${data?.isBaseUnit}`, managementId: 'IP-001', displayName: '機種名', name: '型式'});
  }, [data]);

  const handleSubmit = useCallback(
    async (values: ProductDetailFormType) => {
      try {
        const updateValues = {
          ...values,
          catalogPrice: !isNullish(values.catalogPrice) ? values.catalogPrice.toString() : values.catalogPrice,
          deliveryPrice: !isNullish(values.deliveryPrice) ? values.deliveryPrice.toString() : values.deliveryPrice,
          taxRate: !isNullish(values.taxRate) ? values.taxRate.toString() : values.taxRate,
          legalDurableYear: !isNullish(values.legalDurableYear)
            ? values.legalDurableYear.toString()
            : values.legalDurableYear,
          leaseFee: !isNullish(values.leaseFee) ? values.leaseFee.toString() : values.leaseFee,
          rentalFee: !isNullish(values.rentalFee) ? values.rentalFee.toString() : values.rentalFee,
          dateOfPurchase:
            !isNullish(initialValues?.dateOfPurchase) && isNull(values.dateOfPurchase) ? '' : values.dateOfPurchase,
          dateOfDisposal:
            !isNullish(initialValues?.dateOfDisposal) && isNull(values.dateOfDisposal) ? '' : values.dateOfDisposal,
          periodicInspectionStartDate:
            !isNullish(initialValues?.periodicInspectionStartDate) && isNull(values.periodicInspectionStartDate)
              ? ''
              : values.periodicInspectionStartDate,
          rawBarcode: values.gs1Barcode ?? undefined,
          jmdnCode: values.jmdnCode ? Number(values.jmdnCode) : undefined,
          notes4: values.notes4 ?? '',
          notes5: values.notes5 ?? '',
          notes6: values.notes6 ?? '',
          notes7: values.notes7 ?? '',
          notes8: values.notes8 ?? '',
          notes9: values.notes9 ?? '',
          notes10: values.notes10 ?? '',
          notes11: values.notes11 ?? '',
          notes12: values.notes12 ?? '',
          notes13: values.notes13 ?? '',
          notes14: values.notes14 ?? '',
          notes15: values.notes15 ?? '',
          notes16: values.notes16 ?? '',
          notes17: values.notes17 ?? '',
          notes18: values.notes18 ?? '',
          notes19: values.notes19 ?? '',
          notes20: values.notes20 ?? '',
          isBaseUnit: values.isBaseUnit === '' ? null : values.isBaseUnit,
        };

        const {willDisposed, wontDisposed, isNextDisabled} = getNextStatus(initialValues, values);

        const initialData = await getInspectionResults(myInfo.hospitalHashId, 'dummy', {
          hospitalProductHashId: initialValues.hashId,
          statuses: 'unplanned',
        });

        if (isNextDisabled) {
          try {
            if (initialData.totalCount > 0) {
              await dialogHandler.open(SimpleDialog, {
                title: '機器を廃棄済みに変更しますか？',
                content: '未実施の点検予定が削除されます。\n削除したデータは復元できなくなります。',
                positiveButtonLabel: 'OK',
                negativeButtonLabel: 'キャンセル',
              });
            }
          } catch (_e) {
            return;
          }
        }
        if (willDisposed) {
          try {
            if (initialData.totalCount > 0) {
              await dialogHandler.open(SimpleDialog, {
                title: '機器を廃棄済みに変更しますか？',
                content: '未実施の点検予定が削除されます。\n削除したデータは復元できなくなります。',
                positiveButtonLabel: 'OK',
                negativeButtonLabel: 'キャンセル',
              });
              updateValues.status = 'disabled';
            } else {
              await dialogHandler.open(SimpleDialog, {
                title: 'ステータスを変更しますか？',
                content: '廃棄情報が入力されました。\n機器の稼働状況を「廃棄」に変更しますか？',
                positiveButtonLabel: '変更する',
                negativeButtonLabel: '変更せずに更新',
              });
              updateValues.status = 'disabled';
            }
          } catch (_e) {}
        } else if (wontDisposed) {
          try {
            await dialogHandler.open(SimpleDialog, {
              title: 'ステータスを変更しますか？',
              content: '廃棄情報が削除されました。\n機器の稼働状況を「待機中」に変更しますか？',
              positiveButtonLabel: '変更する',
              negativeButtonLabel: '変更せずに更新',
            });
            updateValues.status = 'ready';
          } catch (_e) {}
        }

        // 購入区分が購入の場合のみ, deliveryPrice,taxIncluded,taxRateは更新できる
        const isPurchase = (updateValues.waysOfPurchase ?? initialValues.waysOfPurchase) === 'purchase';

        if (isNullish(values.makerInspectionSetting?.hashId)) {
          createMakerInspectionSetting(myInfo.hospitalHashId, {
            hospitalProductHashId: hashId ?? '',
            dueDateOfMakerInspection: !isNullish(values.makerInspectionSetting?.dueDateOfMakerInspection)
              ? dayjs(values.makerInspectionSetting.dueDateOfMakerInspection).toDate()
              : undefined,
            nextInspectionDate: !isNullish(values.makerInspectionSetting?.nextInspectionDate)
              ? dayjs(values.makerInspectionSetting.nextInspectionDate).toDate()
              : undefined,
            inspectionPeriod: values.makerInspectionSetting?.inspectionPeriod ?? 1,
            inspectionPeriodUnit: 'year',
          });
        } else {
          updateMakerInspectionSetting(myInfo.hospitalHashId, values.makerInspectionSetting.hashId, {
            dueDateOfMakerInspection: !isNullish(values.makerInspectionSetting?.dueDateOfMakerInspection)
              ? dayjs(values.makerInspectionSetting.dueDateOfMakerInspection).toDate()
              : undefined,
            nextInspectionDate: !isNullish(values.makerInspectionSetting?.nextInspectionDate)
              ? dayjs(values.makerInspectionSetting.nextInspectionDate).toDate()
              : undefined,
          });
        }
        updateMutate({
          ...updateValues,
          maker: undefined,
          maker_hash_id: !updateValues.maker.isNew ? updateValues.maker.hashId : undefined,
          new_maker_name: updateValues.maker.isNew ? updateValues.maker.name : undefined,
          catalogPrice: updateValues.catalogPrice ?? undefined,
          deliveryPrice: isPurchase ? updateValues.deliveryPrice : undefined,
          taxIncluded: isPurchase ? updateValues.taxIncluded : undefined,
          taxRate: isPurchase ? updateValues.taxRate : undefined,
        });
      } catch (error) {
        openSnackBar('機器情報の更新に失敗しました', 'left', 'bottom', 'error');
        throw error;
      }
    },
    [initialValues, updateMutate, myInfo.hospitalHashId, hashId]
  );

  useEffect(() => {
    setInitialValues({
      ...data,
      isBaseUnit: isBaseUnit,
      maker: {
        hashId: data?.maker?.hashId,
        name: data?.maker?.name,
        isNew: false,
      },
      makerInspectionSetting: {
        hashId: makerInspectionSetting?.data.at(0)?.hashId ?? null,
        inspectionPeriod:
          makerInspectionSetting?.data.at(0)?.makerInspectionWholeProductSetting?.inspectionPeriod ?? null,
        dueDateOfMakerInspection: makerInspectionSetting?.data.at(0)?.dueDateOfMakerInspection?.toString() ?? null,
        nextInspectionDate: makerInspectionSetting?.data.at(0)?.nextInspectionDate?.toString() ?? null,
      },
    });
  }, [data, makerInspectionSetting?.data]);

  if (isLoadingHospitalProduct || isLoadingMakerInspectionSetting || !initialValues) {
    return <InnerLoading />;
  }

  return (
    <Formik<ProductDetailFormType>
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}>
      {children(handleCopyProduct)}
    </Formik>
  );
};

export const ProductDetail = () => {
  const templateClasses = use3ColumnsLayoutTemplate();

  return (
    <Grid container className={templateClasses.root}>
      <Suspense fallback={null}>
        <ProductDetailContainer>
          {(handleCopyProduct) => <ProductDetailForm onCopyProduct={handleCopyProduct} />}
        </ProductDetailContainer>
      </Suspense>
    </Grid>
  );
};
