import React, {useEffect, useState} from 'react';
import {
  ClickAwayListener,
  Button,
  ButtonProps,
  Paper,
  Popper,
  PopperPlacementType,
  MenuList,
  MenuItem,
  Tooltip,
  TooltipProps,
  Grow,
  Box,
  styled,
} from '@mui/material';
import {ArrowDropDown} from '@mui/icons-material';

export type MenuItemType<T = unknown> = {
  label: React.ReactNode | string;
  value: T;
  disableMenuItem?: boolean;
  selectedMenuItem?: boolean;
  tooltipOption?: Omit<TooltipProps, 'children'> & {enableTooltip: boolean};
};

export type Props<T = unknown> = {
  children?: React.ReactNode;
  menuItemList: MenuItemType<T>[];
  hiddenArrow?: boolean;
  MenuItemComponent?: React.ElementType;
  buttonProps?: ButtonProps;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  itemsDirection?: 'row' | 'column';
  itemProps?: React.ComponentProps<typeof MenuItem>;
  placement?: PopperPlacementType;
  dataTestId?: string;
  onMenuClick?: (item: MenuItemType<T>, e: React.MouseEvent<Document, MouseEvent>) => void;
  onOpen?: () => void;
  onClose?: () => void;
};

type PopperMenuProps<T> = {
  menuItemList: MenuItemType<T>[];
  itemsDirection: 'row' | 'column';
  handleMenuClick: (item: MenuItemType<T>) => (e: React.MouseEvent<Document, MouseEvent>) => void;
  MenuItemComponent: React.ElementType;
  itemProps?: React.ComponentProps<typeof MenuItem>;
};

const TitleSpan = styled('span')({whiteSpace: 'pre-line'});

const PopperMenu = <T,>({
  menuItemList,
  itemsDirection,
  handleMenuClick,
  MenuItemComponent,
  itemProps,
}: PopperMenuProps<T>) => (
  <MenuList sx={{display: itemsDirection === 'column' ? 'block' : 'flex'}}>
    {menuItemList.map((item, index) => (
      <Tooltip
        key={`${item.value}${index}`}
        title={<TitleSpan>{item.tooltipOption?.title ?? ''}</TitleSpan>}
        arrow={item.tooltipOption?.arrow}
        disableHoverListener={!item.tooltipOption?.enableTooltip}
        classes={item.tooltipOption?.enableTooltip ? {} : {popper: 'hide'}}
        placement={'top'}>
        <MenuItemComponent
          data-testid={`popper-menu-button-${item.value}`}
          key={`MenuItemComponent${index}`}
          tabIndex={index}
          {...itemProps}
          sx={{
            ...(item.disableMenuItem && {color: 'lightGray', cursor: 'default'}),
            ...(item.selectedMenuItem && {backgroundColor: '#EBF1FB'}),
            '&:hover': {backgroundColor: 'lightgray'},
            ...(itemProps?.sx || {}),
          }}
          onClick={handleMenuClick(item)}>
          {item.label}
        </MenuItemComponent>
      </Tooltip>
    ))}
  </MenuList>
);

/**
 * PopperとButtonを組み合わせたコンポーネント
 * ジェネリックのTはMenuItemTypeのvalueの型
 */
export const PopperMenuButtonV5 = <T,>({
  children,
  menuItemList,
  onMenuClick = () => {},
  buttonProps = {color: 'primary', variant: 'outlined'},
  MenuItemComponent = MenuItem,
  itemsDirection = 'column',
  itemProps,
  containerProps,
  dataTestId,
  onClose,
  onOpen,
  hiddenArrow = false,
  placement: muiPlacement = 'bottom',
}: Props<T>): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const toggleMenu = () => setOpen((prevOpen) => !prevOpen);

  useEffect(() => {
    if (open) {
      onOpen && onOpen();
    } else {
      onClose && onClose();
    }
  }, [open, onOpen, onClose]);

  const handleClickButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    toggleMenu();
  };

  const handleClose = (): void => {
    if (!open) return;
    setOpen(false);
  };

  const handleMenuClick = (item: MenuItemType<T>) => (e: React.MouseEvent<Document, MouseEvent>) => {
    if (!item.disableMenuItem) {
      onMenuClick(item, e);
    }
    handleClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box {...containerProps} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Button {...buttonProps} onClick={handleClickButton} data-testid={`popper-menu-button-${dataTestId}`}>
          {children}
          {!hiddenArrow && <ArrowDropDown fontSize="small" />}
        </Button>
        <Popper
          open={open}
          transition
          placement={muiPlacement}
          anchorEl={anchorEl}
          sx={{zIndex: 100000, maxHeight: 360, overflow: 'auto'}}>
          {({TransitionProps, placement}) => (
            <Grow {...TransitionProps} style={{transformOrigin: placement}}>
              <Paper elevation={2} sx={{border: '1px solid lightgray'}}>
                <PopperMenu<T>
                  menuItemList={menuItemList}
                  itemsDirection={itemsDirection}
                  handleMenuClick={handleMenuClick}
                  MenuItemComponent={MenuItemComponent}
                  itemProps={itemProps}
                />
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};
