import React, {useCallback, useEffect, useState} from 'react';
import {useAtomValue} from 'jotai';
import {FaultReceptionsDetailAtom} from '../../FaultReception/states';
import {FaultReceptionHeader} from '../../../components/molecules/FaultReceptionHeader';
import {Footer} from '../../FaultReception/Footer';
import {Box, Paper, styled} from '@material-ui/core';
import {FaultReceptionDetailSection} from '@Apps/FaultReceptionDetail/Content/FaultReceptionDetailSection';
import {useNavigate} from 'react-router-dom';
import {ReceptButton} from './ReceptButton';
import {createFaultReception} from '@modules/fault_receptions/api/faultReceptionApi';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {openSnackBar} from '@components/molecules/SnackBar';

const DetailContainer = styled(Paper)({
  backgroundColor: 'white',
  width: 'calc(100% - 508px)',
  height: 'calc(100vh - 298px)',
  margin: '16px 32px',
  padding: '16px',
  borderRadius: '20px',
});

const StyledBox = styled(Box)({
  display: 'flex',
});
const StyledChileBox = styled(Box)({
  marginBottom: '32px',
  alignSelf: 'end',
});

/**
 * 不具合受付報告の確認画面
 */
export const FaultReceptionPreview = () => {
  const {myInfo} = useMyInfo();
  const faultReceptionsDetailAtom = useAtomValue(FaultReceptionsDetailAtom);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(Boolean(!faultReceptionsDetailAtom.faultDetail));
  }, [faultReceptionsDetailAtom]);

  const newFaultReception = useCallback(
    async (hospitalHashId: string): Promise<string | null> => {
      try {
        const res = await createFaultReception(hospitalHashId, {
          ...faultReceptionsDetailAtom,
        });
        return res.data.hashId;
      } catch (e) {
        console.error(e);
        openSnackBar('不具合受付の登録に失敗しました。', 'left', 'bottom', 'error');
      }

      return null;
    },
    [faultReceptionsDetailAtom]
  );

  const handleClickNewFaultReception = useCallback(async () => {
    const hashId = await newFaultReception(myInfo.hospitalHashId);
    if (hashId !== null) {
      navigate(`/shared/faut_reception/register/complete/${hashId}`);
    }
  }, [myInfo.hospitalHashId, navigate, newFaultReception]);

  return (
    <Box>
      <FaultReceptionHeader title="不具合受付" />
      <StyledBox>
        <DetailContainer elevation={0}>
          <FaultReceptionDetailSection param={faultReceptionsDetailAtom} />
        </DetailContainer>
        <StyledChileBox>
          <ReceptButton onSubmit={handleClickNewFaultReception} label={'不具合受付をする'} disabled={disabled} />
        </StyledChileBox>
      </StyledBox>
      <Footer
        text={'入力内容を確認し、\n「不具合受付をする」ボタンを押してください'}
        backButtonHandler={() => {
          navigate('/shared/faut_reception/register/input');
        }}
      />
    </Box>
  );
};
