import {Typography, TypographyProps, styled} from '@mui/material';
import React from 'react';

const Label = styled(Typography)<{
  required: boolean;
  padding?: string;
  fontWeight?: string;
  fontSize?: string;
  height?: string;
  width?: string;
}>(({theme, required, padding, fontWeight, fontSize, height, width}) => ({
  borderRadius: '4px',
  background: required ? '#0052CC' : theme.palette.grey[500],
  color: '#FFF',
  fontWeight: fontWeight || 'bold',
  fontSize: fontSize || '14px',
  padding: padding || '4px 9px',
  height: height || '24px',
  width: width || '32px',
}));

type FormLabelBadgeProps = TypographyProps & {
  required?: boolean;
  padding?: string;
  fontWeight?: string;
  fontSize?: string;
  height?: string;
  width?: string;
};

export const FormLabelBadge = (props: FormLabelBadgeProps) => {
  const {required, ...rest} = props;
  return (
    <Label {...rest} required={Boolean(required)} variant="caption">
      {required ? '必須' : '任意'}
    </Label>
  );
};
