import {axios} from '@front-libs/core';
import {HospitalProductDetail, HospitalProductIndex} from '@modules/hospital_products/types';
import {
  BulkUpdateHospitalProductsData,
  CreateHospitalProductParams,
  FetchHospitalProductsParams,
  FetchHospitalProductsResult,
  UpdateHospitalProductParams,
} from '@modules/hospital_products/api';
import {convertDateToSimpleDate} from '@front-libs/helpers';
import {BulkUpdateProductListParam} from '@Apps/ProductBulkUpdates/types';

export const getHospitalProduct = async (hospitalHashId: string, hospitalProductHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}`;
  const {data} = await axios.get<HospitalProductDetail>(baseUrl);
  return data;
};

export const getHospitalProducts = async (hospitalHashId: string, params: FetchHospitalProductsParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/products`;
  const {data} = await axios.get<FetchHospitalProductsResult>(baseUrl, {params});
  return data;
};

export const createHospitalProduct = async (hospitalHashId: string, data: CreateHospitalProductParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/products`;
  const response = await axios.post<HospitalProductDetail>(baseUrl, data);
  return response;
};

// TODO: 初期値がnullで送られてくるカラムもあるため、一度整理してから dataの型のanyを修正する
export const bulkCreateHospitalProducts = async (hospitalHashId: string, data: {hospital_products: unknown[]}) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/products/jobs`;
  const response = await axios.post<HospitalProductDetail>(baseUrl, data);
  return response;
};

export const bulkUpdateHospitalProducts = async (
  hospitalHashId: string,
  hospitalProductHashIds: string[],
  data: BulkUpdateHospitalProductsData
) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/products/jobs`;
  const response = await axios.put(baseUrl, {
    hospitalProductHashIds,
    data: {
      ...data,
      periodicInspectionStartDate: data.periodicInspectionStartDate
        ? convertDateToSimpleDate(data.periodicInspectionStartDate)
        : undefined,
    },
  });
  return response;
};

export const updateHospitalProduct = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  data: UpdateHospitalProductParams
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}`;
  return axios.put<HospitalProductDetail>(baseUrl, data);
};

export const deleteHospitalProduct = async (hospitalHashId: string, hospitalProductHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}`;
  return axios.delete(baseUrl);
};

/**
 * 機種の再紐付けに使用するPatch
 * @param hospitalHashId
 * @param hospitalProductHashId
 * @returns
 */
export const patchHospitalProduct = async (
  hospitalHashId: string,
  hospitalProductHashId: string,
  wholeProductHashId: string
) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/${hospitalProductHashId}`;
  return axios.patch(baseUrl, {wholeProductHashId: wholeProductHashId});
};

export const requestImportTask = async (
  hospitalHashId: string,
  data: {
    fileHashId: string;
    hospitalProducts: Partial<Omit<HospitalProductIndex, 'hospitalRoom' | 'rentHospitalRoom'>>[];
    rawHospitalProducts: unknown[];
  }
) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/products/tasks/import`;
  return axios.post(baseUrl, data);
};

export const requestExportTask = async (hospitalHashId: string) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/products/tasks/export`;
  return axios.post(baseUrl);
};

// 機器の一括更新用のテンプレートをダウンロードする
export const exportTemplateHospitalProducts = async (hospitalHashId: string) => {
  const baseUrl = `${
    import.meta.env.VITE_SERVER_URL
  }/api/v1/hospitals/${hospitalHashId}/products/tasks/export_template`;
  const response = await axios.post(baseUrl);
  return response;
};

// 機器の一括更新用する
export const requestBulkUpdateTask = async (
  hospitalHashId: string,
  hospitalProducts: BulkUpdateProductListParam[],
  fileHashId: string
) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/products/tasks/bulk_update`;
  const response = await axios.put(baseUrl, {
    fileHashId,
    hospitalProducts,
  });
  return response;
};
