import React from 'react';
import {Box, Button, styled} from '@material-ui/core';
import {theme} from '@components/atoms/theme';
import {ChevronLeft} from '@material-ui/icons';
import {useNavigate} from 'react-router-dom';

/**
 * 貸出可能機器の閲覧画面のFooter
 */
export const Footer = () => {
  const navigate = useNavigate();

  return (
    <StyledGrid>
      <StyledBackButton variant={'outlined'} onClick={() => navigate('/shared/reception_menu')}>
        <StyledChevronLeft />
        <StyledButtonText>戻る</StyledButtonText>
      </StyledBackButton>
    </StyledGrid>
  );
};

const StyledGrid = styled(Box)({
  position: `fixed`,
  bottom: '44px',
  left: 0,
});

const StyledBackButton = styled(Button)({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.rent.main,
  fontSize: '18px',
  fontWeight: 'bold',
  height: '60px',
  padding: '5px 0px 5px 8px',
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
  width: '124px',
  left: '0px',
  borderRadius: '0px 45px 45px 0px',
  boxShadow: '0px 4px 0px 0px rgba(209, 216, 245, 1)',
  '& .MuiButton-label': {
    justifyContent: 'left',
  },
});

const StyledButtonText = styled(Box)({
  paddingTop: '4px',
  paddingRight: '24px',
  fontSize: '24px',
});

const StyledChevronLeft = styled(ChevronLeft)({
  paddingTop: '4px',
  paddingRight: '8px',
  fontSize: '24px',
});
