import React, {useState} from 'react';
import {Box, Button, Paper, SxProps, Typography} from '@mui/material';
import {WholeProductForm} from './form/WholeProductForm';
import {ChevronLeft} from '@mui/icons-material';
import {useNavigate, useParams} from 'react-router-dom';
import {useFetchWholeProductQuery} from '@modules/products/api';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {InnerLoading} from '@molecules/Loading';
import {ProductHeader} from './ui/ProductHeader';

export const PRODUCT_PC_APPENDIX_WIDTH = '600px';
export const PRODUCT_TABLET_APPENDIX_WIDTH = '490px';

const BackProductsButton = () => {
  const navigate = useNavigate();
  const handleClickProductsLink = () => {
    navigate('/products/whole_products');
  };
  return (
    <Button sx={{fontSize: '14px', padding: 0, color: '#172B4D'}} onClick={handleClickProductsLink}>
      <ChevronLeft />
      院内機種一覧
    </Button>
  );
};

const rootStyle: SxProps = {
  inset: '56px 0 0 0',
  position: 'absolute',
  background: 'aquamarine', // TEST
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
};
export const WholeProductDetail = () => {
  return (
    <Box sx={rootStyle}>
      <Box sx={{width: '100%'}}>
        <BackProductsButton />
      </Box>
      <WholeProductContent />
    </Box>
  );
};

export const WholeProductContent = () => {
  const {myInfo} = useMyInfo();
  const {hashId: wholeProductHashId} = useParams();
  const {data} = useFetchWholeProductQuery(myInfo.hospitalHashId, wholeProductHashId ?? '');
  const [formData, setFormData] = useState({}); // フォームデータを保持するステート

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleFormChange = (updatedData: any) => {
    console.log('フォームが更新されました:', updatedData);
    setFormData(updatedData); // 変更されたデータをステートに保存
  };

  if (!data) {
    return <InnerLoading />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px !important',
      }}>
      <ProductHeader />

      <Paper sx={{flex: 1, display: 'flex', border: '2px solid red'}}>
        <Box
          sx={{
            flexGrow: 1,
            borderRight: '2px solid red',
          }}>
          <div style={{marginTop: '60px'}} />
          <WholeProductForm wholeProduct={data} onFormChange={handleFormChange} />
        </Box>
        <Box
          sx={{
            width: '600px',
            '@media (max-width: 1024px)': {
              width: '490px',
              background: 'red',
            },
          }}>
          <Typography variant="h6">プロダクト </Typography>
        </Box>
      </Paper>

      {Object.keys(formData).length > 0 && (
        <Box sx={{mt: 2}}>
          <Typography variant="h6">現在のフォームデータ:</Typography>
          <pre>{JSON.stringify(formData, null, 2)}</pre>
        </Box>
      )}
    </Box>
  );
};
