import React, {useEffect, useState} from 'react';
import {useForm, Controller, useWatch} from 'react-hook-form';
import {TextField, Box, Checkbox, FormControlLabel, Paper, SxProps} from '@mui/material';
import {ProductIndex} from '@modules/products/types';
import {WholeProductFormValues} from './type';
import {formFields} from './const';
import {isEqual} from 'lodash'; // lodashのisEqualを使って比較

type WholeProductFormProps = {
  wholeProduct: ProductIndex;
  onFormChange: (data: Partial<WholeProductFormValues>) => void;
};

export const WholeProductForm = ({wholeProduct, onFormChange}: WholeProductFormProps) => {
  const {control} = useForm<WholeProductFormValues>({
    defaultValues: {
      rootCategory: wholeProduct.categories?.[0]?.name || '', // 空文字をデフォルトに
      narrowCategory: wholeProduct.categories?.[1]?.name || '',
      maker: wholeProduct.maker?.name || '',
      displayName: wholeProduct.displayName || '',
      name: wholeProduct.name || '',
      approvalNumber: wholeProduct.approvalNumber || '',
      jmdnCode: wholeProduct.jmdnCode ?? 0, // undefinedの代わりに0
      janCode: wholeProduct.janCode || '',
      newJanCode: wholeProduct.newJanCode || '',
      isSpecificMaintain: wholeProduct.isSpecificMaintain ?? false, // undefinedの代わりにfalse
      className: wholeProduct.className || '',
      catalogPrice: wholeProduct.catalogPrice ?? 0, // undefinedの代わりに0
    },
  });

  // 初期値の保存
  const [initialValues, setInitialValues] = useState<WholeProductFormValues>({
    rootCategory: wholeProduct.categories?.[0]?.name || '',
    narrowCategory: wholeProduct.categories?.[1]?.name || '',
    maker: wholeProduct.maker?.name || '',
    displayName: wholeProduct.displayName || '',
    name: wholeProduct.name || '',
    approvalNumber: wholeProduct.approvalNumber || '',
    jmdnCode: wholeProduct.jmdnCode ?? 0,
    janCode: wholeProduct.janCode || '',
    newJanCode: wholeProduct.newJanCode || '',
    isSpecificMaintain: wholeProduct.isSpecificMaintain ?? false,
    className: wholeProduct.className || '',
    catalogPrice: wholeProduct.catalogPrice ?? 0,
  });

  const watchedFields = useWatch({control}); // フォームの変更を監視

  // 初期値と現在の値を比較し、差分のみを抽出
  useEffect(() => {
    const changedKeys = Object.keys(watchedFields).reduce<string[]>(
      (acc, key) => {
        const fieldKey = key as keyof WholeProductFormValues;
        const watchedValue = watchedFields[fieldKey];

        // `undefined` ならデフォルト値を使う
        const currentValue = watchedValue !== undefined ? watchedValue : initialValues[fieldKey];
        console.log('currentValue', currentValue);

        // 初期値と異なる場合、変更されたフィールドのキーを追加
        if (!isEqual(currentValue, initialValues[fieldKey])) {
          acc.push(key); // 変更があったフィールドのキーを配列に追加
        }

        return acc;
      },
      [] // 空の配列を初期値として使用
    );

    // 変更があればログに出力するか、他の処理を行う
    if (changedKeys.length > 0) {
      console.log('変更があったフィールド:', changedKeys);
      // onFormChangeなど他の処理もここで実行可能
      console.log('watchedFields', watchedFields);
      // onFormChange(changedKeys.reduce((acc, key) => ({...acc, [key]: watchedFields[key]}), {}));
      onFormChange(
        changedKeys.reduce(
          (acc, key) => ({
            ...acc,
            [key]: watchedFields[key as keyof WholeProductFormValues],
          }),
          {}
        )
      );
    }
  }, [watchedFields, initialValues]);

  const fieldGroups = [];
  for (let i = 0; i < formFields.length; i += 4) {
    fieldGroups.push(formFields.slice(i, i + 4));
  }

  const gridStyle: SxProps = {
    display: 'grid',
    flexWrap: 'wrap',
    gap: '16px',
    marginBottom: '16px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(212px, 1fr))',
    // 一つのFormが200px が4つ並んだとき 右ペイン(タイムライン等)の幅660pxを足した数値1460pxを指定
    '@media (min-width: 1460px)': {
      gridTemplateColumns: 'repeat(4, 1fr)', // 最大4列に制限
    },
  };
  return (
    <Paper sx={{width: '100%', borderRadius: '16px'}}>
      <form>
        <Box sx={gridStyle}>
          {formFields.map((field, index) => (
            <Box key={index}>
              <Controller
                name={field.name as keyof WholeProductFormValues}
                control={control}
                render={({field: controllerField}) =>
                  field.type === 'textarea' ? (
                    <TextField {...controllerField} label={field.label} multiline rows={4} fullWidth />
                  ) : field.type === 'boolean' ? (
                    <FormControlLabel
                      control={<Checkbox {...controllerField} checked={!!controllerField.value} />}
                      label={field.label}
                    />
                  ) : (
                    <TextField {...controllerField} label={field.label} fullWidth />
                  )
                }
              />
            </Box>
          ))}
        </Box>
      </form>
    </Paper>
  );
};
