import React from 'react';
import {DescriptionOutlined} from '@mui/icons-material';
import {MenuItemSetting} from './types';

export const HospitalProductReadMessages = {
  failed: '機器情報の取得に失敗しました',
} as const;

export const MenuItemValues = ['hospitalProductDetail'] as const;

export const MenuItemSettings: MenuItemSetting[] = [
  {
    icon: <DescriptionOutlined />,
    label: '機器詳細',
    value: 'hospitalProductDetail',
  },
];

export const CodeReaderTypes = ['barcodeReader', 'camera'] as const;

export enum LocalStorageKeys {
  CodeReaderType = 'hitotsu/hospital_product_menu_code_reader_type',
}
