import {TableLayoutType} from './types';

export const tableLayoutFields: TableLayoutType = {
  productList: [
    {title: '管理番号', field: 'managementId', defaultShow: true},
    {title: 'シリアル番号', field: 'serialNumber', defaultShow: true},
    {title: 'ロット番号', field: 'lotNumber', defaultShow: true},
    {title: '大分類', field: 'rootCategory', defaultShow: true},
    {title: '小分類', field: 'narrowCategory', defaultShow: true},
    {title: 'メーカー名', field: 'maker', defaultShow: true},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: '型式', field: 'name', defaultShow: true},
    {title: '定価', field: 'catalogPrice', defaultShow: true},
    {title: '貸出区分', field: 'permanentlyAssigned', defaultShow: true},
    {title: '稼働状況', field: 'status', defaultShow: true},
    {title: '機器管理場所', field: 'hospitalRoom', defaultShow: true},
    {title: '貸出場所', field: 'rentHospitalRoom', defaultShow: true},
    {title: '親機・子機', field: 'isBaseUnit', defaultShow: true},
    {title: '購入日', field: 'dateOfPurchase', defaultShow: true},
    {title: '点検開始日', field: 'periodicInspectionStartDate', defaultShow: true},
    {title: '購入区分', field: 'waysOfPurchase', defaultShow: true},
    {title: '納入価', field: 'deliveryPrice', defaultShow: true},
    {title: '税込/税抜き', field: 'taxIncluded', defaultShow: true},
    {title: '税率', field: 'taxRate', defaultShow: true},
    {title: '院内耐用年数', field: 'legalDurableYear', defaultShow: true},
    {title: '資産番号', field: 'assetRegisterNumber', defaultShow: true},
    {title: '担当代理店', field: 'hospitalDealer', defaultShow: false},
    {title: '管理部署', field: 'hospitalProductMgmtSection', defaultShow: false},
    {title: '保守契約', field: 'isMaintenanceContract', defaultShow: true},
    {title: 'レンタル番号', field: 'rentalId', defaultShow: false},
    {title: 'レンタル企業名', field: 'rentalDealerName', defaultShow: false},
    {title: 'レンタル金額', field: 'rentalFee', defaultShow: false},
    {title: 'レンタル開始日', field: 'rentalStartDate', defaultShow: false},
    {title: 'レンタル終了予定日', field: 'rentalDueDate', defaultShow: false},
    {title: 'レンタル機器返却日', field: 'rentalReturnDate', defaultShow: false},
    {title: 'リース番号', field: 'leaseId', defaultShow: false},
    {title: 'リース企業名', field: 'leaseDealerName', defaultShow: false},
    {title: 'リース金額', field: 'leaseFee', defaultShow: false},
    {title: 'リース開始日', field: 'leaseStartDate', defaultShow: false},
    {title: 'リース終了予定日', field: 'leaseDueDate', defaultShow: false},
    {title: 'リース機器返却日', field: 'leaseReturnDate', defaultShow: false},
    // {title: '減価償却費', field: 'depreciationAmount', defaultShow: true},
    // {title: '帳簿価格', field: 'bookValue', defaultShow: true},
    {title: '廃棄日', field: 'dateOfDisposal', defaultShow: true},
    {title: '廃棄理由', field: 'reasonOfDisposal', defaultShow: true},
    {title: 'バーコード読み取り値', field: 'optionalBarcode', defaultShow: false},
    {title: 'GS1バーコード', field: 'gs1Barcode', defaultShow: false},
    // NOTE:備考1~20はHospitalProductNoteSettingsのAPIのリターン値に合わせてTitleが変更され、Visibleが適用される
    {title: '備考1', field: 'notes', defaultShow: false},
    ...Array.from({length: 19}, (_, index) => ({
      title: `備考${index + 2}`,
      field: `notes${index + 2}`,
      defaultShow: false,
    })),
    {title: '登録者', field: 'createdBy', defaultShow: true},
    {title: '登録日', field: 'createdAt', defaultShow: true},
  ].filter((field) => field.field !== 'periodicInspectionStartDate'),

  inspectionList: [
    {title: '名前', field: 'name', defaultShow: true},
    {title: '最終編集', field: 'updatedBy', defaultShow: true},
    {title: '公開', field: 'publishedAt', defaultShow: true},
    {title: '割当製品', field: 'inspectionProducts', defaultShow: true},
  ],
  inspectionTableList: [
    {title: 'ステータス', field: 'status', defaultShow: true},
    {title: '点検表名', field: 'name', defaultShow: true},
    {title: '点検タイプ', field: 'type', defaultShow: true},
    {title: '最終編集時間', field: 'updatedAt', defaultShow: true},
    {title: '公開日時', field: 'publishedAt', defaultShow: true},
    {title: '登録済みの点検マニュアル', field: 'manualFileName', defaultShow: true},
  ],
  WholeProductPlansList: [
    {title: '機種名', field: 'wholeProductDisplayName', defaultShow: true},
    {title: '型式', field: 'wholeProductName', defaultShow: true},
    {title: 'メーカー名', field: 'wholeProductMakerName', defaultShow: true},
    {title: '点検名', field: 'inspectionSettingName', defaultShow: true},
    {title: '使用する点検表', field: 'inspectionName', defaultShow: true},
    {title: '点検タイプ', field: 'inspectionType', defaultShow: true},
    {title: '点検間隔', field: 'inspectionPeriod', defaultShow: true},
    {title: '大分類', field: 'wholeProductRootCategoryName', defaultShow: true},
    {title: '小分類', field: 'wholeProductNarrowCategoryName', defaultShow: true},
  ],
  hospitalProductPlanList: [
    {title: 'ステータス', field: 'status', defaultShow: true},
    {title: '点検名', field: 'planName', defaultShow: true},
    {title: '管理番号', field: 'managementId', defaultShow: true},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: '型式', field: 'name', defaultShow: true},
    {title: 'メーカー名', field: 'makerName', defaultShow: true},
    {title: '点検間隔', field: 'inspectionPeriod', defaultShow: true},
    {title: '点検開始月', field: 'startMonth', defaultShow: true},
    {title: '月毎の点検日', field: 'startDate', defaultShow: true},
    {title: '使用する点検表', field: 'inspectionName', defaultShow: true},
    {title: '機器管理場所', field: 'hospitalRoom', defaultShow: true},
    {title: '購入日', field: 'dateOfPurchase', defaultShow: true},
  ],

  inspectionProductStartDateList: [
    {title: '管理番号', field: 'managementId', defaultShow: true},
    {title: '大分類', field: 'rootCategory', defaultShow: true},
    {title: '小分類', field: 'narrowCategory', defaultShow: true},
    {title: '型式', field: 'name', defaultShow: true},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: 'メーカー名', field: 'makerName', defaultShow: true},
    {title: '点検開始日', field: 'periodicInspectionStartDate', defaultShow: true},
    {title: '点検表', field: 'inspectionName', defaultShow: true},
  ],
  rentalList: [
    {title: '管理番号', field: 'managementId', defaultShow: true},
    {title: '大分類', field: 'rootCategory', defaultShow: true},
    {title: '小分類', field: 'narrowCategory', defaultShow: true},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: '型式', field: 'name', defaultShow: true},
    {title: '貸出先', field: 'rentalRoom', defaultShow: true},
    {title: '貸出日時', field: 'checkoutAt', defaultShow: true},
    {title: '返却日時', field: 'returnAt', defaultShow: true},
    {title: '持出者', field: 'checkoutBy', defaultShow: true},
    {title: '返却者', field: 'returnBy', defaultShow: true},
  ],
  userList: [
    {title: '名前', field: 'firstName', defaultShow: true},
    {title: 'ユーザーID', field: 'username', defaultShow: true},
    {title: '権限', field: 'authority', defaultShow: true},
    {title: '最終ログイン日時', field: 'lastSignInAt', defaultShow: true},
  ],
  // NOTE: HIT-3551のincident対応で削除。indexedDBに一度保存された場合、復旧不可なため。
  // inspectionProductList: [
  //   {title: '型式', field: 'name', defaultShow: true},
  //   {title: '機種名', field: 'displayName', defaultShow: true},
  //   {title: 'メーカー名', field: 'makerName', defaultShow: true},
  //   {title: '点検間隔', field: 'inspectionPeriod', defaultShow: true},
  // ],
  inspectionHospitalProductsList: [
    {title: '管理番号', field: 'managementId', defaultShow: true},
    {title: '大分類', field: 'rootCategory', defaultShow: true},
    {title: '小分類', field: 'narrowCategory', defaultShow: true},
    {title: 'メーカー名', field: 'maker', defaultShow: true},
    {title: '型式', field: 'name', defaultShow: true},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: '点検開始日', field: 'startDate', defaultShow: true},
  ],
  inspectionResultFileList: [
    {title: 'ファイル名', field: 'fileName', defaultShow: true},
    {title: 'アップロード日', field: 'createdAt', defaultShow: true},
    {title: 'アップロードユーザー', field: 'createdBy', defaultShow: true},
  ],
  inspectionResultList: [
    {title: '管理番号', field: 'managementId', defaultShow: true},
    {title: '当日の点検完了回数', field: 'indexOfTheDay', defaultShow: true},
    {title: '大分類', field: 'rootCategory', defaultShow: true},
    {title: '小分類', field: 'narrowCategory', defaultShow: false},
    {title: '型式', field: 'name', defaultShow: false},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: 'メーカー名', field: 'makerName', defaultShow: false},
    {title: 'ステータス', field: 'status', defaultShow: true},
    {title: '点検結果', field: 'result', defaultShow: true},
    {title: '点検タイプ', field: 'inspectionType', defaultShow: true},
    {title: '点検名', field: 'inspectionSettingName', defaultShow: true},
    {title: '使用する点検表', field: 'inspectionName', defaultShow: true},
    {title: '点検間隔', field: 'inspectionPeriod', defaultShow: true},
    {title: '機器管理場所', field: 'hospitalRoomName', defaultShow: true},
    {title: '貸出場所', field: 'rentHospitalRoomName', defaultShow: true},
    {title: '中断日時', field: 'suspendedAt', defaultShow: true},
    {title: '臨時点検', field: 'isAdhocDate', defaultShow: true},
    {title: '点検予定日', field: 'scheduledTime', defaultShow: true},
    {title: '点検完了日時', field: 'completedAt', defaultShow: true},
    {title: '最終点検者', field: 'inspector', defaultShow: true},
    {title: 'スキップ理由', field: 'skipReason', defaultShow: true},
    {title: 'スキップ日', field: 'skippedTime', defaultShow: true},
  ],
  makerInspectionResultList: [
    {title: '管理番号', field: 'managementId', defaultShow: true},
    {title: '点検予定日', field: 'scheduledTime', defaultShow: true},
    {title: '大分類', field: 'rootCategory', defaultShow: true},
    {title: '小分類', field: 'narrowCategory', defaultShow: false},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: 'ステータス', field: 'status', defaultShow: true},
    {title: 'メーカー名', field: 'makerName', defaultShow: false},
    {title: '型式', field: 'name', defaultShow: false},
    {title: '点検完了日時', field: 'completedAt', defaultShow: true},
    {title: '登録者', field: 'inspector', defaultShow: true},
    {title: '保守契約料', field: 'inspectionFee', defaultShow: true},
    {title: 'スキップ理由', field: 'skipReason', defaultShow: true},
    {title: 'スキップ日', field: 'skippedTime', defaultShow: true},
  ],
  productListInInspectionPlanSetting: [
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: 'メーカー', field: 'companyName', defaultShow: true},
    {title: '利用する点検表', field: 'inspection', defaultShow: true},
    {title: '【定期】点検間隔', field: 'periodicInspectionPeriod', defaultShow: true},
    {title: '【メーカー】点検間隔', field: 'makerInspectionPeriod', defaultShow: true},
  ],
  inspectionPlanList: [
    {title: '点検表名', field: 'inspectionName', defaultShow: true},
    {title: '点検区分', field: 'type', defaultShow: true},
    {title: '点検間隔', field: 'period', defaultShow: true},
  ],
  hospitalFileList: [
    {title: 'ファイル名', field: 'fileName', defaultShow: true},
    {title: 'ファイルタイプ', field: 'category', defaultShow: true},
    {title: 'アップロード日', field: 'createdAt', defaultShow: true},
    {title: 'アップロードユーザー', field: 'createdBy', defaultShow: true},
  ],
  fileList: [
    {title: 'ファイル名', field: 'fileName', defaultShow: true},
    {title: 'アップロード日', field: 'createdAt', defaultShow: true},
    {title: 'アップロードユーザー', field: 'createdBy', defaultShow: true},
  ],
  repairList: [
    {title: '修理番号', field: 'repairRegistrationNumber', defaultShow: true},
    {title: '管理番号', field: 'managementId', defaultShow: true},
    {title: '大分類', field: 'rootCategory', defaultShow: true},
    {title: '小分類', field: 'narrowCategory', defaultShow: true},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: '事象区分', field: 'symptomCategory', defaultShow: true},
    {title: '事象詳細区分', field: 'symptomDetailCategory', defaultShow: true},
    {title: '事象の詳細', field: 'symptomDetail', defaultShow: false},
    {title: '原因区分', field: 'rootCauseCategory', defaultShow: true},
    {title: '原因の詳細', field: 'causeOfIssueDetail', defaultShow: false},
    {title: '修理ステータス', field: 'statusId', defaultShow: true},
    {title: '依頼者の所属部署', field: 'hospitalDepartmentName', defaultShow: true},
    {title: '依頼者', field: 'requestUserName', defaultShow: true},
    {title: '依頼事項', field: 'requests', defaultShow: false},
    {title: 'カード作成者', field: 'createdBy', defaultShow: true},
    {title: '修理受付日時', field: 'requestForRepairAt', defaultShow: true},
    {title: '完了年月日', field: 'fixedAt', defaultShow: true},
    {title: '担当者', field: 'personInCharge', defaultShow: true},
    {title: '事務への申請内容', field: 'applicationToSuppliesDepartment', defaultShow: true},
    {title: '事務への申請年月日', field: 'appliedAtToSuppliesDepartment', defaultShow: true},
    {title: '代替の有無', field: 'necessityOfSubstitute', defaultShow: false},
    {title: '代替の内容', field: 'substitutionDetail', defaultShow: true},
    {title: '処理区分', field: 'dealtCategory', defaultShow: true},
    {title: '修理見積額', field: 'estimatedCostOfFix', defaultShow: true},
    {title: '修理請求額', field: 'costOfFix', defaultShow: true},
    {title: 'メーカー担当者名', field: 'makerPicName', defaultShow: true},
    {title: 'メーカー担当者 電話番号', field: 'makerContactTel', defaultShow: true},
    {title: 'メーカー担当者 メールアドレス', field: 'makerContactEmail', defaultShow: true},
    {title: 'メーカーへの連絡日', field: 'makerContactAt', defaultShow: true},
  ],
  repairKanban: [
    {title: '修理番号', field: 'repairRegistrationNumber', defaultShow: true},
    {title: '管理番号', field: 'managementId', defaultShow: true},
    {title: 'メーカー名', field: 'makerName', defaultShow: true},
    {title: '修理受付日時', field: 'requestForRepairAt', defaultShow: true},
    {title: '担当者', field: 'personInCharge', defaultShow: true},
    {title: '大分類', field: 'rootCategory', defaultShow: false},
    {title: '小分類', field: 'narrowCategory', defaultShow: false},
    {title: '依頼者の所属部署', field: 'hospitalDepartmentName', defaultShow: false},
    {title: '依頼者', field: 'requestUserName', defaultShow: false},
    {title: 'カード作成者', field: 'createdBy', defaultShow: false},
    {title: '完了年月日', field: 'fixedAt', defaultShow: false},
    {title: '事務への申請年月日', field: 'appliedAtToSuppliesDepartment', defaultShow: false},
    {title: '事象詳細区分', field: 'symptomDetailCategory', defaultShow: false},
    {title: '原因区分', field: 'rootCauseCategory', defaultShow: false},
    {title: '処理区分', field: 'dealtCategory', defaultShow: false},
    {title: '修理見積額', field: 'estimatedCostOfFix', defaultShow: false},
    {title: '修理請求額', field: 'costOfFix', defaultShow: false},
    {title: 'メーカー担当者名', field: 'makerPicName', defaultShow: false},
    {title: 'メーカー担当者 電話番号', field: 'makerContactTel', defaultShow: false},
    {title: 'メーカー担当者 メールアドレス', field: 'makerContactEmail', defaultShow: false},
    {title: 'メーカーへの連絡日', field: 'makerContactAt', defaultShow: false},
  ],
  documentList: [{title: '各種規約', field: 'name', defaultShow: true}],
  hospitalWardList: [
    {title: '名前', field: 'name', defaultShow: true},
    {title: '場所の数', field: 'numberOfRooms', defaultShow: true},
  ],
  hospitalRoomList: [
    {title: '名前', field: 'name', defaultShow: true},
    {title: '大エリア', field: 'hospitalWard', defaultShow: true},
    {title: '階', field: 'floorNumber', defaultShow: true},
    {title: '作成者', field: 'createdBy', defaultShow: true},
    {title: '貸出先に表示', field: 'showRentalPlace', defaultShow: true},
  ],

  // todo: reorder again
  newInspectionProductsList: [
    {title: '型式/機種名', field: 'productName', defaultShow: true},
    {title: 'メーカー', field: 'makerName', defaultShow: true},
  ],
  dashboardMonthlyList: [
    {title: '点検予定日', field: 'scheduledTime', defaultShow: true},
    {title: '点検タイプ', field: 'type', defaultShow: true},
    {title: 'ステータス', field: 'status', defaultShow: true},
    {title: '大分類', field: 'rootCategory', defaultShow: false},
    {title: '小分類', field: 'narrowCategory', defaultShow: true},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: '型式', field: 'name', defaultShow: false},
    {title: 'メーカー名', field: 'makerName', defaultShow: false},
    {title: '管理番号', field: 'managementId', defaultShow: true, disabledSelect: true},
    {title: '中断日時', field: 'suspendedAt', defaultShow: false},
    {title: '最終点検者', field: 'inspector', defaultShow: false},
    {title: '稼働状況', field: 'hospitalProductStatus', defaultShow: true},
  ],
  dashboardRentalHistory: [
    {title: 'ステータス', field: 'returnAt', defaultShow: true},
    {title: '小分類', field: 'narrowCategory', defaultShow: true},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: '管理番号', field: 'managementId', defaultShow: true},
    {title: '貸出先', field: 'rentalRoom', defaultShow: true},
    {title: '持出者', field: 'checkoutBy', defaultShow: true},
  ],
  dashboardProductCommentList: [
    {title: 'ピンどめコメント', field: 'description', defaultShow: true},
    {title: 'コメント日時', field: 'createdAt', defaultShow: true},
    {title: 'コメントユーザー', field: 'createdBy', defaultShow: true},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: '小分類', field: 'narrowCategory', defaultShow: true},
    {title: '管理番号', field: 'managementId', defaultShow: true},
  ],
  dashboardNonReturnedList: [
    {title: '貸出日', field: 'checkoutAt', defaultShow: true},
    {title: '貸出先', field: 'rentalRoom', defaultShow: true},
    {title: '持出者', field: 'checkoutBy', defaultShow: true},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: '小分類', field: 'narrowCategory', defaultShow: true},
    {title: '管理番号', field: 'managementId', defaultShow: true},
  ],
  dashboardRepairCommentList: [
    {title: 'ピンどめコメント', field: 'description', defaultShow: true},
    {title: 'コメント日時', field: 'createdAt', defaultShow: true},
    {title: 'コメントユーザー', field: 'createdBy', defaultShow: true},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: '修理ステータス', field: 'statusName', defaultShow: true},
    {title: '小分類', field: 'narrowCategory', defaultShow: true},
    {title: '管理番号', field: 'managementId', defaultShow: true},
    {title: '修理番号', field: 'repairRegistrationNumber', defaultShow: true},
  ],
  signupProductList: [
    // Memo: 当該は 運用開始(オンボーディング) の 機器台帳 新規Excel取込 で使用する テーブル列定義
    // Memo: 運用開始（オンボーディング）は機器マスタ本気整備の処理に置き換わる予定なので、廃止予定
    {title: '管理番号', field: 'managementId', defaultShow: true},
    {title: 'シリアル番号', field: 'serialNumber', defaultShow: true},
    {title: 'ロット番号', field: 'lotNumber', defaultShow: true},
    {title: '大分類', field: 'rootCategory', defaultShow: true},
    {title: '小分類', field: 'narrowCategory', defaultShow: true},
    {title: 'メーカー名', field: 'makerName', defaultShow: true},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: '型式', field: 'name', defaultShow: true},
    {title: '特定保守製品', field: 'isSpecificMaintain', defaultShow: true},
    {title: 'JMDNコード', field: 'jmdnCode', defaultShow: true},
    {title: 'JANコード', field: 'janCode', defaultShow: true},
    {title: '承認番号', field: 'approvalNumber', defaultShow: true},
    {title: '貸出区分', field: 'permanentlyAssigned', defaultShow: true},
    {title: '稼働状況', field: 'status', defaultShow: true},
    // {title: '機器管理場所', field: 'hospitalRoom', defaultShow: true},
    {title: '親機・子機', field: 'isBaseUnit', defaultShow: true},
    {title: '購入日', field: 'dateOfPurchase', defaultShow: true},
    {title: '購入区分', field: 'waysOfPurchase', defaultShow: true},
    {title: '購入元', field: 'purchasedNationalExpense', defaultShow: true},
    {title: '耐用年数', field: 'legalDurableYear', defaultShow: true},
    {title: '資産番号', field: 'assetRegisterNumber', defaultShow: false},
    {title: '担当代理店', field: 'hospitalDealer', defaultShow: false},
    {title: '管理部署', field: 'hospitalProductMgmtSection', defaultShow: false},
    {title: '保守契約', field: 'isMaintenanceContract', defaultShow: true},
    // {title: '減価償却費', field: 'depreciationAmount', defaultShow: true},
    // {title: '帳簿価格', field: 'bookValue', defaultShow: true},
    {title: '廃棄日', field: 'dateOfDisposal', defaultShow: true},
    {title: '廃棄理由', field: 'reasonOfDisposal', defaultShow: true},
    {title: 'レンタル番号', field: 'rentalId', defaultShow: true},
    {title: 'レンタル企業名', field: 'rentalDealerName', defaultShow: true},
    {title: 'レンタル金額', field: 'rentalFee', defaultShow: true},
    {title: 'レンタル開始日', field: 'rentalStartDate', defaultShow: true},
    {title: 'レンタル終了予定日', field: 'rentalDueDate', defaultShow: true},
    {title: 'レンタル機器返却日', field: 'rentalReturnDate', defaultShow: true},
    {title: 'リース番号', field: 'leaseId', defaultShow: true},
    {title: 'リース企業名', field: 'leaseDealerName', defaultShow: true},
    {title: 'リース金額', field: 'leaseFee', defaultShow: true},
    {title: 'リース開始日', field: 'leaseStartDate', defaultShow: true},
    {title: 'リース終了予定日', field: 'leaseDueDate', defaultShow: true},
    {title: 'リース機器返却日', field: 'leaseReturnDate', defaultShow: true},
    {title: 'デモの目的', field: 'purposeOfDemonstration', defaultShow: true},
    {title: 'デモ開始日', field: 'demonstrationStartDate', defaultShow: true},
    {title: 'デモ終了日', field: 'demonstrationEndDate', defaultShow: true},
    {title: '定価', field: 'catalogPrice', defaultShow: true},
    {title: '納入価', field: 'deliveryPrice', defaultShow: true},
    {title: '税込／税抜', field: 'taxIncluded', defaultShow: true},
    {title: '税率', field: 'taxRate', defaultShow: true},
    {title: 'バーコードの表示値', field: 'optionalBarcode', defaultShow: true},
    {title: 'GS1バーコード', field: 'gs1Barcode', defaultShow: true},
    {title: '備考1', field: 'notes', defaultShow: true},
    {title: '備考2', field: 'notes2', defaultShow: true},
    {title: '備考3', field: 'notes3', defaultShow: true},
    // Memo: 初期登録はインポート前提なので、ひとまずGS1は無しにしておく
  ],
  appendImportProductList: [
    // Memo: 当該は 運用開始後 [Excelから一括登録] (機器の追加登録) で使用する テーブル列定義
    {title: '管理番号', field: 'managementId', defaultShow: true},
    {title: 'シリアル番号', field: 'serialNumber', defaultShow: true},
    {title: 'ロット番号', field: 'lotNumber', defaultShow: true},
    {title: '大分類', field: 'rootCategory', defaultShow: true},
    {title: '小分類', field: 'narrowCategory', defaultShow: true},
    {title: 'メーカー名', field: 'makerName', defaultShow: true},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: '型式', field: 'name', defaultShow: true},
    {title: '特定保守製品', field: 'isSpecificMaintain', defaultShow: true},
    {title: 'JMDNコード', field: 'jmdnCode', defaultShow: true},
    {title: 'JANコード', field: 'janCode', defaultShow: true},
    {title: '承認番号', field: 'approvalNumber', defaultShow: true},
    {title: '貸出区分', field: 'permanentlyAssigned', defaultShow: true},
    // {title: '稼働状況', field: 'status', defaultShow: true},
    // {title: '機器管理場所', field: 'hospitalRoom', defaultShow: true},
    {title: '親機・子機', field: 'isBaseUnit', defaultShow: true},
    {title: '購入日', field: 'dateOfPurchase', defaultShow: true},
    {title: '購入区分', field: 'waysOfPurchase', defaultShow: true},
    {title: '購入元', field: 'purchasedNationalExpense', defaultShow: true},
    {title: '耐用年数', field: 'legalDurableYear', defaultShow: true},
    {title: '資産番号', field: 'assetRegisterNumber', defaultShow: false},
    {title: '担当代理店', field: 'hospitalDealer', defaultShow: false},
    {title: '管理部署', field: 'hospitalProductMgmtSection', defaultShow: false},
    {title: '保守契約', field: 'isMaintenanceContract', defaultShow: true},
    // {title: '減価償却費', field: 'depreciationAmount', defaultShow: true},
    // {title: '帳簿価格', field: 'bookValue', defaultShow: true},
    {title: '廃棄日', field: 'dateOfDisposal', defaultShow: true},
    {title: '廃棄理由', field: 'reasonOfDisposal', defaultShow: true},
    {title: 'レンタル番号', field: 'rentalId', defaultShow: true},
    {title: 'レンタル企業名', field: 'rentalDealerName', defaultShow: true},
    {title: 'レンタル金額', field: 'rentalFee', defaultShow: true},
    {title: 'レンタル開始日', field: 'rentalStartDate', defaultShow: true},
    {title: 'レンタル終了予定日', field: 'rentalDueDate', defaultShow: true},
    {title: 'レンタル機器返却日', field: 'rentalReturnDate', defaultShow: true},
    {title: 'リース番号', field: 'leaseId', defaultShow: true},
    {title: 'リース企業名', field: 'leaseDealerName', defaultShow: true},
    {title: 'リース金額', field: 'leaseFee', defaultShow: true},
    {title: 'リース開始日', field: 'leaseStartDate', defaultShow: true},
    {title: 'リース終了予定日', field: 'leaseDueDate', defaultShow: true},
    {title: 'リース機器返却日', field: 'leaseReturnDate', defaultShow: true},
    {title: 'デモの目的', field: 'purposeOfDemonstration', defaultShow: true},
    {title: 'デモ開始日', field: 'demonstrationStartDate', defaultShow: true},
    {title: 'デモ終了日', field: 'demonstrationEndDate', defaultShow: true},
    {title: '定価', field: 'catalogPrice', defaultShow: true},
    {title: '納入価', field: 'deliveryPrice', defaultShow: true},
    {title: '税込／税抜', field: 'taxIncluded', defaultShow: true},
    {title: '税率', field: 'taxRate', defaultShow: true},
    {title: 'バーコードの表示値', field: 'optionalBarcode', defaultShow: true},
    {title: 'GS1バーコード', field: 'gs1Barcode', defaultShow: true},
    {title: '備考1', field: 'notes', defaultShow: true},
    {title: '備考2', field: 'notes2', defaultShow: true},
    {title: '備考3', field: 'notes3', defaultShow: true},

    // Memo: 初期登録はインポート前提なので、ひとまずGS1は無しにしておく
  ],
  trainingPlanList: [
    {title: 'ステータス', field: 'status', defaultShow: true},
    {title: '研修の名称', field: 'name', defaultShow: true},
    {title: '研修内容', field: 'trainingCategory', defaultShow: true},
    {title: '実施 / 予定 ', field: 'trainingProgress', defaultShow: true},
  ],
  trainingScheduleList: [
    {title: 'ステータス', field: 'status', defaultShow: true},
    {title: '研修の名称', field: 'name', defaultShow: true},
    {title: '研修内容', field: 'trainingTypes', defaultShow: true},
    {title: '研修予定日', field: 'scheduleDateRange', defaultShow: true},
    {title: '研修場所', field: 'place', defaultShow: true},
    {title: '研修対象人数', field: 'numberOfTrainees', defaultShow: true},
  ],
  trainingReportList: [
    {title: '研修予定名', field: 'scheduleName', defaultShow: true},
    {title: '研修計画マスタ', field: 'name', defaultShow: true},
    {title: '研修内容', field: 'trainingTypes', defaultShow: true},
    {title: '研修実施日', field: 'trainingDateRange', defaultShow: true},
    {title: '研修場所', field: 'place', defaultShow: true},
  ],
  faultReceptionList: [
    {title: '受付番号', field: 'receptionId', defaultShow: true},
    {title: '受付日時', field: 'createdAt', defaultShow: true},
    {title: 'どんな機器か?', field: 'productFeature', defaultShow: true},
    {title: 'どんな不具合か?', field: 'faultDetail', defaultShow: true},
    {title: '使用していた場所は?', field: 'rentalRoomName', defaultShow: true},
    {title: '誰に聞いたらよいか?', field: 'reporterName', defaultShow: true},
    {title: 'どこで回収できるか?', field: 'location', defaultShow: true},
    {title: '代替機', field: 'isNeedAlternativeDevice', defaultShow: true},
    {title: '至急の対応', field: 'isNeedUrgentResponse', defaultShow: true},
  ],
  receptionList: [
    {title: '受付番号', field: 'receptionId', defaultShow: true},
    {title: '受付日時', field: 'createdAt', defaultShow: true},
    {title: '受付ステータス', field: 'status', defaultShow: true},
    {title: 'どんな機器か?', field: 'productFeature', defaultShow: true},
    {title: 'どんな不具合か?', field: 'faultDetail', defaultShow: true},
    {title: '使用していた場所は?', field: 'rentalRoomName', defaultShow: true},
    {title: '誰に聞いたらよいか?', field: 'reporterName', defaultShow: true},
    {title: 'どこで回収できるか?', field: 'location', defaultShow: true},
    {title: '代替機', field: 'isNeedAlternativeDevice', defaultShow: true},
    {title: '至急の対応', field: 'isNeedUrgentResponse', defaultShow: true},
  ],
  wholeProductList: [
    {title: '大分類', field: 'rootCategory', defaultShow: true},
    {title: '小分類', field: 'narrowCategory', defaultShow: true},
    {title: 'メーカー名', field: 'maker', defaultShow: true},
    {title: '機種名', field: 'displayName', defaultShow: true},
    {title: '型式', field: 'name', defaultShow: true},
    {title: '承認番号', field: 'approvalNumber', defaultShow: true},
    {title: 'JMDNコード', field: 'jmdnCode', defaultShow: true},
    {title: 'JANコード', field: 'janCode', defaultShow: true},
    {title: '特定保守製品', field: 'isSpecificMaintain', defaultShow: true},
    {title: 'クラス分類', field: 'className', defaultShow: true},
    {title: '定価', field: 'catalogPrice', defaultShow: true},
    {title: 'GTINコード', field: 'newJanCode', defaultShow: true},
  ],
};
