import {MenuItemValues, CodeReaderTypes} from './constants';

export type MenuItemValue = (typeof MenuItemValues)[number];

export type MenuItemSetting = {
  icon: React.ReactNode;
  label: string;
  value: MenuItemValue;
};

export type CodeReaderType = (typeof CodeReaderTypes)[number];

export enum CodeReaderActionType {
  CODE_READER_PUT_ITEM = 'CODE_READER_PUT_ITEM',
}

export type CodeReaderPutItemAction = {
  type: CodeReaderActionType.CODE_READER_PUT_ITEM;
  payload: {
    codeReaderType: CodeReaderType;
  };
};

export type CodeReaderAction = CodeReaderPutItemAction;

export type CodeReaderState = {
  codeReaderType: CodeReaderType;
};
