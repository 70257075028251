import React, {MouseEventHandler} from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {Grid, Typography, IconButton} from '@mui/material';
import {SxProps, Theme} from '@mui/material/styles';

type DialogTitleProps = {
  children: React.ReactNode | string;
  onClose?: MouseEventHandler;
  disabledCancel?: boolean;
};

const rootStyle: SxProps<Theme> = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'black',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  flex: '0 0 auto',
});

export const DialogTitle = ({onClose, disabledCancel, children}: DialogTitleProps) => {
  return (
    <Grid sx={rootStyle}>
      <Typography>{children}</Typography>
      <Grid>
        {!disabledCancel && (
          <IconButton color="inherit" onClick={onClose}>
            <ClearIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};
