import React from 'react';

import {Button, styled} from '@material-ui/core';

type Props = {
  label: string;
  onChange: () => void;
};

export const MenuCard = ({label, onChange}: Props) => {
  // ラベルを改行するために、改行文字('\n')を含むJSXを作成
  const lines = label.split('\n');
  const multiLineLabel = lines.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index !== lines.length - 1 && <br />}
    </React.Fragment>
  ));

  return (
    <CardRoot onClick={onChange} data-testid={'menu-card'}>
      {multiLineLabel}
    </CardRoot>
  );
};
const CardRoot = styled(Button)(({theme}) => ({
  borderRadius: '40px',
  boxShadow: '0px 8px 0px 0px rgba(209, 216, 245, 1)',
  '&:active': {
    color: theme.palette.rent.light,
  },
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 'clamp(20px, 4vmin, 48px)', //clampの相対値は右記 48px (フォントサイズ) / 1440px (対象画面サイズ) * 100 = 3.3333
  fontWeight: 'bold',
  textAlign: 'center',
  color: theme.palette.rent.main,
  backgroundColor: 'white',
  lineHeight: '1.3em',
}));
