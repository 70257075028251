import React from 'react';
import {styled, Theme, Button} from '@material-ui/core';

type Props = {
  label: string;
  disabled: boolean;
  onSubmit: () => void;
};
export const ReceptButton = ({label, disabled, onSubmit}: Props) => {
  return (
    <StyledButton onClick={onSubmit} disabled={disabled}>
      {label}
    </StyledButton>
  );
};

const StyledButton = styled(Button)(({theme}: {theme: Theme}) => ({
  color: theme.palette.rent.main,
  backgroundColor: 'white',
  fontSize: '40px',
  fontWeight: 'bold',
  borderRadius: '40px',
  boxShadow: '0px 8px 0px 0px #B2CBF0',
  width: '384px',
  height: '200px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
