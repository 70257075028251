import React, {useCallback} from 'react';
import {RelatedContentSection} from './RelatedContentSection';
import {TrainingScheduleProductListItem} from './TrainingScheduleProductListItem';
import {TrainingSchedule} from '@modules/training_schedules/types';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {EditProductsDialog, EditProductsDialogProps} from '../EditProductsDialog';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {updateTrainingSchedule} from '@modules/training_schedules/api';
import {WholeProductIndex} from '@modules/products/types';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';

type Props = {
  trainingSchedule: TrainingSchedule | null;
  onChangeProducts: () => void;
};

export const TrainingScheduleProductsListContainer: React.FC<Props> = ({trainingSchedule, onChangeProducts}) => {
  const {myInfo} = useMyInfo();
  const {isReadOnly} = useMyRole();
  const handleAddProduct = useCallback(async () => {
    const wholeProducts = await dialogHandler.open<EditProductsDialogProps, WholeProductIndex[]>(EditProductsDialog, {
      hospitalHashId: myInfo.hospitalHashId,
      defaultValues: trainingSchedule?.wholeProducts,
    });

    await updateTrainingSchedule(myInfo.hospitalHashId, {
      trainingScheduleHashId: trainingSchedule?.hashId ?? '',
      wholeProductHashIds: wholeProducts.map((item) => item.hashId),
    });
    onChangeProducts();
  }, [myInfo.hospitalHashId, onChangeProducts, trainingSchedule?.hashId, trainingSchedule?.wholeProducts]);

  return (
    <RelatedContentSection
      sectionName={'研修対象の製品'}
      numberOfCount={trainingSchedule?.wholeProducts.length ?? 0}
      onAddRelatedContent={handleAddProduct}
      isReadOnly={isReadOnly}>
      {trainingSchedule &&
        (trainingSchedule.wholeProducts ?? []).map((item, index) => (
          <TrainingScheduleProductListItem wholeProduct={item} key={index} />
        ))}
    </RelatedContentSection>
  );
};
