import React from 'react';
import {FaultReceptionHeader} from '@components/molecules/FaultReceptionHeader';
import {Box, styled, Theme, Grid} from '@material-ui/core';
import {MenuCard} from './MenuCard';
import {ConfirmCard} from './ConfirmCard';
import {useNavigate} from 'react-router-dom';
import {Footer} from '@Apps/FaultReception/Footer';
import {useMyRole} from '@modules/hospital_users/hooks/useMyRole';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchHospitalSettingsQuery} from '@modules/hospital_settings/api';
import {faultReceptionSettings} from '@modules/hospital_settings/types';
import {InnerLoading} from '@molecules/Loading';

type ReceptionMenuSettings = {
  showRentableDevices: boolean;
  showFaultReceptions: boolean;
};

/**
 * Asset病棟ユーザーメニュー
 * @todo ボタンが複数の時、タブレットで表示出来なくなるのでレスポンシブ対応(2x2に切り替わる)を行う
 * @returns
 */
export const ReceptionMenu = () => {
  const {isWard} = useMyRole();
  const {myInfo} = useMyInfo();
  const navigate = useNavigate();

  const {data, isLoading} = useFetchHospitalSettingsQuery(myInfo.hospitalHashId);
  if (isLoading) {
    return <InnerLoading />;
  }

  const settings: ReceptionMenuSettings = {
    showRentableDevices:
      data?.data?.find((x) => x.key === faultReceptionSettings.show_rentable_devices.key)?.value === 'true',
    showFaultReceptions:
      data?.data?.find((x) => x.key === faultReceptionSettings.show_fault_receptions.key)?.value === 'true',
  };

  /**
   * 病棟ユーザーかつ表示設定がOFFの時は非表示
   */
  const showRentableDevices = !isWard || settings.showRentableDevices;
  const showFaultReceptions = !isWard || settings.showFaultReceptions;

  return (
    <StyledBody>
      <FaultReceptionHeader showLogo title={'Asset病棟ユーザーメニュー'} />
      <StyledMain>
        {showRentableDevices && (
          <StyledContainer>
            <ItemGrid>
              <MenuCard label={'貸出可能\n機器の閲覧'} onChange={() => navigate('/shared/rentable_devices')} />
            </ItemGrid>
            <MenuItemGrid />
          </StyledContainer>
        )}
        {showFaultReceptions && (
          <StyledContainer>
            <ItemGrid>
              <MenuCard label={'不具合受付'} onChange={() => navigate('/shared/faut_reception/register/input')} />
            </ItemGrid>
            <MenuItemGrid>
              <ConfirmCard label={'不具合受付の履歴確認'} onChange={() => navigate('/shared/fault_receptions')} />
            </MenuItemGrid>
          </StyledContainer>
        )}
      </StyledMain>
      <Footer
        text={'選択してください'}
        backButtonHandler={() => {
          // FIXME:暫定的に修理に戻るようにする
          navigate('/repairs');
        }}
        isWard={isWard}
      />
    </StyledBody>
  );
};

const StyledBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
});

const StyledMain = styled(Box)(({theme}: {theme: Theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  padding: '32px',
  backgroundColor: theme.palette.grey[50],
  width: 'calc(100vw - 64px);',
  height: 'calc(100vh - 286px)',
}));

const StyledContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 20px',
});

const ItemGrid = styled(Grid)(({theme}) => ({
  width: 'min(36vmin,368px)',
  height: 'min(36vmin,368px)',
}));

const MenuItemGrid = styled(Grid)(({theme}) => ({
  width: 'min(36vmin,368px)',
  height: 'min(8vmin,80px)',
  marginTop: '24px',
}));
