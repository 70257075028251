import React from 'react';
import {makeStyles, Theme} from '@material-ui/core';
import Logo from '../../../assets/asset_logo.png';

type Props = {
  onClick?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 132,
    objectFit: 'contain',
    WebkitTouchCallout: 'none',
    msTouchSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    userSelect: 'none',
    pointerEvents: 'none',
  },
}));

export const HITOTSULogo: React.FC<Props> = ({onClick}) => {
  const classes = useStyles();

  return (
    <img
      className={classes.root}
      src={Logo}
      alt="HITOTSU logo"
      onClick={onClick}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }}
    />
  );
};
