import {axios} from '@front-libs/core';
import {useQuery} from 'react-query';
import {StandardListApiResponse} from '../common/type';
import {HospitalDepartMent} from './types';
import {useMemo} from 'react';
import {getHospitalDepartmentsKey} from '@constants/api';

export type GetDepartmentsParams = {
  name?: string;
  page?: number;
  perPage?: number;
  order?: string;
  showDeleted?: boolean; // 削除済み取得
};

export const fetchHospitalDepartments = async (hospitalHashId: string, params: GetDepartmentsParams) => {
  const baseUrl = `${import.meta.env.VITE_SERVER_URL}/api/v1/hospitals/${hospitalHashId}/hospital_department`;
  const {data} = await axios.get<StandardListApiResponse<HospitalDepartMent>>(baseUrl, {params});
  return data;
};

export const useFetchHospitalDepartments = (hospitalHashId: string, params: GetDepartmentsParams) => {
  const query = useQuery([getHospitalDepartmentsKey, hospitalHashId, params], () =>
    fetchHospitalDepartments(hospitalHashId, params)
  );
  return useMemo(
    () => ({
      ...query,
      data: query.data?.data ?? [],
      totalCount: query.data?.totalCount ?? 0,
    }),
    [query]
  );
};
