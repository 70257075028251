import React, {ChangeEvent} from 'react';
import {Controller, Control, FieldValues} from 'react-hook-form';
import {Box, Typography, TextField} from '@mui/material';
import {FormLabelBadge} from '@molecules/FormLabelBadge';

type FormFieldProps = {
  name: string;
  control: Control<FieldValues>;
  label: string;
  placeholder: string;
  required?: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

/**
 * 入力フォーム
 */
export const FormField: React.FC<FormFieldProps> = React.memo(
  ({name, control, label, placeholder, required = true, handleChange}) => (
    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2}}>
      <Typography sx={{fontSize: '14px', minWidth: '72px', mr: 1}}>{label}</Typography>
      <FormLabelBadge
        required={required}
        padding="2px 6px"
        minWidth="32px"
        margin="0px"
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      />
      <Controller
        name={name}
        control={control}
        render={({field}) => (
          <TextField
            size="small"
            sx={{backgroundColor: 'white', ml: 2, width: '100%'}}
            placeholder={placeholder}
            {...field}
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              field.onChange(e);
              handleChange(e);
            }}
          />
        )}
      />
    </Box>
  ),
  (prev, next) => prev.control === next.control && prev.handleChange === next.handleChange
);
