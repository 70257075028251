import React from 'react';
import {HeaderBackwardButton} from '@components/molecules/Buttons/HeaderBackwardButton';
import {Box, Button, Divider, SxProps, Typography} from '@mui/material';

const headerLabelStyle: SxProps = {
  fontSize: '18px',
  fontWeight: 'bold',
};

const HeaderComponentStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px 16px 32px',
};

const RegisterChildUnitButtonStyle: SxProps = {
  height: '42px',
  padding: '8px 16px',
  fontWeight: 500,
  fontSize: '15px',
};

type HeaderProps = {
  onClickPrevButton: VoidFunction;
  onClickNextButton: VoidFunction;
};

export const HeaderComponent = ({onClickPrevButton, onClickNextButton}: HeaderProps) => {
  return (
    <>
      <Box sx={HeaderComponentStyle}>
        <Box>
          <HeaderBackwardButton label="機器詳細に戻る" onClick={onClickPrevButton} />
          <Typography variant="h4" sx={headerLabelStyle}>
            関連する子機
          </Typography>
        </Box>
        <Button variant="outlined" sx={RegisterChildUnitButtonStyle} onClick={onClickNextButton}>
          子機を登録
        </Button>
      </Box>
      <Divider />
    </>
  );
};
