import {useMemo, useCallback} from 'react';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import {InspectionResultListElement, ViewInspectionType} from '@Apps/InspectionResultList/pc/types';
import {Column} from '@molecules/Table/props';
import {SmallInspectionResultStatusBadge} from '@components/atoms/InspectionResultStatusBadge';
import {ScheduledDateColumn} from './InternalInspection/columns/ScheduledDateColumn';
import {formatRFC3339Date, convertDateToSimpleDate, isNullish} from '@front-libs/helpers';
import {useInspectionResultStatus, usePerPage} from './InternalInspection/states/states';
import {InspectionResultIndex} from '@modules/inspection_results/types';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {
  UpdateScheduledDateDialog,
  UpdateScheduledDateDialogProps,
  UpdateScheduledDateDialogResult,
} from './InternalInspection/dialogs/UpdateScheduledDateDialog';
import {openSnackBar} from '@molecules/SnackBar';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';
import {
  applyPreviousInspectionResults,
  checkPreviousInspectionResults,
  deleteInspectionResults,
  FetchInspectionResultsParam,
  requestExportInspectionResultPDFs,
  updateInspectionResults,
  UpdateInspectionResultsParam,
  useFetchInspectionResultsQuery,
} from '@modules/inspection_results/api';
import {InspectionResultEvaluation, InspectionResultStatus} from '@modules/inspection_results/enum';
import {CategoryFormatter} from '@modules/categories/helpers';
import {AlertDialog} from '@components/molecules/Dialogs/AlertDialog';
import {InspectionResultBadge} from '@components/atoms/InspectionResultBadge';
import {
  SkipInspectionDialog,
  SkipInspectionDialogProps,
  SkipInspectionDialogResult,
} from './InternalInspection/dialogs/SkipInspectionDialog';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import {InspectionResultTableManagementIdColumn} from '@organisms/InspectionResultTableManagementIdColumn';
import {useAtomValue} from 'jotai';
import {queryScheduledAtFromAtom, queryScheduledAtToAtom} from './InternalInspection/states/selectors';
import {formatPeriod} from '@modules/inspection_setting/helper';

const FAKE_INSPECTION_ID = 'EgqdR7b5l3mzr6Y';
// NOTE:/Apps/InspectionResult/ViewInspectionResult/index.tsxのtypeToTextと同義
// NOTE:/Apps/InspectionTemplates/TemplateList/TemplateSelector.tsxのtypeToTextと同義
export const typeToText = {
  pre_use: '使用前点検',
  in_use: '使用中点検',
  post_use: '使用後点検',
  periodic: '定期点検',
  maker_periodic: '',
  // FIXME dailyや'pre_use,in_use,post_use'は利用しない。削除すると本番影響があるため、削除はしない
  daily: '',
  'pre_use,in_use,post_use': '',
};

export const useTableData = (
  type: ViewInspectionType,
  status: InspectionResultStatus,
  data: InspectionResultIndex[],
  totalCount: number
) => {
  const [perPage] = usePerPage();
  const tableLayoutKey = 'inspectionResultList';

  const [tableLayout, setTableLayout] = useTableLayout(
    tableLayoutKey,
    useMemo(
      () => ({
        status: true,
        managementId: true,
        indexOfTheDay: type === 'pre_use',
        suspendedAt: status === 'uncompleted',
        scheduledTime: type === 'periodic',
        completedAt: status === 'completed',
        result: status === 'completed',
        isAdhocDate: status === 'uncompleted' || status === 'completed',
        inspector: status !== 'unplanned',
        skipReason: status === 'skipped',
        skippedTime: status === 'skipped',
      }),
      [type, status]
    )
  );

  const columns = useMemo(() => {
    const tableColumn = Object.assign<Column<InspectionResultListElement>[], TableLayout[]>(
      [],
      tableLayout?.currentLayout
    );
    return tableColumn.map<Column<InspectionResultListElement>>((item) => {
      switch (item.field) {
        case 'status':
          item.render = SmallInspectionResultStatusBadge;
          break;
        case 'indexOfTheDay':
          item.sorting = false;
          item.render = ({indexOfTheDay}: {indexOfTheDay: number | null}) =>
            indexOfTheDay !== null ? `${indexOfTheDay - 1}回` : '';
          break;
        case 'scheduledTime':
          item.render = ScheduledDateColumn;
          break;
        case 'managementId':
          item.render = InspectionResultTableManagementIdColumn;
          break;
        case 'result':
          item.render = ({result}: {result: InspectionResultEvaluation}) =>
            InspectionResultBadge({inspectionResult: result});
          break;
      }
      item.noBodyWrap = true;
      return item;
    });
  }, [tableLayout]);

  const rows = useMemo<InspectionResultListElement[]>(() => {
    return (data ?? []).map(
      (i): InspectionResultListElement => ({
        hashId: i.hashId,
        inspectionHashId: i.inspectionHashId,
        inspectorHashId: i.inspectorHashId,
        hospitalHashId: i.hospitalHashId,
        hospitalProductHashId: i.hospitalProductHashId,
        type: i.type ?? undefined,
        inspectionType: i.type ? typeToText[i.type] : undefined,
        status: i.status,
        result: i.result,
        completedAt: formatRFC3339Date(i.completedAt ?? '', 'YYYY/MM/DD HH:mm') ?? '',
        suspendedAt: formatRFC3339Date(i.suspendedAt ?? '', 'YYYY/MM/DD HH:mm') ?? '',
        scheduledTime: i.scheduledTime ? new Date(i.scheduledTime) : null,
        inspector: i.inspector ? `${i.inspector.lastName} ${i.inspector.firstName}` : '',
        makerName: i.hospitalProduct.maker.name,
        managementId: i.hospitalProduct?.managementId,
        serialNumber: i.hospitalProduct?.serialNumber,
        rootCategory: CategoryFormatter.getRootCategory(i.hospitalProduct?.categories)?.name ?? '',
        narrowCategory: CategoryFormatter.getNarrowCategory(i.hospitalProduct?.categories)?.name ?? '',
        displayName: i.hospitalProduct?.displayName ?? '',
        name: i.hospitalProduct?.name ?? '',
        purchasedDate: i.hospitalProduct?.dateOfPurchase ? i.hospitalProduct.dateOfPurchase.replace(/-/g, '/') : '',
        depreciationAmount: i.hospitalProduct?.depreciationAmount,
        isAdhocDate: i.isAdhocDate ? '臨時' : '通常',
        skipReason: i.skipReason ?? '',
        skippedTime: formatRFC3339Date(i.skippedTime ?? '', 'YYYY/MM/DD') ?? '',
        hospitalRoomName: HospitalRoomFormatter.getFullRoom(i.hospitalProduct?.hospitalRoom),
        rentHospitalRoomName: HospitalRoomFormatter.getFullRoom(i.hospitalProduct?.rentHospitalRoom),
        inspectionSettingName: i.inspectionProduct?.name ?? '',
        inspectionName: i.inspection.name ?? '',
        inspectionPeriod:
          i.inspectionProduct?.periodicInspectionPeriodUnit && i.inspection?.type === 'periodic'
            ? formatPeriod(
                i.inspectionProduct.periodicInspectionPeriod ?? 0,
                i.inspectionProduct.periodicInspectionPeriodUnit
              )
            : '',

        indexOfTheDay: i.numberOfSameDayFormerResults !== null ? i.numberOfSameDayFormerResults + 1 : null,
      })
    );
  }, [data]);

  const totalPage = useMemo(() => {
    return Math.ceil((totalCount ?? 0) / perPage);
  }, [totalCount, perPage]);

  return {
    columns,
    rows,
    tableLayout,
    setTableLayout,
    totalPage,
  } as const;
};

export const useChangeScheduledDate = (hospitalHashId: string, data: InspectionResultIndex[]) => {
  const changeScheduledDate = useCallback(
    async (hashIds: string[], date: Date | null) => {
      if (hashIds.length === 0) return;
      try {
        if (date === null) {
          const res = await dialogHandler.open<UpdateScheduledDateDialogProps, UpdateScheduledDateDialogResult>(
            UpdateScheduledDateDialog,
            {
              num: hashIds.length,
            }
          );

          date = res.scheduledDate;
        }
      } catch (_) {
        // 何も入力されなかった場合
        return;
      }

      const scheduledTime = convertDateToSimpleDate(date);
      const inspectionResults = hashIds
        .map((hid): UpdateInspectionResultsParam['inspectionResults'][number] | null => {
          const result = data.find((r) => r.hashId === hid);
          return result !== undefined
            ? {
                inspectionResultHashId: hid,
                scheduledTime: scheduledTime,
                status: result.status,
              }
            : null;
        })
        .filter((r): r is UpdateInspectionResultsParam['inspectionResults'][number] => r !== null);

      try {
        const res = await updateInspectionResults(hospitalHashId, FAKE_INSPECTION_ID, {
          inspectionResults,
        });
        openSnackBar(`${res.data.length}件の点検予定日を更新しました`, 'left', 'bottom', 'info');
      } catch (error: unknown) {
        console.error(error);
        openSnackBar(`エラーが発生しました: ${error}`, 'left', 'bottom', 'error');
      }
    },
    [data, hospitalHashId]
  );

  return changeScheduledDate;
};

export const useDeleteInspectionResult = (hospitalHashId: string, data: InspectionResultIndex[]) => {
  const deleteInspectionResult = useCallback(
    async (hashIds: string[], status: InspectionResultStatus) => {
      if (hashIds.length === 0) return;
      let title = '';
      switch (status) {
        case 'completed':
          title = '点検結果';
          break;
        case 'uncompleted':
          title = '実施中の点検予定';
          break;
        case 'skipped':
          title = 'スキップした予定';
          break;
        default:
          title = '点検予定';
          break;
      }

      try {
        const content =
          hashIds.length === 1
            ? `この${title}を削除しようとしています。\n\nこのアクションは元に戻せません。`
            : `${hashIds.length}件の${title}を削除しようとしています。\n\nこのアクションは元に戻せません。`;
        await dialogHandler.open(MessageDialog, {
          title: `${title}を削除しますか？`,
          content: content,
          positiveButtonLabel: '削除',
          warning: true,
        });
      } catch (_e) {
        // キャンセルが押された場合
        return;
      }
      try {
        await deleteInspectionResults(hospitalHashId, FAKE_INSPECTION_ID, {
          inspectionResultHashIds: hashIds,
        });
        const message = hashIds.length === 1 ? '削除しました' : `${hashIds.length}件削除しました`;
        openSnackBar(message, 'left', 'bottom', 'info');
      } catch (e: unknown) {
        if (e) {
          console.error(e);
          openSnackBar(`エラーが発生しました: ${e}`, 'left', 'bottom', 'error');
        }
      }
    },
    [hospitalHashId]
  );

  return deleteInspectionResult;
};

export const useDownloadPDFs = (hospitalHashId: string) => {
  const downloadPDFs = useCallback(
    async (hashIds: string[]) => {
      if (hashIds.length === 0) return;

      await requestExportInspectionResultPDFs(hospitalHashId, FAKE_INSPECTION_ID, {
        inspectionResultHashIds: hashIds,
      });

      openSnackBar('点検表のPDF出力を受け付けました。\n処理完了後、通知をご確認ください。', 'center', 'top');
    },
    [hospitalHashId]
  );

  return downloadPDFs;
};

export const useApplyPreviousInspectionResults = (hospitalHashId: string, viewInspectionType: ViewInspectionType) => {
  const updateInspectionResult = useCallback(
    async (hashIds: string[]) => {
      if (hashIds.length === 0) return;

      try {
        const content =
          '点検予定を一括で完了する場合、各点検予定に直前の点検結果を反映し、点検を完了とします。\nなお、以下に該当する点検予定は完了できません（それ以外の点検予定は完了できます）。\n・前回の点検結果が存在しない\n・直前の点検結果で使用した点検表が更新されている\n・直前の点検結果とは異なる点検表が紐づけられている\n・直前の点検結果に異常値が含まれる\n\n点検予定を一括で完了しますか？';
        await dialogHandler.open(MessageDialog, {
          title: '前回の点検結果を用いて一括完了します',
          content: content,
          positiveButtonLabel: '実行',
          warning: false,
        });
      } catch (_e) {
        // キャンセルが押された場合
        return;
      }

      const previousInvalidInspectionResults = await checkPreviousInspectionResults(
        hospitalHashId,
        FAKE_INSPECTION_ID,
        {
          inspectionResultHashIds: hashIds.join(),
        }
      );
      if (!isNullish(previousInvalidInspectionResults)) {
        try {
          const content =
            '選択した点検予定の中に、以下の点検予定が含まれています。\n・前回の点検結果が存在しない\n・直前の点検結果で使用した点検表が更新されている\n・直前の点検結果とは異なる点検表が紐づけられている\n・直前の点検結果に異常値が含まれる\n上記に該当する点検予定は完了できません。';
          await dialogHandler.open(AlertDialog, {
            title: '選択した点検予定の一部を完了できません',
            content: content,
            positiveButtonLabel: '上記に該当する点検予定を除いて完了',
            warning: true,
          });
        } catch (_e) {
          // キャンセルが押された場合
          return;
        }
      }

      try {
        const invalidHashIds: string[] = [];

        if (!isNullish(previousInvalidInspectionResults)) {
          previousInvalidInspectionResults.forEach((result) => {
            result.inspectionResultHashIds.forEach((hashId) => invalidHashIds.push(hashId));
          });
        }
        const filteredHashIds = hashIds.filter(
          (hashId) => !invalidHashIds.some((invalidHashId) => invalidHashId === hashId)
        );

        if (filteredHashIds.length > 0) {
          const res = await applyPreviousInspectionResults(hospitalHashId, FAKE_INSPECTION_ID, {
            inspectionResultHashIds: filteredHashIds,
          });
          const completedCount = res.data.filter((element) => element.succeed === true).length;
          const message = hashIds.length === 1 ? '完了しました' : `${completedCount}件完了しました`;
          openSnackBar(message, 'left', 'bottom', 'info');
        } else {
          openSnackBar(`選択したすべての点検予定を完了できませんでした`, 'left', 'bottom', 'error');
        }
      } catch (e: unknown) {
        if (e) {
          console.error(e);
          openSnackBar(`エラーが発生しました: ${e}`, 'left', 'bottom', 'error');
        }
      }
    },
    [hospitalHashId]
  );

  return updateInspectionResult;
};

export const useSkipInspectionResults = (hospitalHashId: string, userHashId: string) => {
  const updateInspectionResult = useCallback(
    async (hashIds: string[]) => {
      if (hashIds.length === 0) return;

      let skipDate: Date;
      let operator: string;
      let reason: string | undefined;

      try {
        const res = await dialogHandler.open<SkipInspectionDialogProps, SkipInspectionDialogResult>(
          SkipInspectionDialog,
          {
            defaultOperatorHashId: userHashId,
            defaultSkipDate: convertDateToSimpleDate(new Date()),
          }
        );

        skipDate = res.skipDate;
        operator = res.operator;
        reason = res.reason;
      } catch (_e) {
        return;
      }

      try {
        await updateInspectionResults(hospitalHashId, FAKE_INSPECTION_ID, {
          inspectionResults: hashIds.map((hashId) => ({
            inspectionResultHashId: hashId,
            status: 'skipped',
            skippedByHashId: operator,
            skippedTime: convertDateToSimpleDate(skipDate),
            skipReason: reason,
          })),
        });

        openSnackBar(`${hashIds.length}件の点検をスキップしました`, 'left', 'bottom', 'info');
        // eslint-disable-next-line no-shadow
      } catch (e) {
        console.error(e);
        openSnackBar(`点検のスキップに失敗しました: ${e}`, 'left', 'bottom', 'error');
      }
    },
    [hospitalHashId, userHashId]
  );

  return updateInspectionResult;
};

export const useInspectionNameFilter = (hospitalHashId: string, type: ViewInspectionType) => {
  const [inspectionResultStatus] = useInspectionResultStatus();
  const queryScheduledAtFrom = useAtomValue(queryScheduledAtFromAtom);
  const queryScheduledAtTo = useAtomValue(queryScheduledAtToAtom);

  const params = useMemo(() => {
    const _p: FetchInspectionResultsParam = {
      statuses: inspectionResultStatus !== 'overdue' ? inspectionResultStatus : 'unplanned',
      types: type,
      perPage: 100,
    };

    if (queryScheduledAtFrom) {
      _p.scheduledAtFrom = queryScheduledAtFrom;
    }

    if (queryScheduledAtTo) {
      _p.scheduledAtTo = queryScheduledAtTo;
    }

    return _p;
  }, [type, inspectionResultStatus, queryScheduledAtFrom, queryScheduledAtTo]);

  const {data: inspectionProducts, isLoading: isFetchingInspectionResults} = useFetchInspectionResultsQuery(
    hospitalHashId,
    FAKE_INSPECTION_ID,
    params
  );

  /** 点検表名のセレクトボックスのフィルター */
  const inspectionNameOptions = useMemo(() => {
    const uniqueNames = new Set();

    return inspectionProducts
      .filter((inspection) => {
        const name = inspection?.inspection?.name;
        return name && !uniqueNames.has(name) && uniqueNames.add(name);
      })
      .map((inspection) => ({
        label: inspection.inspection.name,
        value: inspection.inspection.name,
      }));
  }, [inspectionProducts]);

  /** 点検名のセレクトボックスのフィルター */
  const inspectionSettingNameOptions = useMemo(() => {
    const uniqueNames = new Set();

    return inspectionProducts
      .filter((inspection) => {
        const name = inspection?.inspectionProduct?.name;
        return name && !uniqueNames.has(name) && uniqueNames.add(name);
      })
      .map((inspection) => ({
        label: inspection.inspectionProduct.name,
        value: inspection.inspectionProduct.name,
      }));
  }, [inspectionProducts]);

  return {
    isFetchingInspectionResults,
    inspectionNameOptions,
    inspectionSettingNameOptions,
  };
};
