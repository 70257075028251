import React from 'react';
import {Box, Divider, Typography, styled} from '@material-ui/core';
const StyledTitle = styled(Typography)({
  marginBottom: '8px',
  fontWeight: 600,
  fontSize: '16px',
  color: '#172B4D',
});

const Underline = styled(Divider)({
  height: '1px',
  backgroundColor: '#5D6B82',
});

const TopAndBottomMarginBox = styled(Box)(({margin}: {margin: number}) => ({
  margin: `${margin}px 0`,
}));

export const UnderlineTitle = ({title, margin = 16}: {title: string; margin?: number}) => {
  return (
    <TopAndBottomMarginBox margin={margin}>
      <StyledTitle variant="h5">{title}</StyledTitle>
      <Underline variant="fullWidth" />
    </TopAndBottomMarginBox>
  );
};
