import React, {memo, useCallback} from 'react';
import {Divider} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import {useFormikContext} from 'formik';
import {FaultReceptionFormType} from '..';
import {LabelBadgeAndCheckbox} from '../CheckBox';
import {HeadingLabel} from '../HeadingLabel';
import {RadioButtonField} from './RadioButtonField';
import {ReceptionTextField} from './ReceptionTextField';
import {FaultReceptionLocationType} from '@modules/fault_receptions/types';

const StyledDivider = styled(Divider)({
  margin: '16px 0',
});

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

/**
 * 不具合受付の入力フォーム部分
 * @returns
 */
export const FaultReceptionForm = memo(
  () => {
    const {values, setFieldValue, validateField} = useFormikContext<FaultReceptionFormType>();

    const setFieldValueCallback = useCallback(
      (name: string, value: boolean | string) => {
        setFieldValue(name, value);
      },
      [setFieldValue]
    );
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFieldValue(name, value);
      },
      [setFieldValue]
    );

    return (
      <StyledContainer>
        <HeadingLabel title="どんな機器ですか？" example="（例:血圧計、輸液ポンプ、など）" required />
        <ReceptionTextField
          name="productFeature"
          value={values.productFeature}
          onChange={handleChange}
          autoFocus={true}
          top={'0px'}
        />
        <HeadingLabel title="どんな不具合ですか？" example="（例：電源が入らない、画面が映らない など）" required />
        <ReceptionTextField
          name="faultDetail"
          rows={3}
          value={values.faultDetail}
          onChange={handleChange}
          top={'0px'}
        />
        <HeadingLabel title="機器を使用していた場所はどこですか？" example="（例：外来、ICU、101号室 など）" required />
        <ReceptionTextField name="rentalRoomName" value={values.rentalRoomName} onChange={handleChange} top={'0px'} />
        <HeadingLabel title="不具合の詳細を知りたい場合、誰に聞いたらよいですか？" required />
        <ReceptionTextField name="reporterName" value={values.reporterName} onChange={handleChange} top={'0px'} />
        <HeadingLabel title="この機器はどこで回収できますか？" required />
        <RadioButtonField
          location={values.location}
          locationName={values.locationName || ''}
          handleChange={(location: FaultReceptionLocationType, locationName: string) => {
            setFieldValueCallback('location', location);
            if (location === 'other') {
              setFieldValueCallback('locationName', locationName);
              setTimeout(() => {
                // 再度Validationを行う setFieldValueは非同期であるため、遅延処理を追加
                validateField('locationName');
              }, 100);
            }
          }}
        />
        <StyledDivider />
        <LabelBadgeAndCheckbox
          label="代替機が必要な場合はチェック"
          name="isNeedAlternativeDevice"
          checked={values.isNeedAlternativeDevice}
          onChange={setFieldValueCallback}
        />
        <LabelBadgeAndCheckbox
          label="至急の対応が必要な場合はチェック"
          name="isNeedUrgentResponse"
          checked={values.isNeedUrgentResponse}
          onChange={setFieldValueCallback}
        />
      </StyledContainer>
    );
  },
  (prevProps, nextProps) => {
    // Propsを持たないので親コンポーネントが変更されてもこのコンポーネントは再レンダリングされない
    return true;
  }
);
