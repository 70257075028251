import {useCallback} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {isNullish} from '@front-libs/helpers';
import {ResetMyInfo} from '@modules/hospital_users/api';

const CACHE_KEY_PREFIX = '@@auth0spajs@@';
const OLD_CACHE_KEY_PREFIX = '@@hitotsu-front@@';

export const useLogout = () => {
  const {logout, isAuthenticated} = useAuth0();
  return useCallback(() => {
    if (isAuthenticated) {
      // ログアウト時にAuth0のキャッシュを削除
      const userKeys = Object.keys(localStorage).filter(
        (key) => key.startsWith(CACHE_KEY_PREFIX) || key.startsWith(OLD_CACHE_KEY_PREFIX)
      );
      for (const key of userKeys) {
        localStorage.removeItem(key);
      }

      logout({
        returnTo: window.location.origin,
      });
      ResetMyInfo();
      !isNullish(window.zE) && window.zE('messenger', 'logoutUser');
    }
  }, [isAuthenticated, logout]);
};
