import React, {memo} from 'react';
import {FormControlLabel, FormControl, RadioGroup, Radio, styled} from '@material-ui/core';

const StyledRadio = styled(Radio)({
  color: '#8B95A6',
  '&.Mui-checked': {
    color: '#007DFF',
  },
});
const StyledFormControlLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    color: '#172B4D',
  },
});

type RadioButtonsProps = {
  value: string;
  options: {label: string; value: string}[];
  onChange: (value: string) => void;
};

/**
 * 不具合受付入力フォームのラジオボタン
 */
export const RadioButton = memo(
  ({value, options, onChange}: RadioButtonsProps) => {
    return (
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="radio"
          value={value}
          row
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange && onChange(e.target.value);
          }}>
          {options.map(({label, value: val}, index) => (
            <StyledFormControlLabel key={index} control={<StyledRadio />} label={label} value={val} />
          ))}
        </RadioGroup>
      </FormControl>
    );
  },
  (prev, next) => {
    return prev.value === next.value;
  }
);
