import {Footer} from '@Apps/BaseSharedMenu/Footer';
import {Header} from '@Apps/BaseSharedMenu/Header';
import {RentalErrorDialog} from '@Apps/BaseSharedMenu/RentalErrorDialog';
import {VerticalPairTable} from '@Apps/BaseSharedMenu/VerticalPairTable';
import {RentButton} from '@Apps/BaseSharedMenu/RentDevices/RentButton';
import {RentProductTable} from '@Apps/BaseSharedMenu/RentDevices/RentProductTable';
import {UnacceptedStatus} from '@Apps/BaseSharedMenu/RentDevices/types';
import {
  canUseDeviceCameraAtom,
  defaultBarcodeScannerTypeAtom,
  lenderNameAtom,
  rentalSettingAtom,
  rentRoomAtom,
  rentStepperAtom,
} from '@Apps/BaseSharedMenu/states';
import {useAsyncEffect} from '@front-libs/core';
import {StrUtil} from '@front-libs/helpers';
import {FetchHospitalProductsParams, getHospitalProducts} from '@modules/hospital_products/api';
import {HospitalProductIndex} from '@modules/hospital_products/types';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {bulkCreateRentals, DeviceLender} from '@modules/rentals/api';
import {Box, styled, SxProps, Theme} from '@mui/material';
import {contentFixedStyle} from '@templates/RentalTemplate';
import {useAtomValue, useSetAtom} from 'jotai';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {NoRowsContent} from '../../NoRowsContent';
import {useValidateToRent} from './hooks';

/**
 * 貸出のバーコードリーダー画面
 * @todo 返却のバーコードリーダー画面とほぼ一緒なので共通化させたい
 */
export const RentByBarcodeReader = () => {
  const {myInfo} = useMyInfo();
  const [rentHospitalProducts, setRentHospitalProducts] = useState<HospitalProductIndex[]>([]);
  const rentRoom = useAtomValue(rentRoomAtom);
  const lenderName = useAtomValue(lenderNameAtom);
  const setRentalStepper = useSetAtom(rentStepperAtom);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [managementId, setManagementId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [status, setStatus] = useState<UnacceptedStatus>(null);
  const navigate = useNavigate();
  const {validateToRent} = useValidateToRent();
  const hospitalRentalSetting = useAtomValue(rentalSettingAtom);
  const canUseDeviceCamera = useAtomValue(canUseDeviceCameraAtom);
  const setDefaultBarcodeScannerType = useSetAtom(defaultBarcodeScannerTypeAtom);

  const deviceLender: DeviceLender | undefined = useMemo(() => {
    if (hospitalRentalSetting === 'not_need') return undefined;

    return {
      userId: hospitalRentalSetting === 'by_barcode' ? lenderName ?? undefined : undefined,
      userName: hospitalRentalSetting === 'by_direct_input' ? lenderName ?? undefined : undefined,
    };
  }, [hospitalRentalSetting, lenderName]);

  const handleClickCancel = useCallback(
    (rowIndex: number) => {
      setRentHospitalProducts(rentHospitalProducts.filter((_item, idx) => idx !== rowIndex));
    },
    [rentHospitalProducts]
  );

  const handleClickSwitchDeviceCamera = useCallback(() => {
    if (canUseDeviceCamera) {
      setDefaultBarcodeScannerType('camera');
      navigate('/shared/rental/product/camera');
    }
  }, [canUseDeviceCamera, navigate, setDefaultBarcodeScannerType]);

  const handleBarcodeReaderInput = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      // NOTE:onKeyPressが非推奨になったので変更したところShift等が追加されるようになったので対応
      if (
        event.key === 'Shift' ||
        event.key === 'Control' ||
        event.key === 'Alt' ||
        event.key === 'Meta' ||
        event.key === 'Backspace' ||
        event.key === 'Delete'
      ) {
        if (event.key === 'Backspace') {
          setManagementId(managementId.slice(0, -1));
        }
        return;
      }
      // Enter以外のキーを検知したらmanagementIdに連結する
      if (event.key !== 'Enter') {
        setManagementId(managementId + event.key);
        return;
      } else if (event.key === 'Enter' && managementId.length === 0) {
        return;
      }

      // Enterを検知したら、managementIdをもとに機器情報を検索する
      let parameterManagementId = StrUtil.toHankaku(managementId);
      if (parameterManagementId[0] === '!') {
        parameterManagementId = parameterManagementId.slice(0, -1);
        const index = parameterManagementId.indexOf('('); // `(` のインデックスを取得
        if (index !== -1) {
          // `(` が見つかった場合
          parameterManagementId = parameterManagementId.slice(index); // `(` の次の文字から末尾までを取得
        }
      }

      setManagementId('');

      const params: FetchHospitalProductsParams = {
        managementId: parameterManagementId,
        permanentlyAssigneds: false,
        statuses: 'ready,working,uninspected,repairing',
      };

      const {data, totalCount} = await getHospitalProducts(myInfo.hospitalHashId, params);

      if (totalCount === 0) {
        setStatus(null);
        setOpenDialog(true);
      } else {
        const validateStatus = validateToRent(data);
        if (validateStatus !== null) {
          setStatus(validateStatus);
          setOpenDialog(true);
          return;
        }
        setRentHospitalProducts([
          ...rentHospitalProducts,
          ...data.filter((item) => !rentHospitalProducts.some((d) => d.hashId === item.hashId)),
        ]);
      }
    },
    [managementId, myInfo.hospitalHashId, rentHospitalProducts, validateToRent]
  );

  const handleSubmit = async () => {
    if (!isSubmitting) setIsSubmitting(true);
  };
  useAsyncEffect(async () => {
    if (!isSubmitting) return;
    const rentData = rentHospitalProducts.map((item) => ({
      hospitalProductHashId: item.hashId,
      hospitalRoomHashId: rentRoom?.hashId,
      checkoutUserHashId: myInfo.hashId,
      forceRental: true,
      checkoutDeviceLender: deviceLender,
    }));
    await bulkCreateRentals(myInfo.hospitalHashId, rentData);
    navigate('/shared/complete');
    setRentalStepper(3);
  }, [isSubmitting]);

  const focusInput = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (!openDialog) e.target.focus();
    },
    [openDialog]
  );

  useEffect(() => {
    setRentalStepper(2);
  }, [setRentalStepper]);

  const RentalTableView = () => {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box sx={rentalTableContainerStyle}>
          <RentProductTable
            rentRoom={rentRoom?.name ?? '未定'}
            rentingHospitalProducts={rentHospitalProducts}
            onClickCancel={handleClickCancel}
          />
        </Box>
        <Box sx={operationContainerStyle}>
          {lenderName && (
            <Box sx={operatorTableStyle}>
              <VerticalPairTable label="持出者" name={lenderName} />
            </Box>
          )}
          <Box>
            <RentButton label={'貸出する'} onChange={handleSubmit} />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Header title={'貸出'} />
      <Box sx={contentFixedStyle}>
        <Box sx={rootStyle}>{rentHospitalProducts.length === 0 ? <NoRowsContent /> : <RentalTableView />}</Box>
      </Box>
      <Footer
        text={'貸出する医療機器の\nバーコードを読み取って下さい'}
        nextButtonLabel={canUseDeviceCamera ? 'カメラで読み取る' : undefined}
        onClickNextButton={handleClickSwitchDeviceCamera}
      />
      <RentalErrorDialog
        open={openDialog}
        onClickButton={() => setOpenDialog(false)}
        status={status as UnacceptedStatus}
        type="rent"
      />
      <StyledBarcodeInput
        type="text"
        autoFocus
        readOnly
        onBlur={focusInput}
        value={managementId}
        onKeyDown={handleBarcodeReaderInput}
      />
    </>
  );
};

const rootStyle: SxProps = {margin: '0 80px', width: '100%'};

const rentalTableContainerStyle: SxProps<Theme> = (theme) => ({
  maxHeight: '100%',
  overflow: 'auto',
  height: 'fit-content',
  width: '65%',
  [theme.breakpoints.up('laptop')]: {
    width: '70%',
  },
});

const StyledBarcodeInput = styled('input')({
  position: 'absolute',
  top: 20,
  marginTop: '-550px',
  '@media screen and (orientation: portrait)': {
    marginTop: '0px',
    marginLeft: '-5500px',
  },
});

const operationContainerStyle: SxProps<Theme> = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  width: '25%',
  height: '398px',
  overflow: 'auto',
  gap: '20px',
  [theme.breakpoints.up('laptop')]: {
    paddingBottom: '16px',
    paddingTop: '16px',
  },
});

const operatorTableStyle: SxProps<Theme> = (theme) => ({
  width: '100%',
  marginTop: '48px',
  [theme.breakpoints.up('laptop')]: {
    marginTop: '28px',
  },
});
