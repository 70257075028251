import React, {memo, useEffect, useState} from 'react';
import {FaultReceptionLocationType} from '@modules/fault_receptions/types';
import {RadioButton} from './RadioButton';
import {Typography, styled} from '@material-ui/core';
import {ReceptionTextField} from './ReceptionTextField';

const StyledExampleText = styled(Typography)({
  fontSize: '16px',
  color: '#172B4D',
  marginTop: '8px',
});

const options: {label: string; value: FaultReceptionLocationType}[] = [
  {label: '所定の場所', value: 'designated_location'},
  {label: 'その他', value: 'other'},
];

type RadioButtonFieldProps = {
  location: string;
  locationName: string;
  handleChange: (location: FaultReceptionLocationType, locationName: string) => void;
};

/**
 * 不具合受付のラジオボタンフィールド
 */
export const RadioButtonField = memo(
  ({location, locationName, handleChange}: RadioButtonFieldProps) => {
    const [showText, setShowText] = useState(location === 'other');
    const [_location, setLocation] = useState(location);
    const [_locationName, setLocationName] = useState(locationName);

    useEffect(() => {
      if (_location) handleChange(_location as FaultReceptionLocationType, _locationName);
    }, [handleChange, _location, showText, _locationName]);

    return (
      <>
        <RadioButton
          options={options}
          value={_location}
          onChange={(v) => {
            setShowText(v === 'other');
            setLocation(v);
          }}
        />
        {showText && (
          <>
            <StyledExampleText>（例：ナースステーション、医療機器管理室 など）</StyledExampleText>
            <ReceptionTextField
              name="locationName"
              value={_locationName}
              onChange={(e) => {
                setLocationName(e.target.value);
              }}
              top="0px"
              bottom="16px"
            />
          </>
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.location === nextProps.location && prevProps.locationName === nextProps.locationName;
  }
);
