import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

// CHAT_BOT_IDは、chatbotのDOMのid
const CHAT_BOT_ID = 'tayori-ai-chatbot-app';
// ICON_CLASS_NAMEは、chatbotのDOM配下のIconクラス名
const ICON_CLASS_NAME = 'tayori-ai-chatbot-app-tayori-ai-chatbot-icon';

/** チャットボットIconのサイズ調整 */
const chatbotIconScale = (dom?: HTMLElement) => {
  // domが指定されていない場合は、初期表示時のIconを取得する
  if (!dom) {
    const IconDomCollection = document.getElementsByClassName(ICON_CLASS_NAME);
    if (IconDomCollection && IconDomCollection[0] instanceof HTMLElement) {
      dom = IconDomCollection[0];
    }
  }
  if (!dom) return;
  dom.style.transform = 'scale(0.65)';
};

/** チャットボット全体の位置調整 */
const chatbotPosition = (dom: HTMLElement) => {
  dom.style.right = '15px';
  // marginBottomを使用しているのは元のchatbotの位置がmarginBottomで指定しているため
  dom.style.marginBottom = '90px';
};

/**
 * チャットボットの表示制御
 * 表示時は位置調整のための関数を呼び出す
 * @param pathname
 * @param dom
 * @returns
 */
const chatbotViewHandler = (pathname: string, dom: HTMLElement | null) => {
  if (!dom) return;
  // スマホもしくは貸出・返却・病棟画面の場合は非表示
  const isChatbotHidden = pathname.includes('/sp/') || pathname.includes('/shared');
  dom.style.display = isChatbotHidden ? 'none' : 'block';
  if (!isChatbotHidden) {
    chatbotPosition(dom);
    chatbotIconScale();
  }
};

let isZendeskObserver = false;
/**
 * zenDeskのチャットウインドウが開いた時に、引数のDOMを非表示にする
 * カスタムHookにしない理由は、developerModeで複数回呼び出されるため
 * @see https://developer.zendesk.com/api-reference/widget-messaging/web/core/
 * @param dom
 * @returns
 */
const zendeskObserver = (dom: HTMLElement) => {
  // 副作用が一度だけ実行されるようにする
  if (isZendeskObserver) return;
  isZendeskObserver = true;
  try {
    // biome-ignore lint/suspicious/noExplicitAny: 外部APIの型定義が不明なため
    (window as any).zE('messenger:on', 'open', function () {
      dom.style.display = 'none';
    });
    // biome-ignore lint/suspicious/noExplicitAny: 外部APIの型定義が不明なため
    (window as any).zE('messenger:on', 'close', function () {
      dom.style.display = 'block';
    });
  } catch (error) {
    console.error(error);
  }
};

/**
 * tayori AIチャットボットの表示制御
 */
export const tayoriChatbotViewHandler = () => {
  const location = useLocation();
  const tayoriChatBotDomRef = useRef<HTMLElement | null>(document.getElementById(CHAT_BOT_ID));

  useEffect(() => {
    // 初回レンダリング時に実行
    setChatbotViewHandler();
  }, []);

  useEffect(() => {
    setChatbotViewHandler();
  }, [location]);

  useEffect(() => {
    if (!tayoriChatBotDomRef.current) return;
    // zenDeskのチャットウインドウが開いた時に、アイコンを非表示にする
    zendeskObserver(tayoriChatBotDomRef.current);
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type !== 'childList') return;
        // targetClassNameを持つ要素が追加されたかを確認
        const addedChatElement = Array.from(mutation.addedNodes).find(
          (node) => node instanceof HTMLElement && node.classList.contains(ICON_CLASS_NAME)
        );
        if (addedChatElement instanceof HTMLElement) {
          chatbotIconScale(addedChatElement);
          break;
        }
      }
    });

    // Observerを起動し、targetClassNameを持つ要素が追加されたかを監視する
    observer.observe(tayoriChatBotDomRef.current, {
      childList: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [tayoriChatBotDomRef.current]);

  const setChatbotViewHandler = () => {
    // DOMがまだ取得できていない場合、再取得を試みる
    if (!tayoriChatBotDomRef.current) {
      tayoriChatBotDomRef.current = document.getElementById(CHAT_BOT_ID);
    }
    chatbotViewHandler(location.pathname, tayoriChatBotDomRef.current);
  };
};
