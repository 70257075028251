import React, {useCallback, useRef, useState} from 'react';
import {Box, Typography} from '@material-ui/core';
import {styled} from '@material-ui/core/styles';
import {HITOTSULogo} from '@atoms/HITOTSULogo';
import {PopperMenu} from './PopperMenu';
import {useLongPress} from 'use-long-press';
import {useSignOut} from '@modules/auth/hooks';
import {useCurrentDateTime} from '@front-libs/core';

type FaultReceptionHeaderProps = {
  title?: string;
  text?: string;
  showLogo?: boolean;
};

/**
 * 長押しでログアウトのボタンが出るAssetsのLogo
 */
const LongPressLogOutLog = () => {
  const [open, setOpen] = useState(false);
  const anchorElementRef = useRef<HTMLDivElement>(null);
  const logout = useSignOut();

  const handleLongPress = useLongPress(
    (e) => {
      setOpen(true);
    },
    {
      threshold: 2000,
    }
  );

  const handleClickMenu = useCallback(
    (value: 'logout') => {
      if (value === 'logout') logout();
    },
    [logout]
  );

  return (
    <StyledLogoBox
      ref={anchorElementRef}
      onContextMenu={(e) => {
        e.preventDefault();
        return false;
      }}
      {...handleLongPress()}>
      <HITOTSULogo />
      <PopperMenu
        open={open}
        anchorEl={anchorElementRef.current}
        onClickSettingMenu={handleClickMenu}
        onClose={() => {
          setOpen(false);
        }}
      />
    </StyledLogoBox>
  );
};

/**
 * 日付表示コンポーネント
 */
const HeaderDate = () => {
  const formattedDate = useCurrentDateTime();
  return <StyledDateBox>{formattedDate}</StyledDateBox>;
};

/**
 * 不具合受付のヘッダー
 * タイトルと現在のローカル時刻が表示される
 * @param props.title タイトル文字列
 * @param props.showLogo trueの時タイトルロゴが表示される
 * @param props.text オプション 入力時タイトル下に文字列が表示される
 */
export const FaultReceptionHeader = ({title, text, showLogo = false}: FaultReceptionHeaderProps) => {
  return (
    <StyledRootBox>
      {showLogo && <LongPressLogOutLog />}
      <HeaderDate />
      <StyledTitleBox top={showLogo ? 0 : 30}>{title}</StyledTitleBox>
      {text && <StyledTextTypography>{text}</StyledTextTypography>}
    </StyledRootBox>
  );
};

const StyledRootBox = styled(Box)({
  boxSizing: 'border-box',
  width: '100%',
  padding: '0px 32px',
});

const StyledTitleBox = styled(Box)(({top}: {top: number}) => ({
  color: '#374659',
  fontSize: '24px',
  fontWeight: 'bold',
  paddingTop: `${top}px`,
}));

const StyledLogoBox = styled('div')({
  paddingTop: '32px',
  width: '132px',
  cursor: 'pointer',
});

const StyledDateBox = styled(Box)({
  top: '32px',
  right: '32px',
  position: 'absolute',
});

const StyledTextTypography = styled(Typography)({
  paddingTop: '8px',
  fontSize: '16px',
});
